import axios from 'axios';

const PREACK_BASE_URL = process.env.VUE_APP_SERVICE_BASE_URL;

/**
 * Gets the REC Customer ID
 * @param {string} id for REC Operation
 * @returns {Promise} response promise
 */
export const generatePreackID = flowAlias => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${PREACK_BASE_URL}preack`, {
        flow_alias: flowAlias
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

/**
 *
 * Calls on PUT the preack service to set the notice movement date
 * this movement date is associated to the pre-ack id.
 *
 * @param {string} preackId
 * @param {string} movementDate (YYYY/MM/DD)
 *
 * @return Promise<response> | Promise<errorResponse>
 */
export const updateNoticeMovementDate = (preackId, movementDate, stateAccountNumber) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${PREACK_BASE_URL}preack/${preackId}/update`, {
        transaction_applied_at: movementDate,
        state_account_number: stateAccountNumber
      })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export const postInscriptionForm = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_SERVICE_BASE_URL}v1/inscription_form2`, data)
      .then(() => {
        resolve();
      })
      .catch(error => reject(error));
  });
};

export const postIdentificationData = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_SERVICE_BASE_URL}v1/identification`, data)
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

/**
 * Gets the Preack for a Specific Customer Operation
 * @param {string} flowAlias REC Operation Alias
 * @param {string} customerId REC Customer Id
 * @returns {Promise} response promise
 */
export const generatePreack = flowAlias => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${PREACK_BASE_URL}preack`, {
        flow_alias: flowAlias
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err.response.data.status);
      });
  });
};

/**
 * Updates the number of obligations for a customer
 * @param {data} Collection of Obligations, Operation : Dicrease or Increase
 * @param {string} customerId REC Customer Id
 * @returns {Promise} response promise
 */
export const postObligations = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_SERVICE_BASE_URL}v1/fiscal_obligation`, data)
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

/**
 * Unsuscribes a customer
 * @param {data} Reason of Unsubscription, description, title and customer RFC
 * @returns {Promise} response promise
 */
export const postUnsubscription = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_SERVICE_BASE_URL}preack/${data.created_by}/commits`, data)
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

/**
 * @param {string} zipCode receives a zip code.
 * @returns {} Object with the address data provided by renapo
 */
export const loadPostalCodeInfo = zipCode => {
  if (!zipCode) {
    return;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_SERVICE_BASE_URL}address/zip/${zipCode}`)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(error => reject(error));
  });
};

/**
 * @param {any} {commit}
 * @returns {json} with the data from the server properties catalog
 */
export const loadProperties = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_SERVICE_BASE_URL}catalogs/properties`)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(error => reject(error));
  });
};

/**
 * @param {any} {commit}
 * @returns {json} whit the data from the server vialities catalog
 */
export const loadVialities = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_SERVICE_BASE_URL}catalogs/vialities`)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(error => reject(error));
  });
};

export const searchActivities = queryActivity => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_SERVICE_BASE_URL}catalogs/activities?q=${queryActivity}&limit=50&page=1`)
      .then(res => {
        resolve(res.data.data.activities);
      })
      .catch(error => reject(error));
  });
};
