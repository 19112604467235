import axios from 'axios';


export const getAttachments = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`https://x16m3fyo4k.execute-api.us-east-1.amazonaws.com/dev/attachments`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * Posts the Notaries
 * @param {Object} payload Notaries object
 * @returns {Promise} response promise
 */
export const postNotaries = payload => {
  return new Promise((resolve, reject) => {
    axios
      .post(`https://x16m3fyo4k.execute-api.us-east-1.amazonaws.com/dev/notary`, payload)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
