<template>
  <div class="ui main text container">
    <div class="title-header">
      <h2 class="ui header center aligned">{{ $t('home.title') }}</h2>
    </div>
    <h2>Inscripción</h2>
    <div class="ui two cards" id="solicitud-rec-container">
      <!-- REC -->
      <div class="ui raised link card" id="solicitud-rec" @click="navigateToService()">
        <div class="content">
          <div class="header">{{ $t("rec.title") }}</div>
          <div class="description">
            <p>{{ $t("rec.description") }}</p>
          </div>
        </div>
      </div>
    </div>

    <h2>Avisos</h2>
    <div class="ui two cards">
      <div class="ui raised link card" v-for="menuOption in menuOptions"
        v-bind:key="menuOption.id"
        @click="navigateToService(menuOption.ruta)">
        <div class="content">
          <div class="header">{{ menuOption.option_title }}</div>
          <div class="description">
            <p>{{ menuOption.option_description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Menu',
  components: {},
  created() {
    window.onbeforeunload = null;
    this.resetModulesStatus();
  },
  data: function() {
    return {
      menuOptions: [
        {
          option_title: this.$t('aao.title'),
          option_description: this.$t('aao.description'),
          ruta: 'aviso-aumento-obligaciones',
          id: 2
        },
        {
          option_title: this.$t('ado.title'),
          ruta: 'aviso-disminucion-obligaciones',
          option_description: this.$t('ado.description'),
          id: 3
        },
        {
          option_title: this.$t('acdf.title'),
          option_description: this.$t('acdf.description'),
          ruta: 'aviso-cambio-domicilio',
          id: 4
        },
        {
          option_title: this.$t('aae.title'),
          option_description: this.$t('aae.description'),
          ruta: 'aviso-apertura-establecimiento',
          id: 5
        },
        {
          option_title: this.$t('ace.title'),
          option_description: this.$t('ace.description'),
          ruta: 'aviso-cierre-establecimiento',
          id: 6
        },
        {
          option_title: this.$t('cdrs.title'),
          option_description: this.$t('cdrs.description'),
          ruta: 'aviso-cambio-denominacion',
          id: 7
        },
        {
          option_title: this.$t('crl.title'),
          option_description: this.$t('crl.description'),
          ruta: 'aviso-cambio-representante-legal',
          id: 8
        },
        {
          option_title: this.$t('sa.title'),
          option_description: this.$t('sa.description'),
          ruta: 'aviso-suspension-actividades',
          id: 9
        },
        {
          option_title: this.$t('ra.title'),
          option_description: this.$t('ra.description'),
           ruta: 'aviso-reanudacion-actividades',
          id: 10
        },
        {
          option_title: this.$t('il.title'),
          option_description: this.$t('il.description'),
          ruta: 'aviso-inicio-liquidacion',
          id: 11
        },
        {
          option_title: this.$t('as.title'),
          option_description: this.$t('as.description'),
          ruta: 'aviso-apertura-sucesion',
          id: 12
        },
        {
          option_title: this.$t('brec.title'),
          option_description: this.$t('brec.description'),
          ruta: 'aviso-baja-rec',
          id: 13
        }
      ]
    };
  },
    methods: {
      getTitleByRoute(route) {
	return this.menuOptions.find(e => e.ruta === route).option_title; //eslint-disable-line
      },
    ...mapGetters({
      getIsLoading: "getIsLoading",
      getErrorModalData: "getErrorModalData"
    }),
    ...mapActions({
      showErrorModal: "saveShowErrorModal",
      resetModulesStatus: 'resetModulesStatus'
    }),
    /**
     * Closes the error modal by setting the showError function to false
     *
     * @return void
     */
    closeModal() {
      this.showErrorModal({ show: false });
    },
    navigateToService(route) {
      if (route == undefined) {
        route = "registro";
	this.$gtag.event(route, {
          'event_category': 'tramites',
          'event_label': this.$t("rec.title"),
          'value': 1
	})
      } else {
	this.$gtag.event(route, {
          'event_category': 'tramites',
          'event_label': this.getTitleByRoute(route),
          'value': 1
	})
      }
      this.$router.push({ path: `/tramites/${route}` });
    }
  }
};
</script>

<style>
.container {
  padding-bottom: 5rem;
}
#solicitud-rec-container {
  padding-bottom: 25px;
}

#solicitud-rec {
  width: 100%;
}

.image-header {
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.title-header {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.wizard-icon {
  font-style: normal !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
