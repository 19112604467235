<template>
  <div class="ui grid container">
    <h2 class="ui header"></h2>
  </div>
</template>

<script>
export default {
  name: 'PreponderantActivity',
  props: ['module'],
  data() {
    return {
      identificationValues: this.module
    };
  },
  watch: {
    module(newVal) {
      this.identificationValues = newVal;
    }
  }
};
</script>
