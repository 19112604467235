import axios from 'axios'

const BASE_URL = process.env.VUE_APP_SERVICE_BASE_URL;

const state = {
    properties: "",
    vialities: "",
    isSaved: false,
    validated: false,
    data: null
};

const mutations = {
    'Set_Properties' (state, properties) {
        state.properties = properties;
    },
    'Set_Vialities' (state, vialities) {
        state.vialities = vialities;
    },
    Set_SavedStatus(state, isSaved) {
        state.isSaved = isSaved;
    },
    'Set_Validated' (state, validated) {
        state.validated = validated;
    },
    'Set_Data' (state, data) {
        state.data = data;
	}	
};


const getters = {
    getPropiedades(state) {
        return state.properties;
    },
    getVialidades(state) {
        return state.vialities;
    },
    getIsSaved(state) {
        return state.isSaved;
    },
    loadValidated() {
        return state.validated;
	},
	getData() {
        return state.data;
    }

};

const actions = {
    /**
     * @param {any} {commit}
     * @returns {json} with the data from the server properties catalog
     */
    loadProperties: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}catalogs/properties`)
                .then((data) => {
                    let properties = data.data;
                    commit('Set_Properties',properties)
                    resolve(true);
                }).catch(error => reject(error))
        });
    },
    /**
     * @param {any} {commit}
     * @returns {json} whit the data from the server vialities catalog
     */
    loadVialities: () => {
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}catalogs/vialities`)
            .then((data) => {
                resolve(data);
            })
            .catch(error => reject(error))
        });
        },

    /**
     * @param {any} {commit
     * @param {any} state} recives the vue data from a component
     * @returns {any} response with the result of the post service 
     */
    saveData() {
        if (!state.data) {
            return;
        }

        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}v1/tax_residence`, state.data)
            .then(success => {
                resolve(success);
            })
            .catch(error => reject(error))
        });
    },
    setData: ({ commit }, dataD) => {
        commit('Set_Data', dataD)
    },
    setIsSaved({ commit }, isSaved) {
        commit('Set_SavedStatus', isSaved);
    },
    validator({ commit }, validator) {
        commit('Set_Validated', validator);
    }
};



export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
