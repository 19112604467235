import { helpers } from 'vuelidate/lib/validators';

// options = {}  means defaults in empty
export const isValidFileSize = (options = {}) => {
  return helpers.withParams(options, value => {
    if (!value) {
      return true;
    }
    const fileSizeinKb = value.size / 1024;
    const size = Math.round(fileSizeinKb * 100) / 100; // convert up to 2 decimal place
    console.log(size <= options.maxFileSize / 1024);
    return size <= options.maxFileSize / 1024;
  });
};

export const isValidFileType = (options = {}) => {
  return helpers.withParams(options, value => {
    if (!value) {
      return true;
    }
    return options.fileTypes.filter(tpe => tpe === value['type']).length > 0;
  });
};
