<template lang="html">
  <div class="notaries__header">
    <img
        class="ui left medium image"
        src="@/assets/img/logo-formulariodenotarios-01.png"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header"
}
</script>
