import { render, staticRenderFns } from "./DocumentoProtocolizado.vue?vue&type=template&id=a40c2ed0&scoped=true&"
import script from "./DocumentoProtocolizado.vue?vue&type=script&lang=js&"
export * from "./DocumentoProtocolizado.vue?vue&type=script&lang=js&"
import style0 from "./DocumentoProtocolizado.vue?vue&type=style&index=0&id=a40c2ed0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a40c2ed0",
  null
  
)

export default component.exports