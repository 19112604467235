<template>
  <div class="ui grid container">
    <br />
    <h2 class="ui header">{{ $t('preview.overview.calculated_taxes.title') }}</h2>
    <div class="one column row">
      <ListadoObligaciones :columns="obligationsColumns" :obligations="mapTaxesTableData()"></ListadoObligaciones>
    </div>
    <sui-divider />
  </div>
</template>

<script>
import ListadoObligaciones from './Obligaciones/ListadoObligaciones.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'InscriptionForm',
  props: ['module'],
  data() {
    return {
      environmentalTaxes: ["ISN", "ICEMP", "IECAT", "IECA", "IECS"],
      identificationValues: this.module,
      transactionDate: this.module.operations_started_date,
      taxesData: this.module.taxes_descriptions,
      obligationsColumns: [
        this.$t('preview.overview.calculated_taxes.title'),
        this.$t('preview.overview.calculated_taxes.table.description'),
        this.$t('preview.overview.calculated_taxes.form.expiration_description'),
        this.$t('preview.overview.calculated_taxes.form.initial_date'),
        this.$t('preview.overview.calculated_taxes.form.status')
      ]
    };
  },
  methods: {
    getFormattedDate(taxType = '') {
      if (this.module.taxes.includes('ISN') && taxType === 'ISN') {
        return moment(this.module.taxes_data[0].dateSelected)
            .utc()
            .format('MM/YYYY');
      } else if (this.environmentalTaxes.includes(taxType) && (this.module.flow_alias != 'aaof' && this.module.flow_alias != 'adof')) {
        return moment(this.getTaxDateByKey.value)
            .utc()
            .format('MM/YYYY');
      } else if (this.module.flow_alias === 'aaof' || this.module.flow_alias === 'adof') {
        return moment(this.module.transaction_applied_at)
            .utc()
            .format('MM/YYYY');
      }
      return moment(this.transactionDate)
          .utc()
          .format('MM/YYYY');
    },
    getTaxDateByKey(key) {
      return this.module.taxes_data.find((tax) => tax.name === key)
    },
    mapTaxesTableData() {
      console.log('this.taxesData', this.taxesData);
      return this.taxesData.map(tax => {
        return {
          title: tax.title,
          description: tax.description,
          expiration_title: tax.expiration_description,
          initial_date: this.getFormattedDate(tax.abbreviation),
          obligation_type: 'ALTA'
        };
      });
    },
    ...mapGetters({
      getCurrentAlias: 'getCurrentAlias'
    })
  },
  watch: {
    module(newVal) {
      this.identificationValues = newVal;
    }
  },
  components: {
    ListadoObligaciones
  }
};
</script>
