import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import store from './store';
import i18n from './i18n';
import SuiVue from 'semantic-ui-vue';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import PortalVue from 'portal-vue';

import './filters/potfilter.js';
import './filters/inputfilter.js';
import VuejsDialog from 'vuejs-dialog';

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSeedling, faEdit, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faFacebookSquare,
  faInstagram,
  faYoutube,
  faTelegramPlane
} from '@fortawesome/free-brands-svg-icons';
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMask from 'v-mask';
import './directives/focus';

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import PotFilters from "@/plugins/PotFilters";
import Uppercase from "@/plugins/Uppercase";
import RenapoReplaceSpecialChars from "@/plugins/RenapoReplaceSpecialChars";
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

Vue.use(VuejsDialog);
Vue.use(Vuelidate);
Vue.use(SuiVue);
Vue.use(PotFilters);
Vue.use(Uppercase);
Vue.use(RenapoReplaceSpecialChars);
Vue.use(VueMask);
Vue.use(PortalVue);
Vue.use(VueFormWizard)

const GOOGLE_ANALYTICS_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;
Vue.use(VueGtag, {
  config: { id: GOOGLE_ANALYTICS_ID }
}, router);

library.add(
    faSeedling,
    faEdit,
    faTrash,
    faCheck,
    faTwitter,
    faFacebookSquare,
    faInstagram,
    faYoutube,
    faTelegramPlane,
    faFontAwesome
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  axios,
  render: h => h(App)
}).$mount('#app');

global.vm = App;
