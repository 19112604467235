<template>
  <div class="ui container">
    <Loader v-if="isloading"/>
    <form class="ui form">
      <div class="two fields">
        <div class="field" :class="{ error: $v.data.business_name.$error }">
          <label> {{ $t("identification_data.form.business_name") }} *</label>
          <input type="text"
                 name="business_name"
                 :disabled="readOnlyMode"
                          :placeholder="$t('identification_data.form.business_name')"
                          v-model="data.business_name"
                          @blur="$v.data.business_name.$touch()"/>
          <span v-if="!$v.data.business_name.required && $v.data.business_name.$anyDirty" class="error-message" >
          {{ $t('validation.field_required') | potfilter($t('identification_data.form.business_name')) }}
          </span>
          <span v-if="!$v.data.business_name.minLength && $v.data.business_name.$anyDirty" class="error-message" >
          {{ $t('validation.field_invalid') | potfilter($t('identification_data.form.business_name')) }}
          </span>
        </div>
        <div class="field" :class="{ error: $v.constitution_date_Formateado.$error }">
          <label>{{ $t("identification_data.form.constitution_date") }} *</label>
          <div class="ui calendar" id="identification_data.form.constitution_date">
            <datepicker
            :placeholder="$t('identification_data.form.constitution_date')"
            v-model="constitution_date_Formateado"
            @blur="$v.constitution_date_Formateado.$touch()"
            :language="es"
            :open-date="disabledDates.from"
            :initial-view="'year'"
            :disabled-dates="disabledDates"
                  valueType="format"
                  format="dd/MM/yyyy"
            :disabled="readOnlyMode">
            </datepicker>
            <span v-if="!$v.constitution_date_Formateado.required && $v.constitution_date_Formateado.$anyDirty" class="error-message">
              {{ $t('validation.field_required') | potfilter($t('identification_data.form.constitution_date')) }}
            </span>
          </div>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.data.regimen_id.$error }">
          <label class="input-wrapper">{{ $t("identification_data.form.regimen") }} *</label>
          <sui-popup flowing hoverable >
            <p>
              {{$t('identification_data.form.regimen.help')}}
            </p>
            <sui-icon name="info circle" slot="trigger"  position="top center"/>
          </sui-popup>
          <sui-dropdown
              :placeholder="$t('identification_data.form.regimen')"
              :disabled="readOnlyMode"
              selection
              :options="getRegimenFormatted"
              @blur="$v.data.regimen_id.$touch()"
              v-model="data.regimen_id"/>
          <tr>
            <span v-if="!$v.data.regimen_id.required && $v.data.regimen_id.$anyDirty"   class="error-message">
                {{ $t('validation.field_required') | potfilter($t('identification_data.form.regimen')) }}
            </span>
          </tr>
        </div>
      </div>
    </form>
    <modal :error="mineError"
           v-show="isModalVisible"
           @close="closeModal"/>
  </div>
</template>

<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import {es} from 'vuejs-datepicker/dist/locale'
    import datepicker from 'vuejs-datepicker';    
    import moment from 'moment';
    import { mapActions, mapGetters } from 'vuex';
    import modal from '../shared/Modal';
    import Loader from '../Loader';    
    export default {
      name: "PersonaM",
      props: {
        readOnlyMode: {
          type: Boolean,
          default () {
            return false;
          }
        }
      },
      data() {
        return {
          es: es,
          mineError: {
            title: '',
            description: ''
          },
          isloading:false,
          loading:false,
          isModalVisible: false,
          disabledDates: {
            // Disable all dates up to specific date
            from: moment().toDate(),
            // Disable all dates after specific date
            to: moment().subtract(300, 'years').toDate()
          },
          data: {
              business_name: "",
            taxpayer_type: "legal_entity",
            constitution_date: '',
            regimen_id: null
          },
          regimenes: [],
          constitution_date_Formateado: ''
          };
      },
      methods:{
        getRegimenById(id) {
          return this.regimenes.filter((i) => i.id === id)[0];
        },
        closeModal() {
          this.isModalVisible = false;
        },
        showModal() {
          this.isModalVisible = true;
        },
        validateForm() {
          let isValidForm = !this.$v.$error;
          this.$v.$reset()
          this.$v.$touch()
          if (isValidForm) {
            this.$emit('data', {
              taxpayer_type: this.data.taxpayer_type,
              data: {
                business_name: this.data.business_name,
                constitution_date: this.data.constitution_date,
                regimen: this.getRegimenById(this.data.regimen_id).name
              }
            });
          }
          return isValidForm;
        },
        ...mapActions({
          loadRegimen : 'datos_identificacion/loadRegimen'
        }),
      },

      mounted() {
        this.loadRegimen().then(() => {
          this.isloading=false;
        }).catch(err => {
        if(err) {
          this.mineError.description =this.$t('server.error');
          this.mineError.title = "Error";
          this.showModal();
          this.isloading=false;
        }
        });
      },
      watch: {
        getRegimen(data){
          this.regimenes = data.data;
        },
        /**
           * jaimexcastillo
           * @date 2020-05-29
           * @param {any} newData this references the data from the vue instance and its being send to 'datos_identificacion/setData'
           * @returns void
           */
        data: {
          handler: function (newData){
            const newDate = moment(this.constitution_date_Formateado).format('YYYY/MM/DD');
            newData['constitution_date'] = newDate;
          },
          deep: true
        },
        /**
           * @date 2020-06-19
           * @param {any} changeFecha es la fecha (en formato Date) que registra el componente de vuejs-datepicker , esta se parsea a el formato requerido y se asigna al 'constitution_date' que enviamos dentro de data
           * @returns void
           */
        constitution_date_Formateado: {
          handler: function(changeFecha) {
            var newFecha = JSON.stringify(changeFecha)
            newFecha = `${newFecha.substr(9,2)}-${newFecha.substr(6,2)}-${newFecha.substr(1,4)}`
            this.data['constitution_date'] = newFecha;
          }
        }
      },
      components:{
        datepicker,
        modal,
        Loader
      },
      computed: {
        ...mapGetters({
          getRegimen: 'datos_identificacion/getRegimen'
        }),
        getRegimenFormatted() {
          return this.regimenes.map((i) => {
            return {
              text: i.name,
              value: i.id
            }
          });
        }
      },
      validations: {
        data: {
          business_name: {
            required,
            minLength: minLength(4),
          },
          constitution_date: {
            required,
          },
          regimen_id: {
            required
          }
        },
        constitution_date_Formateado:{
          required
        }
      }
    };
</script>

<style >
  .error-message {
    color: red;
    font-size: 12px;
    float: left;
  }
  .fields .field .input-wrapper{
    display:inline !important;
  }
</style>
