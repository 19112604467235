/* eslint-disable */
import { postNotaries } from '@/api/notaries';
import { tramites, modules } from '@/shared/constants';

export default {
  saveNotariosData({ state }, payload) {
      return postNotaries(payload)
          .then((success) => {
              return Promise.resolve(success);
          }).catch((failed) => {
              return Promise.reject(failed)
          })
  },
  /**
   * Calls the current module saveData fn to call backend service
   * @param internal vuex objet {getters, dispatch}
   * @return void
   */
  saveCurrentModuleData({ getters, state, dispatch }) {
    let advanceInProcess = false;
    return new Promise((resolve, reject) => {
      if (!getters[`${getters.getCurrentModule}/getIsSaved`]) {
        if (getters[`${getters.getCurrentModule}/loadValidated`]) {
          dispatch(`${getters.getCurrentModule}/saveData`)
            .then(() => {
              advanceInProcess = true;
              dispatch(`${getters.getCurrentModule}/setIsSaved`, true);
              dispatch('setNextModule', state.currentModule);
              resolve(advanceInProcess);
            })
            .catch(err => reject(err));
        } else {
          advanceInProcess = false;
          reject(advanceInProcess);
        }
      } else {
        // if the module is already saved
        dispatch('setNextModule', state.currentModule);
        advanceInProcess = true;
        resolve(advanceInProcess);
      }
    });
  },
  /**
   * Guarda en el store general el  flow builder alias (tramite) actual
   * basado en el param ingresado en la ruta de tramites (tramites/:tipoTramite).
   *
   *
   * @param string alias
   */
  saveCurrentAlias({ commit, state }, tipoTramite) {
    if (state.currentAlias !== tipoTramite) {
      let alias = '';
      for (const tramiteKey in tramites) {
        if (tramites[tramiteKey].ruta === tipoTramite) alias = tramites[tramiteKey].flowBuilderAlias;
        commit('setCurrentAlias', alias);
      }
    }
  },
  /**
   * Saves current module to the store calling the mutation event.
   * usalo cuando te muevas de modulo en modulo (pasos) atravez del timeline
   * para ir actualizando que modulo esta activo y asi saber que store usar.
   *
   * @param Object<{}> placeholder object
   * @param Object commit the commit function to call the mutation event.
   * @param Object state the current state of the store; use it here on the action to
   *                     validate any previous state vs the newest.
   * @param module the name of the module to set
   * @return void
   */
  saveCurrentModule({ commit, state }, module) {
    if (state.currentModule !== module) {
      commit('setCurrentModule', module);
    }
  },
  resetModulesStatus({ dispatch, commit }) {
    modules.forEach(module => {
      dispatch(`${module}/setIsSaved`, false);
      dispatch(`${module}/validator`, false);
      commit('setCurrentModule', '');
    });
  },
  /**
   * Setea el modulo inicial (currentModule) segun el alias del tramite.
   * [Esta funcion debe ser solo usada en Home.vue su responsabilidad es solo setear
   * el modulo inicial para cada tramite]
   *
   * @param Object <state> The state object.
   */
  saveInitialModuleByAlias({ commit }, tipoTramite) {
    if (tipoTramite) {
      switch (tipoTramite) {
        case tramites.registro.ruta:
          commit('setCurrentModule', 'datos_identificacion');
          break;
        case tramites.avisoCambioDomicilio.ruta:
          commit('setCurrentModule', 'datos_identificacion');
          break;
        case tramites.avisoAumentoObligaciones.ruta:
          commit('setCurrentModule', 'datos_identificacion');
          break;
        case tramites.avisoAltaSucursal.ruta:
          commit('setCurrentModule', 'datos_identificacion');
          break;
        case tramites.avisoCierreSucursal.ruta:
          commit('setCurrentModule', 'datos_identificacion');
          break;
        default:
          commit('setCurrentModule', 'datos_identificacion');
      }
    }
  },
  /**
   * Commits the isLoadig value to the corresponding state property
   *
   * @param  {}<commit> internal vuex commit object
   * @param boolean isLoading
   *
   * @return void
   * to avoid booltraps.
   */
  saveisLoading({ commit }, loading = {}) {
    loading.isLoading = typeof loading.isLoading !== 'boolean' ? false : loading.isLoading;
    loading.message = !loading.message ? 'Cargando...' : loading.message;
    commit('setIsLoading', loading);
  },
  responseMessage({ commit }, response) {
    commit('setResponseMessage', response[0])
    commit('setResponseType', response[1])
    setTimeout(() => {
        commit('setResponseType', 0)
        commit('setResponseMessage', '')
      }, 15000);
  },
  saveTipoPersona({ commit }, tipoPersona) {
    commit('setTipoPersona', tipoPersona);
  },
  /**
   * @param {} config
   * @return void
   */
  saveShowErrorModal({ commit }, config) {
    config.error = !config.error ? { title: '', description: '' } : config.error;
    commit('setErrorModalData', config);
  },
  setDisableRouteLeaveDialog({ commit }, disableDialog = {}) {
    if (disableDialog && typeof disableDialog.disabled !== 'boolean') {
      disableDialog.disable = false;
    }
    commit('setDisableRouteLeaveDialog', disableDialog.disabled);
  }
};
