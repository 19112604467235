<template>
  <div class="ui container">
    <Loader v-if="isloading" />
    <form class="ui form">
      <h4>{{ description }}</h4>
      <h5>{{ $t('general.form.description_required_fields') }}</h5>
      <div class="two fields">
        <div class="field" :class="{ error: $v.domicilioFiscal.zip.$error }">
          <label> {{ $t('taxresidence.form.zip') }} *</label>
          <sui-input
            :disabled="readOnlyMode"
            type="number"
            id="codigopostal"
            maxlength="5"
            name="codigo_postal"
            :placeholder="$t('taxresidence.form.zip')"
            v-model="domicilioFiscal.zip"
            @keydown="deleted"
            @keypress="debounceSearchCP"
            @paste="loadPostalCodeEvt"
            @blur="loadPostalCodeEvt"
            :loading="loading"
          />
          <span v-if="!$v.domicilioFiscal.zip.required && $v.domicilioFiscal.zip.$anyDirty" class="error-message">
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.zip')) }}
          </span>
          <span v-if="!$v.domicilioFiscal.zip.minLength && $v.domicilioFiscal.zip.$anyDirty" class="error-message">
            {{ potFilterNameTag($t('validation.field_invalid'), $t('taxresidence.form.zip')) }}
          </span>
        </div>

        <div class="field" :class="{ error: $v.domicilioFiscal.state.$error }">
          <label> {{ $t('taxresidence.form.entity') }} *</label>
          <input
            type="text"
            name="entidad"
            :placeholder="$t('taxresidence.form.entity')"
            v-model="state"
            @blur="$v.domicilioFiscal.state.$touch()"
            disabled
          />
          <span v-if="!$v.domicilioFiscal.state.required && $v.domicilioFiscal.state.$anyDirty" class="error-message">
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.entity')) }}
          </span>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.domicilioFiscal.municipality.$error }">
          <label> {{ $t('taxresidence.form.city') }} *</label>
          <sui-dropdown
            id="drop-municipio"
            :placeholder="$t('taxresidence.form.city')"
            selection
            :options="getMunicipios"
            v-model="domicilioFiscal.municipality"
            @blur="$v.domicilioFiscal.municipality.$touch()"
            :disabled="municipios.length <= 1 || readOnlyMode"
          />

          <span
            v-if="!$v.domicilioFiscal.municipality.required && $v.domicilioFiscal.state.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.city')) }}
          </span>
        </div>
        <!-- Neighborhood Input -->
        <div class="field" :class="{ error: $v.domicilioFiscal.neighborhood.$error }">
          <label class="input-wrapper">{{ $t('taxresidence.form.neighborhood') }} *</label>
          <sui-popup flowing hoverable >
              <p>
                {{$t('taxresidence.form.neighborhood.popup.message')}}
                <a target="_blank" href="https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx">{{
                  'https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx'
                  }}</a>
              </p>
              <sui-icon name="info circle" slot="trigger"  position="top center"/>
          </sui-popup>
          <sui-dropdown
            id="drop-colonia"
            :placeholder="$t('taxresidence.form.select_neighborhood')"
            selection
            :options="getColonias"
            v-model="domicilioFiscal.neighborhood"
            @blur="$v.domicilioFiscal.neighborhood.$touch()"
            :disabled="colonias == 0 || readOnlyMode"
          />,
          <span
            v-if="!$v.domicilioFiscal.neighborhood.required && $v.domicilioFiscal.neighborhood.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.neighborhood')) }}
          </span>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.domicilioFiscal.type_of_road_id.$error }" id="tipo_vialidad">
          <label>{{ $t('taxresidence.form.viality_type') }} *</label>
          <sui-dropdown
            :disabled="readOnlyMode"
            id="drop-vialidad"
            :placeholder="$t('taxresidence.form.select_viality_type')"
            :options="vialidades"
            selection
            v-model="domicilioFiscal.type_of_road_id"
            @blur="$v.domicilioFiscal.type_of_road_id.$touch()"
          />

          <span
            v-if="!$v.domicilioFiscal.type_of_road_id.required && $v.domicilioFiscal.type_of_road_id.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.viality_type')) }}
          </span>
        </div>
        <div class="field" :class="{ error: $v.domicilioFiscal.street.$error }">
          <label>{{ $t('taxresidence.form.street') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="calle"
            :placeholder="$t('taxresidence.form.street')"
            v-model="domicilioFiscal.street"
            @blur="$v.domicilioFiscal.street.$touch()"
          />
          <span v-if="!$v.domicilioFiscal.street.required && $v.domicilioFiscal.street.$anyDirty" class="error-message">
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.street')) }}
          </span>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.domicilioFiscal.exterior.$error }">
          <label>{{ $t('taxresidence.form.ext_number') }}*</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="num_exterior"
            :placeholder="$t('taxresidence.form.ext_number')"
            v-model="domicilioFiscal.exterior"
            @blur="$v.domicilioFiscal.exterior.$touch()"
            @keyup="address"
          />
          <span
            v-if="!$v.domicilioFiscal.exterior.required && $v.domicilioFiscal.exterior.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.ext_number')) }}
          </span>
        </div>
        <div class="field">
          <label>{{ $t('taxresidence.form.int_number') }} </label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="num_interior"
            :placeholder="$t('taxresidence.form.int_number')"
            v-model="domicilioFiscal.interior"
          />
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.domicilioFiscal.between_street.$error }">
          <label>{{ $t('taxresidence.form.between_streets_1') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="entre_calle"
            :placeholder="$t('taxresidence.form.between_streets_1')"
            v-model="domicilioFiscal.between_street"
            @blur="$v.domicilioFiscal.between_street.$touch()"
          />
          <span
            v-if="!$v.domicilioFiscal.between_street.required && $v.domicilioFiscal.between_street.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.between_streets_1')) }}
          </span>
        </div>
        <div class="field" :class="{ error: $v.domicilioFiscal.and_street.$error }">
          <label>{{ $t('taxresidence.form.between_streets_2') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="y_calle"
            :placeholder="$t('taxresidence.form.between_streets_2')"
            v-model="domicilioFiscal.and_street"
            @blur="$v.domicilioFiscal.and_street.$touch()"
          />
          <span
            v-if="!$v.domicilioFiscal.and_street.required && $v.domicilioFiscal.and_street.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.between_streets_2')) }}
          </span>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.domicilioFiscal.property_type.$error }">
          <label>{{ $t('taxresidence.form.property_type') }} *</label>
          <sui-dropdown
            :disabled="readOnlyMode"
            selection
            :placeholder="$t('taxresidence.form.select_property_type')"
            :options="getProperties"
            v-model="domicilioFiscal.property_type"
            @blur="$v.domicilioFiscal.property_type.$touch()"
          />
          <span
            v-if="!$v.domicilioFiscal.property_type.required && $v.domicilioFiscal.property_type.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.property_type')) }}
          </span>
        </div>
        <div class="field">
          <label>{{ $t('taxresidence.form.water_and_drain_meter') }}</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="registro"
            @blur="$v.domicilioFiscal.water_and_drain_meter.$touch()"
            :placeholder="$t('taxresidence.form.water_and_drain_meter')"
            v-model="domicilioFiscal.water_and_drain_meter"
          />

          <span
            v-if="
              !$v.domicilioFiscal.water_and_drain_meter.alphaNum && $v.domicilioFiscal.water_and_drain_meter.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.alphanumeric') }}
          </span>
        </div>
      </div>

      <div class="field">
        <div class="field" :class="{ error: $v.domicilioFiscal.references.$error }">
          <label>{{ $t('taxresidence.form.additional_reference') }} *</label>
          <textarea
            id="reference-field"
            :disabled="readOnlyMode"
            name="referencia"
            :placeholder="$t('taxresidence.form.additional_reference')"
            v-model="domicilioFiscal.references"
            @keydown="
              onReferencesKeyDown($event);
              $v.domicilioFiscal.references.$touch();
            "
          >
          </textarea>
          <span
            v-if="!$v.domicilioFiscal.references.required && $v.domicilioFiscal.references.$anyDirty"
            class="error-message"
          >
            {{ potFilterNameTag($t('validation.field_required'), $t('taxresidence.form.additional_reference')) }}
          </span>
        </div>
      </div>

      <div class="field">
        <label for="mapa-ref">{{ $t('taxresidence.form.map') }}</label>
        <Mapa ref="domicilioFiscalMapa" class="mapa" />
      </div>
    </form>
    <modal :error="mineError" v-show="isModalVisible" @close="closeModal" />
  </div>
</template>

<script>
import { required, minLength, alphaNum } from 'vuelidate/lib/validators';
import modal from '../shared/Modal';
import Loader from '../Loader';
import debounce from 'lodash/debounce';
import Mapa from './Mapa';
import { entidades, keyCodes } from '@/shared/constants';
import { loadPostalCodeInfo, loadProperties, loadVialities } from '../../shared/services';

export default {
  name: 'DomicilioFiscal',
  components: {
    modal,
    Loader,
    Mapa
  },
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    description: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      domicilioFiscal: {
        zip: '',
        state: null,
        municipality: null,
        type_of_road_id: null,
        street: '',
        exterior: '',
        interior: '',
        between_street: '',
        and_street: '',
        references: '',
        water_and_drain_meter: '',
        neighborhood: null,
        property_type: null
      },
      coordinate_x: '',
      coordinate_y: '',
      maxAmountCharsReferenceField: 150,
      mineError: {
        title: '',
        description: ''
      },
      isloading: false,
      loading: false,
      isModalVisible: false,
      coordenadas: {},
      municipios: [],
      vialidades: [],
      inmuebles: [],
      colonias: [],
      city: '',
      state: '',
      colonia: '',
      entidades: entidades,
      propiedad: '',
      vialidad: ''
    };
  },
  validations: {
    domicilioFiscal: {
      zip: {
        required,
        minLength: minLength(5)
      },
      state: {
        required
      },
      municipality: {
        required
      },
      type_of_road_id: {
        required
      },
      street: {
        required
      },
      exterior: {
        required
      },
      neighborhood: {
        required
      },
      property_type: {
        required
      },
      water_and_drain_meter: {
        alphaNum
      },
      references: {
        required
      },
      between_street: {
        required
      },
      and_street: {
        required
      }
    }
  },

  mounted() {
    loadProperties()
      .then(properties => {
        this.isloading = false;
        this.getPropiedades(properties);
      })
      .catch(err => {
        if (err) {
          this.mineError.description = this.$t('server.error');
          this.mineError.title = 'Error';
          this.showModal();
          this.isloading = false;
        }
      });
    loadVialities().then(vialities => {
      this.getVialidades(vialities);
    });
  },

  methods: {
    getStateById(id) {
      return this.entidades.filter(i => i.value === id)[0];
    },
    getVialityById(id) {
      if (!this.vialidades) {
        return;
      }
      return this.vialidades.filter(i => i.value === id)[0];
    },
    showModal() {
      this.isModalVisible = true;
    },
    validateForm() {
      let isValidForm = false;
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit('data', {
          zip: this.domicilioFiscal.zip,
          state: this.getStateById(this.domicilioFiscal.state).text,
          municipality: this.domicilioFiscal.municipality,
          type_of_road_id: this.domicilioFiscal.type_of_road_id,
          type_of_road: this.getVialityById(this.domicilioFiscal.type_of_road_id).text,
          street: this.domicilioFiscal.street,
          exterior: this.domicilioFiscal.exterior,
          interior: this.domicilioFiscal.interior,
          between: [{ street: this.domicilioFiscal.between_street }, { street: this.domicilioFiscal.and_street }],
          references: this.domicilioFiscal.references,
          water_and_drain_meter: this.domicilioFiscal.water_and_drain_meter,
          neighborhood: this.domicilioFiscal.neighborhood,
          property_type: this.domicilioFiscal.property_type
        });
        isValidForm = true;
      } else {
        this.$emit('validatedComponent', false);
        isValidForm = false;
      }
      return isValidForm;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    updateCoordinate(coordenadas) {
      this.coordenadas = coordenadas;
    },

    /**
     * karlaces
     * @date 2020-06-24
     * @param {keydown} event
     * Remove leading zeros from input type=number
     */
    deleted(evt) {
      const key = evt.keyCode || evt.charCode;
      let value = evt.target.value;
      if (key == 8 && value.length == 1) {
        this.domicilioFiscal.zip = '';
      }
    },
    loadPostalCodeEvt(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      const value = evt.target.value;

      if (value.length <= 5) {
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          this.searchPostalCode();
        }
      } else {
        evt.preventDefault();
      }
    },
    debounceSearchCP: debounce(function(event) {
      this.loadPostalCodeEvt(event);
    }, 2000),
    searchPostalCode() {
      this.loading = true;
      if (!this.domicilioFiscal.zip) {
        return;
      }
      loadPostalCodeInfo(this.domicilioFiscal.zip)
        .then(zipCodeData => {
          this.loading = false;
          this.setPostalCodeData(zipCodeData);
        })
        .catch(err => {
          if (err.response && err.response.data.status === 'fail') {
            this.mineError.description = this.$t('address.zip_does_not_exists');
            this.mineError.title = 'Error';
            this.isModalVisible = true;
            self.loading = false;
            // TODO: Change this to global modal
            this.showModal();
          } else {
            this.mineError.description = this.$t('server.error');
            this.mineError.title = 'Error';
            this.isModalVisible = true;
            this.loading = false;
            this.showModal();
          }
        });
    },
    address() {
      setTimeout(() => {
        const direccion =
          this.domicilioFiscal.type_of_road_id +
          ' ' +
          this.domicilioFiscal.street +
          ' #' +
          this.domicilioFiscal.exterior +
          ',' +
          this.domicilioFiscal.neighborhood +
          ',' +
          this.domicilioFiscal.municipality +
          ',Nuevo León';
        this.$refs.domicilioFiscalMapa.geocodePosition(direccion);
      }, 2500);
    },
    onReferencesKeyDown(event) {
      if (this.domicilioFiscal.references.length >= this.maxAmountCharsReferenceField) {
        if ((event.keyCode >= keyCodes.zero && event.keyCode <= keyCodes.z) || event.keyCodes === keyCodes.spaceBar) {
          event.preventDefault();
          return;
        }
      }
    },
    /**
     * karlaces
     * @date 2020-06-24
     * @param array data
     * you use the data to set data in the vue variable
     * @returns void
     */
    getVialidades(vialities = []) {
      if (!vialities && !Array.isArray(vialities)) {
        return;
      }

      this.vialidades = vialities.map(v => {
        return {
          text: v.name,
          value: v.id.toString()
        };
      });
    },
    /**
     * Placeholder function for the global plugin function <$potFilterNameTag>
     * encapsulated on this function beause if you use <$potFilterNameTag> directly on the template
     * you end up with errors like "$potFilterNameTag" was referenced on the instance but not defined
     *
     * @param value pot editor tag to be replaced
     * @value string which is going to be replaced on the __name__ poteditor string.
     *
     * @return string
     */
    potFilterNameTag(value, param) {
      return this.$potFilterNameTag(value, param);
    },
    /**
     * karlaces
     * @date 2020-06-24
     * @param array newCurp
     * you use the newCurp to set data in the vue variables
     * @returns void
     */
    setPostalCodeData(zipData) {
      this.domicilioFiscal.state = zipData[0].state_id;
      this.state = zipData[0].state;
      this.colonias = zipData;
      this.municipios = zipData;
    },
    /**
     * karlaces
     * @date 2020-06-24
     * @param array data
     * you use the data to set data in the vue variable
     * @returns void
     */
    getPropiedades(properties) {
      this.inmuebles = properties;
    }
  },
  computed: {
    getProperties() {
      if (!this.inmuebles) {
        return;
      }
      return this.inmuebles.map(i => {
        return {
          text: i.name,
          value: i.name
        };
      });
    },
    getColonias() {
      if (!this.colonias) {
        return;
      }
      return this.colonias.map(i => {
        return {
          text: i.suburb,
          value: i.suburb
        };
      });
    },
    getMunicipios() {
      if (!this.municipios) {
        return;
      }
      const result = [];
      const map = new Map();
      for (const item of this.municipios) {
        if (!map.has(item.municipality)) {
          map.set(item.municipality, true);
          result.push({
            value: item.city,
            text: item.city
          });
        }
      }
      return result;
    }
  },
  watch: {
    /**
     * karlaces
     * @date 2020-06-24
     * @param array data
     * you use the data to set data in the vue variables
     * @returns void
     */
    getMunicipios: {
      handler: function(data) {
        this.domicilioFiscal.municipality = data[0].value;
      },
      deep: true
    }
  },
  'domicilioFiscal.municipality': function(evt) {
    for (const item of this.municipios) {
      if (item.municipality == evt) {
        this.city = item.city;
      }
    }
  },
  /**
   * This watch is usually called when you choose a property type in the ui
   * it sets the property called redendantly in purpose "propiedad"
   */
  'domicilioFiscal.neighborhood': function(evt) {
    for (const item of this.inmuebles) {
      if (item.id == evt) {
        this.colonia = item.name;
      }
    }
  },
  'domicilioFiscal.property_type': function(evt) {
    for (const item of this.inmuebles) {
      if (item.id == evt) {
        this.propiedad = item.name;
      }
    }
  },
  'domicilioFiscal.type_of_road_id': function(evt) {
    for (const item of this.vialidades) {
      if (item.id == evt) {
        this.vialidad = item.name;
      }
    }
  },
  between_street: {
    handler: function(newData) {
      this.domicilioFiscal.between[0] = { street: newData };
    }
  },
  and_street: {
    handler: function(newData) {
      this.domicilioFiscal.between[1] = { street: newData };
    }
  }
};
</script>
<style scoped>
#reference-field {
  height: 112px;
}

.mapa {
  width: 100%;
  height: 400px;
}
/**
     * TODO: Move this to a general styles file and add it in main.js
     */
.error-message {
  display: block;
  color: red;
  font-size: 12px;
  float: left;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fields .field .input-wrapper{
  display:inline;
}
</style>
