import { getAttachments } from '@/api/notaries';
import state from './state';
export default {
    getNotariosAttachmentUrl() {
      return getAttachments()
          .then((success) => {
              return Promise.resolve(success);
          }).catch((failed) => {
              return Promise.reject(failed)
          })
    },
    getResponseType() {
      return state.responseStatus.type;
    },
    getResponseMessage() {
      return state.responseStatus.message;
    },
    getCurrentModule() {
        return state.currentModule
    },
    getCurrentAlias() {
        return state.currentAlias;
    },
    getIsLoading() {
        return state.loading;
    },
    getTipoPersona() {
        return state.tipoPersona;
    },
    getErrorModalData() {
        return state.errorModalData;
    },
    getIsDisableRouteLeaveDialog() {
        return state.disableRouteLeaveDialog;
    }
}
