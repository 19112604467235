import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Menu from '../views/Menu.vue'
import Success from '../views/Success.vue'
import Notaries from '../views/Notaries.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/tramites',
  },
  {
    path: '/tramites/:tipoTramite',
    name: 'Tramites',
    component: Home
  },
  {
    path: '/prueba',
    name: 'Prueba',
    component: () => import(/* webpackChunkName: "prueba" */ '../components/DomicilioFiscal/DomicilioFiscal.vue')
  },
  {
    path: '/tramites',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/tramites-success',
    name: 'Success',
    component: Success
  },
  {
    path: '/notarios',
    name: 'Notaries',
    component: Notaries
  },
  {
    path: '/acerca',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/VistaPrevia/Obligaciones/Obligaciones.vue' )
  }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
