export default {
    install(Vue) {
        Vue.prototype.$potFilterNameTag = (value, param) => {
            return value.replace("__name__", param);
        };
        Vue.prototype.$potFilterLengthTag = (value, param) => {
            return value.replace("__min_length__", param);
        };
        Vue.prototype.$potFilterModule = (value, param) => {
            return value.replace('__module__', param);
        };
        Vue.prototype.$potFilterNotSame = (value, param) => {
            return value.replace('__field__', param);
        }
        Vue.prototype.$potfilterFileSize = (value, param) => {
            return value.replace('__file_size__', param);
        }
    }
}
