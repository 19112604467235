import state from './state';
export default {
    getData() {
        return state.data;
    },
    getIsSaved(state) {
        return state.isSaved;
    },
    loadValidated() {
        return state.validated
    },
}