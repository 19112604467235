<template>
  <div class="ui container">
    <form class="ui form">
      <div class="two fields" v-if="tipoPersona === 'both' && enableSkipLegalRepresentative">
        <div class="field">
          <h3>{{ $t('legal_representative.form.do_you_have') }}</h3>
        </div>
        <div class="field">
          <sui-form-fields inline>
            <sui-form-field>
              <sui-checkbox :disabled="readOnlyMode" label="Si" radio value="si" v-model="hasRepresentative" />
            </sui-form-field>
            <sui-form-field>
              <sui-checkbox :disabled="readOnlyMode" label="No" radio value="no" v-model="hasRepresentative" />
            </sui-form-field>
            <tr>
              <span v-if="!$v.hasRepresentative.required && $v.hasRepresentative.$anyDirty" class="error-message">{{
                potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.do_you_have'))
              }}</span>
            </tr>
          </sui-form-fields>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="hasRepresentative === 'si'">
          <h4>{{ $t('legal_representative.form.description') }}</h4>
          <h5>{{ $t('general.form.description_required_fields') }}</h5>
          <div class="two fields">
            <div class="field" :class="{ error: $v.data.rfc.$error }">
              <label>{{ $t('legal_representative.form.rfc') }} *</label>
              <input
                :disabled="readOnlyMode"
                type="text"
                :placeholder="$t('legal_representative.form.rfc')"
                v-model="data.rfc"
                @blur="$v.data.rfc.$touch()"
              />
              <tr>
                <span v-if="!$v.data.rfc.required && $v.data.rfc.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.rfc')) }}
                </span>
              </tr>
              <tr>
                <span
                  v-if="
                    (!$v.data.rfc.rfcFormat || !$v.data.rfc.minLength || !$v.data.rfc.maxLength) &&
                      $v.data.rfc.$anyDirty
                  "
                  class="error-message"
                >
                  {{ potFilterNameTag($t('validation.field_invalid'), $t('legal_representative.form.rfc')) }}
                </span>
              </tr>
              <tr>
                <span v-if="!$v.data.rfc.notSameAsRFCPersonaFisica && $v.data.rfc.$anyDirty" class="error-message">{{
                  getRfcNotSameAsError()
                }}</span>
              </tr>
            </div>

            <div class="field" :class="{ error: $v.data.curp.$error }">
              <label>{{ $t('legal_representative.form.curp') }} *</label>
              <sui-input
                :disabled="readOnlyMode"
                type="text"
                :placeholder="$t('legal_representative.form.curp')"
                v-model="data.curp"
                :loading="loading"
              />
              <tr>
                <span v-if="!$v.data.curp.required && $v.data.curp.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.curp')) }}
                </span>
              </tr>
              <tr>
                <span v-if="!$v.data.curp.curpFormat && $v.data.curp.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_invalid'), $t('legal_representative.form.curp')) }}
                </span>
              </tr>
              <tr>
                <span v-if="!$v.data.curp.notSameAsCURPPersonaFisica && $v.data.curp.$anyDirty" class="error-message">{{
                  getCurpErrorNotSameAs()
                }}</span>
              </tr>
            </div>
          </div>
          <div class="two fields" :class="{ error: $v.data.entity.$error }">
            <div class="field">
              <label>{{ $t('legal_representative.form.entity') }} *</label>
              <sui-dropdown
                :disabled="readOnlyMode"
                :placeholder="$t('legal_representative.form.entity')"
                selection
                :options="entidades"
                @blur="$v.data.entity.$touch()"
                v-model="data.entity"
              />
              <tr>
                <span v-if="!$v.data.entity.required && $v.data.entity.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.entity')) }}
                </span>
              </tr>
            </div>
          </div>
          <div class="two fields">
            <div class="field" :class="{ error: $v.data.name.$error }">
              <label>{{ $t('legal_representative.form.name') }} *</label>
              <input
                type="text"
                :disabled="readOnlyMode"
                :placeholder="$t('legal_representative.form.name')"
                v-model="data.name"
                @blur="$v.data.name.$touch()"
              />
              <tr>
                <span v-if="!$v.data.name.required && $v.data.name.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.name')) }}
                </span>
              </tr>
              <tr>
                <span v-if="!$v.data.name.names && $v.data.name.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_invalid'), $t('legal_representative.form.name')) }}
                </span>
              </tr>
            </div>

            <div class="field" :class="{ error: $v.data.lastname.$error }">
              <label>{{ $t('legal_representative.form.lastname') }} *</label>
              <input
                :disabled="readOnlyMode"
                type="text"
                :placeholder="$t('legal_representative.form.lastname')"
                v-model="data.lastname"
                @blur="$v.data.lastname.$touch()"
              />
              <tr>
                <span v-if="!$v.data.lastname.required && $v.data.lastname.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.lastname')) }}
                </span>
              </tr>
              <tr>
                <span v-if="!$v.data.lastname.names && $v.data.lastname.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_invalid'), $t('legal_representative.form.lastname')) }}
                </span>
              </tr>
            </div>
          </div>
          <div class="two fields">
            <div class="field" :class="{ error: $v.data.second_lastname.$error }">
              <label>{{ $t('legal_representative.form.second_lastname') }} *</label>
              <input
                type="text"
                :disabled="readOnlyMode"
                :placeholder="$t('legal_representative.form.second_lastname')"
                v-model="data.second_lastname"
                @blur="$v.data.second_lastname.$touch()"
              />
              <tr>
                <span
                  v-if="!$v.data.second_lastname.required && $v.data.second_lastname.$anyDirty"
                  class="error-message"
                >
                  {{
                    potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.second_lastname'))
                  }}
                </span>
              </tr>
              <tr>
                <span v-if="!$v.data.second_lastname.names && $v.data.second_lastname.$anyDirty" class="error-message">
                  {{
                    potFilterNameTag($t('validation.field_invalid'), $t('legal_representative.form.second_lastname'))
                  }}
                </span>
              </tr>
            </div>

            <div class="field" :class="{ error: $v.formattedBirthdate.$error }">
              <label>{{ $t('legal_representative.form.birthdate') }} *</label>
              <div class="ui calendar" id="fecha_nacimiento_legal">
                <datepicker
                  :disabled="readOnlyMode"
                  :placeholder="$t('legal_representative.form.birthdate')"
                  v-model="$v.formattedBirthdate.$model"
                  :value="formattedBirthdate"
                  valuetype="format"
                  format="dd/MM/yyyy"
                  :language="es"
                  :open-date="disabledDates.from"
                  :initial-view="'year'"
                  @input="$v.formattedBirthdate.$touch()"
                  :disabled-dates="disabledDates"
                ></datepicker>
                <tr>
                  <span v-if="!$v.formattedBirthdate.required && $v.formattedBirthdate.$anyDirty" class="error-message">
                    {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.birthdate')) }}
                  </span>
                </tr>
              </div>
            </div>
          </div>
          <div class="two fields">
            <div class="field" :class="{ error: $v.data.nationality.$error }">
              <label>{{ $t('legal_representative.form.nationality') }} *</label>
              <sui-dropdown
                :disabled="readOnlyMode"
                :placeholder="$t('legal_representative.form.nationality')"
                selection
                :options="nacionalidades"
                @blur="$v.data.nationality.$touch()"
                v-model="data.nationality"
              />
              <tr>
                <span v-if="!$v.data.nationality.required && $v.data.nationality.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.nationality')) }}
                </span>
              </tr>
            </div>

            <div class="field" :class="{ error: $v.genderTemp.$error }">
              <label>{{ $t('legal_representative.gender') }} *</label>

              <sui-dropdown
                :disabled="readOnlyMode"
                :placeholder="$t('legal_representative.gender')"
                selection
                :options="sexos"
                @blur="$v.genderTemp.$touch()"
                v-model="genderTemp"
              />

              <tr>
                <span v-if="!$v.genderTemp.required && $v.genderTemp.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.gender')) }}
                </span>
              </tr>
            </div>
          </div>

          <div class="two fields">
            <div class="field" :class="{ error: $v.data.email.$error }">
              <label>{{ $t('legal_representative.form.email') }} *</label>
              <input
                type="text"
                :disabled="readOnlyMode"
                :placeholder="$t('legal_representative.form.email')"
                v-model="data.email"
                @blur="$v.data.email.$touch()"
              />
              <tr>
                <span v-if="!$v.data.email.required && $v.data.email.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.email')) }}
                </span>
              </tr>
              <tr>
                <span v-if="!$v.data.email.email" class="error-message">
                  {{ potFilterNameTag($t('validation.field_invalid'), $t('legal_representative.form.email')) }}
                </span>
              </tr>
            </div>

            <div class="field" :class="{ error: $v.data.phone.$error }">
              <label>{{ $t('legal_representative.form.phone_number') }} *</label>
              <input
                :disabled="readOnlyMode"
                name="phone"
                :placeholder="$t('legal_representative.form.phone_number')"
                v-model="data.phone"
                v-mask="'(##) #### ####'"
                @blur="$v.data.phone.$touch()"
              />
              <tr>
                <span v-if="!$v.data.phone.required && $v.data.phone.$anyDirty" class="error-message">
                  {{ potFilterNameTag($t('validation.field_required'), $t('legal_representative.form.phone_number')) }}
                </span>
              </tr>

              <tr>
                <span
                  v-if="(!$v.data.phone.minLength || !$v.data.phone.maxLength) && $v.data.phone.$anyDirty"
                  class="error-message"
                >
                  {{ potFilterNameTag($t('validation.field_invalid'), $t('legal_representative.form.phone_number')) }}
                </span>
              </tr>
            </div>
          </div>
        </div>
      </transition>
    </form>
    <modal :error="mineError" v-show="isModalVisible" @close="closeModal" />
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  helpers,
  sameAs, //eslint-disable-line
  not //eslint-disable-line
} from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { es } from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';
import modal from '../shared/Modal';
import { nacionalidades, entidades, sexos } from '@/shared/constants';
import moment from 'moment';
import { rfcFormat } from '../../utilities/rfcValidators';
import { curpFormat } from '../../utilities/curpValidators';

const names = helpers.regex('names', /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]+([ a-zA-ZÀ-ÿ\u00f1\u00d1]+)*$/);

export default {
  props: {
    tipoPersona: {
      type: String,
      default() {
        return '';
      }
    },
    rfcPersona: {
      type: String,
      default() {
        return '';
      }
    },
    curpPersona: {
      type: String,
      default() {
        return '';
      }
    },
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    enableSkipStep: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      sexos: sexos,
      es: es,
      loading: false,
      nacionalidades: nacionalidades,
      entidades: entidades,
      formattedBirthdate: '',
      genderTemp: null,
      hasRepresentative: 'si',
      disabledDates: {
        // Disable all dates up to specific date
        from: moment()
          .subtract(18, 'years')
          .toDate(),
        // Disable all dates after specific date
        to: moment()
          .subtract(120, 'years')
          .toDate()
      },
      mineError: {
        title: '',
        description: ''
      },
      isModalVisible: false,
      data: {
        rfc: '',
        curp: '',
        gender: null,
        second_lastname: '',
        lastname: '',
        name: '',
        birthdate: '',
        nationality: 'MEX',
        entity: null,
        phone: '',
        email: ''
      },
      enableSkipLegalRepresentative: this.enableSkipStep
    };
  },
  components: {
    datepicker,
    modal
  },
  computed: {
    isSameRFCPersonFisica() {
      return this.rfcPersona === this.data.rfc;
    },
    isSameCURPPersonFisica() {
      return this.curpPersona === this.data.curp;
    }
  },
  methods: {
    getEntityById(id) {
      return this.entidades.filter(i => i.value === id)[0];
    },
    getNationalityById(id) {
      return this.nacionalidades.filter(i => i.value === id)[0];
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    loadCurpData() {
      this.$v.data.curp.$touch();
      if (!this.$v.data.curp.$error && this.data.curp.length === 18) {
        this.loading = true;
        this.fetchData(this.data.curp)
          .then(res => {
            this.loading = false;
            this.setCurpData(this.loadCurp(res));
          })
          .catch(err => {
            if (err && err.status === 404) {
              this.mineError.description = this.$t('renapo.0002');
              this.mineError.title = err;
              this.isModalVisible = true;
              this.loading = false;
            } else {
              this.mineError.description = this.$t('renapo.socket');
              this.mineError.title = err;
              this.isModalVisible = true;
              this.loading = false;
            }
          });
      }
      this.$v.data.curp.$touch();
    },
    ...mapGetters({
      loadCurp: 'representante_legal/LoadCurp'
    }),
    ...mapActions({
      fetchData: 'representante_legal/loadCurpData',
      setData: 'representante_legal/setData'
    }),
    /**
     * jaimexcastillo
     * @date 2020-05-29
     * @param {array} newCurp
     *  you use the newCurp to set data in the vue variables
     * @returns void
     */
    setCurpData(newCurp) {
      if (newCurp) {
        this.data.curp = newCurp.CURP;
        this.data.name = this.$replaceSpecialChars(newCurp.Nombres);
        this.data.second_lastname = this.$replaceSpecialChars(newCurp.Apellido2);
        this.data.lastname = this.$replaceSpecialChars(newCurp.Apellido1);
        this.data.birthdate = newCurp.FechNac;
        this.data.entity = parseInt(newCurp.NumEntidadReg);
        this.data.nationality = newCurp.Nacionalidad;
        this.genderTemp = newCurp.Sexo.toLowerCase() === 'h' ? 'Masculino' : 'Femenino';
        this.formattedBirthdate = moment(newCurp.FechNac, 'DD/MM/YYYY').toDate();
        this.$v.data.name.$reset();
        this.$v.data.curp.$reset();
        this.$v.data.second_lastname.$reset();
        this.$v.data.lastname.$reset();
        this.$v.data.birthdate.$reset();
        this.$v.data.entity.$reset();
        this.$v.data.nationality.$reset();
        this.$v.data.gender.$reset();
      }
    },
    validateForm() {
      let isValidForm = false;
      if (this.hasRepresentative === 'no') {
        isValidForm = true;
      } else {
        this.$v.$reset();
        this.$v.$touch();
        isValidForm = !this.$v.$error;
        if (isValidForm) {
          this.$emit('data', {
            rfc: this.data.rfc,
            curp: this.data.curp,
            gender: this.data.gender,
            second_lastname: this.data.second_lastname,
            lastname: this.data.lastname,
            name: this.data.name,
            birthdate: this.data.birthdate,
            nationality: this.getNationalityById(this.data.nationality).text,
            entity: this.getEntityById(this.data.entity).text,
            phone: this.data.phone,
            email: this.data.email
          });
        }
      }
      return isValidForm;
      /* this.$emit('data', this.data);
       * return true; */
    },
    /**
     * Placeholder function for the global plugin function <$potFilterNameTag>
     * encapsulated on this function beause if you use <$potFilterNameTag> directly on the template
     * you end up with errors like "$potFilterNameTag" was referenced on the instance but not defined
     *
     * @param value pot editor tag to be replaced
     * @value string which is going to be replaced on the __name__ poteditor string.
     *
     * @return string
     */
    potFilterNameTag(value, param) {
      return this.$potFilterNameTag(value, param);
    },
    getRfcNotSameAsError() {
      let errorText = this.potFilterNameTag(
        this.$t('validation.not_same_as'),
        this.$t('legal_representative.form.rfc')
      );
      errorText = this.$potFilterNotSame(errorText, 'RFC');
      errorText = this.$potFilterModule(errorText, 'Datos de Identificación');
      return errorText;
    },
    /**
     * Display the error when you have an error typing the curp data,
     * Created to replace the different filters that the "not same as" kind of validation required
     *
     * @return string
     */
    getCurpErrorNotSameAs() {
      let errorText = this.$potFilterNameTag(
        this.$t('validation.not_same_as'),
        this.$t('legal_representative.form.curp')
      );
      errorText = this.$potFilterNotSame(errorText, this.$t('legal_representative.form.curp'));
      errorText = this.$potFilterModule(errorText, this.$t('identification_data.form.title'));
      return errorText;
    }
  },
  watch: {
    'data.rfc': {
      handler: function(newData) {
        this.data.rfc = this.$uppercase(newData);
      }
    },
    'data.curp': {
      handler: function(newData) {
        this.data.curp = this.$uppercase(newData);
      }
    },
    hasRepresentative: {
      handler: function(newData) {
        this.$emit('setHasLegalRepresentant', newData === 'si');
      }
    },
    formattedBirthdate: {
      handler: function(newData) {
        this.data.birthdate = moment(newData, 'DD/MM/yyyy').format('YYYY/MM/DD');
      }
    },
    genderTemp: {
      handler: function(newGender) {
        this.data.gender = newGender.toLowerCase() === 'masculino' ? 'male' : 'female';
      }
    }
  },
  validations: {
    data: {
      rfc: {
        required,
        minLength: minLength(13),
        maxLength: maxLength(13),
        rfcFormat,
        notSameAsRFCPersonaFisica() {
          return !this.isSameRFCPersonFisica;
        }
      },
      curp: {
        required,
        minLength: minLength(14),
        curpFormat,
        notSameAsCURPPersonaFisica() {
          return !this.isSameCURPPersonFisica;
        }
      },
      second_lastname: {
        required,
        minLength: minLength(1),
        names
      },
      lastname: {
        required,
        minLength: minLength(1),
        names
      },
      name: {
        required,
        minLength: minLength(1),
        names
      },
      nationality: {
        required
      },
      phone: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(14)
      },
      entity: {
        required
      },
      //TODO: validate if this still needed
      birthdate: {
        required,
        minLength: minLength(4)
      },
      gender: {
        required
      },
      email: {
        required,
        email
      }
    },
    formattedBirthdate: {
      required
    },
    genderTemp: {
      required
    },
    hasRepresentative: {
      required
    }
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
  float: left;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
