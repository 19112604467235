import { postObligations } from '../../shared/services';
import { postInscriptionForm } from '../../shared/services';

const state = {
    isSaved: false,
    validated: false,
    data: null
}

const mutations = {
    'Set_SavedStatus' (state, isSaved) {
        state.isSaved = isSaved;
    },
    'Set_Validated' (state, validated) {
        state.validated = validated;
    },
    'Set_Data' (state, data) {
        state.data = data;
    }	    
}


const getters = {

    getIsSaved(state) {
        return state.isSaved;
    },
    loadValidated() {
        return state.validated;
	},
	getData() {
        return state.data;
    }

}

const actions = {

    /**
     * @param {any} {commit
     * @param {any} state} recives the vue data from a component
     * @returns {any} response with the result of the post service 
     */    
    saveData({ state }) {
        if (!state.data) 
            return;
        
        if (state.data.isn_data) {
            state.data.isn_data.preack_id = state.data.preack_id;
            return new Promise((resolve, reject) => {
                postInscriptionForm(state.data.isn_data).then(() => {
                    postObligations(state.data)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => reject(error))
                }).catch(() => {
                    reject();
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                postObligations(state.data)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => reject(error))
            });
        }
    },
    setData: ({ commit }, data) => {
        commit('Set_Data', data)
    },
    setIsSaved({ commit }, isSaved) {
        commit('Set_SavedStatus', isSaved);
    },
    validator({ commit }, validated) {
        commit('Set_Validated', validated)
    }
};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
