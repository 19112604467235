<template>    
  <sui-form>
    <h4>  {{ $t('book.form.description') }} </h4>
    <h5>  {{ $t('general.form.description_required_fields') }} </h5>
    <div class="two fields">
      <div class="field" size="tiny" :class="{error: $v.formData.no_public_deed.$error}">
        <label>{{ $t("book.form.no_public_deed") }} *</label>
        <input
            maxlength="100"
            :disabled="readOnlyMode"
            placeholder="No. de escritura"
            type="text"
            @blur="$v.formData.no_public_deed.$touch()"
            v-model="formData.no_public_deed"/>
        <span v-if="!$v.formData.no_public_deed.required &&  $v.formData.no_public_deed.$anyDirty"   class="error-message">
          {{ $t('validation.field_required') | potfilter($t('book.form.no_public_deed')) }}
        </span>
      </div>
      <div class="field" size="tiny" :class="{error: $v.formData.book.$error}">
        <label>{{ $t('book.form.book') }} *</label>
        <input
            maxlength="100"
            :disabled="readOnlyMode"
            placeholder="Libro"
            type="text"
            @blur="$v.formData.book.$touch()"
            v-model="formData.book"/>
        <span v-if="!$v.formData.book.required &&  $v.formData.book.$anyDirty" class="error-message">
          {{ $t('validation.field_required') | potfilter($t('book.form.book')) }}
        </span>
      </div>
    </div>
    <div class="two fields">
      <div class="field" size="tiny" :class="{error: $v.formData.sheet.$error}">
        <label>{{ $t('book.form.sheet') }} *</label>
        <input
            maxlength="100"
            :disabled="readOnlyMode"
            :placeholder="$t('book.form.sheet')"
            type="text"
            @blur="$v.formData.sheet.$touch()"
            v-model="formData.sheet"/>
        <span v-if="!$v.formData.sheet.required &&  $v.formData.sheet.$anyDirty"   class="error-message">
          {{ $t('validation.field_required') | potfilter($t('book.form.sheet')) }}
        </span>
      </div>
      <div class="field" size="tiny">
        <label>{{ $t('book.form.rfc_notary_public') }}</label>
        <input
            maxlength="100"
            :disabled="readOnlyMode"
            :placeholder="$t('book.form.rfc_notary_public')"
            type="text"
            v-model="formData.rfc_notary_public"/>
      </div>
    </div>
    <div class="field" size="tiny" :class="{error: $v.formData.rpp_and_trade_data.$error}">
      <label>{{ $t('book.form.rpp_and_trade_data') }} *</label>
      <textarea
          maxlength="100"
          :disabled="readOnlyMode"
          rows="2"
          :placeholder="$t('book.form.rpp_and_trade_data')"
          type="textarea"
          @blur="$v.formData.rpp_and_trade_data.$touch()"
          v-model="formData.rpp_and_trade_data"/>
      <span v-if="!$v.formData.rpp_and_trade_data.required &&  $v.formData.rpp_and_trade_data.$anyDirty"   class="error-message">
        {{ $t('validation.field_required') | potfilter($t('book.form.rpp_and_trade_data')) }}
      </span>
    </div>
    <div class="field" size="tiny" :class="{error: $v.formData.date_signature_constitution.$error}">
      <label>{{ $t('book.form.date_signature_constitution') }} *</label>
      <datepicker placeholder="Fecha de Constitución"
                  :disabled="readOnlyMode"
                  v-model="formData.date_signature_constitution"
                  :value="dateSignatureFormateado"
                  @blur="$v.formData.date_signature_constitution.$touch()"
                  valueType="format"
                  format="dd/MM/yyyy"
                  :language="es"
                  :open-date="disabledDates.from"
                  :initial-view="'year'"
                  :disabled-dates="disabledDates" />
      <span v-if="!$v.formData.date_signature_constitution.required && $v.formData.date_signature_constitution.$anyDirty"  class="error-message">
        {{ $t('validation.field_required') | potfilter($t('book.form.date_signature_constitution')) }}
      </span>
    </div>
    <div class="field" :class="{ error: $v.formData.nationality.$error }">
      <label>{{ $t('book.form.nationality') }} *</label>
      <sui-dropdown
          :disabled="readOnlyMode"
          :placeholder="$t('book.form.nationality')"
          selection
          :options="nacionalidades"
          v-model="$v.formData.nationality.$model"
          @blur="$v.formData.nationality.$touch()"
      />
      <div v-if="$v.formData.nationality.$error" class="error-message">
            <span v-if="!$v.formData.nationality.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('book.form.nationality')) }}
            </span>
      </div>
    </div>
  </sui-form>
</template>

<script>
    import { es } from 'vuejs-datepicker/dist/locale'
    import {minLength, required} from 'vuelidate/lib/validators';
    import moment from 'moment';
    import datepicker from 'vuejs-datepicker';
    import { nacionalidades } from '@/shared/constants';

    export default {
      props: {
          readOnlyMode: {
        type: Boolean,
        default () {
            return false;
        }
          }
      },
      data() {
          return {
            formTouched : null,
            dateSignatureFormateado: '',
            es: es,
            disabledDates: {
                // Disable all dates up to specific date
                from: moment().toDate(),
                // Disable all dates after specific date
                to: moment().subtract(300, 'years').toDate()
            },
            errors : false,
            formData : {
                no_public_deed : '',
                book : '',
                sheet : '',
                rfc_notary_public : '',
                rpp_and_trade_data : '',
                constitution_date : '',
                nationality: null,
            },
            nacionalidades: nacionalidades
          }
      },
      components: {
          datepicker
      },
      methods: {
          validateForm() {
            this.$v.$reset();
            this.$v.$touch();
            let isValidForm = !this.$v.$error;
            if (isValidForm) {
                this.$emit('data', this.formData);
            }
            return isValidForm;
          }
      },
      validations: {
          formData: {
            no_public_deed: {
                required
            },
            book: {
                required
            },
            sheet: {
                required
            },
            rpp_and_trade_data: {
                required
            },
            date_signature_constitution: {
                required
            },
            nationality: {
              required,
              minLength: minLength(1)
            },
          }
      },
      watch: {
          dateSignatureFormateado: {
        handler: function (newDate){
            this.formData.date_signature_constitution = moment(newDate).format('YYYY/MM/DD');
        }
          }
      }
    }
</script>

<style scoped>
    .inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
    }
</style>
