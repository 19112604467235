export default {
    setCurrentAlias(state, tramite) {
        state.currentAlias = tramite;
    },
    setCurrentModule(state, module) {
        if (state.getCurrentAlias !== module) {
            state.currentModule = module
        }
    },
    setIsLoading(state, loading) {
        state.loading = loading;
    },
    setResponseType(state, type) {
      state.responseStatus.type = type;
    },
    setResponseMessage(state, message) {
      state.responseStatus.message = message;
    },
    setTipoPersona(state, tipoPersona) {
        state.tipoPersona = tipoPersona;
    },
    setErrorModalData(state, errorModalData) {
        state.errorModalData = errorModalData;
    },
    setDisableRouteLeaveDialog (state, disable) {
        state.disableRouteLeaveDialog = disable;
    }
}
