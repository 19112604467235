var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"datos-adjuntos-container ui container"}},[_c('form',{staticClass:"ui form"},[_c('h4',[_vm._v(_vm._s(_vm.$t('attachments.form.description')))]),_c('h5',[_vm._v(_vm._s(_vm.$t('general.form.description_required_fields')))]),(_vm.tipoPersona === 'both')?_c('div',[_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaFisica.identification_id_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.identification_id'))+" *")]),_c('input',{ref:"identification_id_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"identification_id_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaFisica', 'identification_id_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaFisica.identification_id_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaFisica.identification_id_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaFisica.identification_id_file.required &&
              _vm.$v.datosAdjuntos.personaFisica.identification_id_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.identification_id')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.identification_id_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaFisica.identification_id_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.identification_id')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.identification_id_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaFisica.identification_id_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.identification_id')),'4'))+" ")]):_vm._e()]),_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaFisica.cif_sat_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.cif_sat'))+" *")]),_c('input',{ref:"cif_sat_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"cif_sat_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaFisica', 'cif_sat_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaFisica.cif_sat_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaFisica.cif_sat_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaFisica.cif_sat_file.required &&
              _vm.$v.datosAdjuntos.personaFisica.cif_sat_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.cif_sat')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.cif_sat_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaFisica.cif_sat_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.cif_sat')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.cif_sat_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaFisica.cif_sat_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.cif_sat')),'4'))+" ")]):_vm._e()]),_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaFisica.address_proof_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.address_proof'))+" *")]),_c('input',{ref:"address_proof_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"address_proof_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaFisica', 'address_proof_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaFisica.address_proof_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaFisica.address_proof_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaFisica.address_proof_file.required &&
              _vm.$v.datosAdjuntos.personaFisica.address_proof_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.address_proof')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.address_proof_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaFisica.address_proof_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.address_proof')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.address_proof_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaFisica.address_proof_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.address_proof')),'4'))+" ")]):_vm._e()]),(_vm.hasLegalRepresentant)?_c('div',[_c('div',[_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.legal_representative_identification'))+" *")]),_c('input',{ref:"legal_representative_identification_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"legal_representative_identification_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaFisica', 'legal_representative_identification_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$touch()}}}),(
                !_vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.required &&
                  _vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$anyDirty
              )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.legal_representative_identification')))+" ")]):(
                !_vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.isValidFileType &&
                  _vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$anyDirty
              )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.legal_representative_identification')),_vm.allowedFileTypesToString))+" ")]):(
                !_vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.isValidFileSize &&
                  _vm.$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$anyDirty
              )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.legal_representative_identification')),'4'))+" ")]):_vm._e()]),_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaFisica.legal_representative_power_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.legal_representative_power')))]),_c('input',{ref:"legal_representative_power_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"legal_representative_power_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaFisica', 'legal_representative_power_file')}}}),(
              !_vm.$v.datosAdjuntos.personaFisica.legal_representative_power_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaFisica.legal_representative_power_file.$anyDirty
              )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.legal_representative_power')),_vm.allowedFileTypesToString))+" ")]):(
              !_vm.$v.datosAdjuntos.personaFisica.legal_representative_power_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaFisica.legal_representative_power_file.$anyDirty
              )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.legal_representative_power')),'4'))+" ")]):_vm._e()])])]):_vm._e(),(_vm.hasEnvironmentalTax)?[_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.environmental_tax'))+" *")]),_c('input',{ref:"environmental_tax_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"environmental_tax_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaFisica', 'environmental_tax_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.required &&
              _vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.environmental_tax')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.environmental_tax')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaFisica.environmental_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.environmental_tax')),'4'))+" ")]):_vm._e()])]:_vm._e()],2):_vm._e(),(_vm.tipoPersona === 'moral')?_c('div',[_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaMoral.cif_sat_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.cif_sat'))+" *")]),_c('input',{ref:"cif_sat_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"cif_sat_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaMoral', 'cif_sat_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaMoral.cif_sat_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaMoral.cif_sat_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaMoral.cif_sat_file.required &&
              _vm.$v.datosAdjuntos.personaMoral.cif_sat_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.cif_sat')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.cif_sat_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaMoral.cif_sat_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.cif_sat')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.cif_sat_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaMoral.cif_sat_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.cif_sat')),'4'))+" ")]):_vm._e()]),_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.constitutive_act'))+" *")]),_c('input',{ref:"constitutive_act_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"constitutive_act_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaMoral', 'constitutive_act_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.required &&
              _vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.constitutive_act')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.constitutive_act')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaMoral.constitutive_act_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.constitutive_act')),'4'))+" ")]):_vm._e()]),_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.legal_representative_identification'))+" *")]),_c('input',{ref:"legal_representative_identification_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"legal_representative_identification_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaMoral', 'legal_representative_identification_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.required &&
              _vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.legal_representative_identification')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.legal_representative_identification')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.legal_representative_identification')),'4'))+" ")]):_vm._e()]),_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaMoral.legal_representative_power_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.legal_representative_power')))]),_c('input',{ref:"legal_representative_power_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"legal_representative_power_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaMoral', 'legal_representative_power_file')}}}),(
          !_vm.$v.datosAdjuntos.personaMoral.legal_representative_power_file.isValidFileType &&
          _vm.$v.datosAdjuntos.personaMoral.legal_representative_power_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.legal_representative_power')),_vm.allowedFileTypesToString))+" ")]):(
          !_vm.$v.datosAdjuntos.personaMoral.legal_representative_power_file.isValidFileSize &&
          _vm.$v.datosAdjuntos.personaMoral.legal_representative_power_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.legal_representative_power')),'4'))+" ")]):_vm._e()]),_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaMoral.address_proof_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.address_proof'))+" *")]),_c('input',{ref:"address_proof_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"address_proof_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaMoral', 'address_proof_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaMoral.address_proof_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaMoral.address_proof_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaMoral.address_proof_file.required &&
              _vm.$v.datosAdjuntos.personaMoral.address_proof_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.address_proof')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.address_proof_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaMoral.address_proof_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.address_proof')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.address_proof_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaMoral.address_proof_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.address_proof')),'4'))+" ")]):_vm._e()]),(_vm.hasEnvironmentalTax)?[_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.environmental_tax'))+" *")]),_c('input',{ref:"environmental_tax_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"environmental_tax_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaMoral', 'environmental_tax_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.required &&
              _vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.environmental_tax')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.environmental_tax')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaMoral.environmental_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.environmental_tax')),'4'))+" ")]):_vm._e()])]:_vm._e(),(_vm.hasISHRTax)?[_c('div',{staticClass:"field",class:{ error: _vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.$error }},[_c('label',[_vm._v(_vm._s(_vm.$t('attachments.form.ishr_tax'))+" *")]),_c('input',{ref:"ishr_tax_file",attrs:{"disabled":_vm.readOnlyMode,"type":"file","accept":_vm.acceptFileTypes,"id":"ishr_tax_file"},on:{"change":function($event){return _vm.handleFileUpload($event, 'personaMoral', 'ishr_tax_file')},"input":function($event){return _vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.$touch()},"blur":function($event){return _vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.$touch()}}}),(
            !_vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.required &&
              _vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter")(_vm.$t('validation.field_required'),_vm.$t('attachments.form.ishr_tax')))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.isValidFileType &&
              _vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_type")(_vm._f("potfilter")(_vm.$t('validation.file_type_invalid'),_vm.$t('attachments.form.ishr_tax')),_vm.allowedFileTypesToString))+" ")]):(
            !_vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.isValidFileSize &&
              _vm.$v.datosAdjuntos.personaMoral.ishr_tax_file.$anyDirty
          )?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm._f("potfilter_file_size")(_vm._f("potfilter")(_vm.$t('validation.file_size_invalid'),_vm.$t('attachments.form.ishr_tax')),'4'))+" ")]):_vm._e()])]:_vm._e()],2):_vm._e(),_c('br'),_c('br')]),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"error":_vm.mineError},on:{"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }