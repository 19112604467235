export default {
    setData(state, data) {
        state.data = data;
    },
    setSavedStatus(state, isSaved) {
        state.isSaved = isSaved;
    },
    setValidated (state, validated) {
        state.validated = validated
    }
} 