<template>
  <div class="ui container ">
    <form class="ui form">
      <h4>{{ $t('tax_increase.form.description') }}</h4>
      <h5>{{ $t('general.form.description_required_fields') }}</h5>
      <div class="two fields">
        <div class="field" :class="{ error: $v.tax_increase.taxes.$error }">
          <sui-form-fields grouped>
            <label>{{ $t('tax_increase.form.taxes') }} *</label>
            <sui-form-field v-for="tax in getTaxes" v-bind:key="tax">
              <sui-checkbox
                :label="tax"
                :value="tax"
                @change="addTax(tax, $event)"
                @blur="$v.tax_increase.taxes.$touch()"
                :disabled="readOnlyMode"
              />
            </sui-form-field>
          </sui-form-fields>

          <span v-if="!$v.tax_increase.taxes.required && $v.tax_increase.taxes.$anyDirty" class="error-message">
            {{ $t('validation.field_required') | potfilter($t('tax_increase.form.taxes')) }}
          </span>
        </div>
      </div>

      <transition name="slide" mode="out-in">
        <template v-if="isISNSelected">
          <div>
            <div class="two fields">
              <div class="field" :class="{ error: $v.isnData.employees_number.$error }">
                <label>{{ $t('inscription_request.form.employees_number') }} {{ isISNSelected ? '*' : '' }}</label>
                <input
                  :disabled="readOnlyMode"
                  type="text"
                  name="employees_number"
                  :placeholder="$t('inscription_request.form.employees_number')"
                  v-model="isnData.employees_number"
                  @blur="$v.isnData.employees_number.$touch()"
                />

                <span v-if="!$v.isnData.employees_number.numeric" class="error-message">
                  {{ $t('validation.field_invalid') | potfilter($t('inscription_request.form.employees_number')) }}
                </span>
                <span
                  v-if="!$v.isnData.employees_number.required && $v.isnData.employees_number.$anyDirty"
                  class="error-message"
                >
                  {{ $t('validation.field_required') | potfilter($t('inscription_request.form.employees_number')) }}
                </span>
              </div>

              <div class="field">
                <label
                  >{{ $t('inscription_request.form.imss_started_date') }} {{ isPatronalOrImssFilled ? '*' : '' }}</label
                >
                <div class="ui">
                  <datepicker
                    :disabled="readOnlyMode"
                    :placeholder="$t('inscription_request.form.imss_started_date')"
                    v-model="imssStartedDateFormat"
                    :value="imssStartedDateFormat"
                    :language="es"
                    @input="
                      !$v.isnData.imss_started_date.$touch();
                      throwDependantValidations();
                    "
                    :initial-view="'year'"
                    :open-date="disabledDates.from"
                    :disabled-dates="disabledDates"
                    valueType="format"
                    format="dd/MM/yyyy"
                  >
                  </datepicker>
                </div>
                <span
                  v-if="!$v.isnData.imss_started_date.required && $v.isnData.imss_started_date.$anyDirty"
                  class="error-message"
                >
                  {{ $t('validation.field_required') | potfilter($t('inscription_request.form.imss_started_date')) }}
                </span>
              </div>
            </div>

            <div class="two fields">
              <div class="field" :class="{ error: $v.isnData.patronal_register_number.$error }">
                <label
                  >{{ $t('inscription_request.form.patronal_register_number') }}
                  {{ isPatronalOrImssFilled ? '*' : '' }}</label
                >
                <input
                  maxlength="11"
                  :disabled="readOnlyMode"
                  type="text"
                  name="patronal_register_number"
                  :placeholder="$t('inscription_request.form.patronal_register_number')"
                  v-model="isnData.patronal_register_number"
                  @blur="
                    $v.isnData.patronal_register_number.$touch();
                    throwDependantValidations();
                  "
                />

                <span
                  v-if="!$v.isnData.patronal_register_number.required && $v.isnData.patronal_register_number.$anyDirty"
                  class="error-message"
                >
                  {{
                    $t('validation.field_required') | potfilter($t('inscription_request.form.patronal_register_number'))
                  }}
                </span>
                <span
                  v-if="
                    (!$v.isnData.patronal_register_number.minLength ||
                      !$v.isnData.patronal_register_number.maxLength ||
                      !$v.isnData.patronal_register_number.alphaNum) &&
                      $v.isnData.patronal_register_number.$anyDirty
                  "
                  class="error-message"
                >
                  {{
                    $t('validation.field_invalid') | potfilter($t('inscription_request.form.patronal_register_number'))
                  }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </transition>
    </form>
  </div>
</template>

<script>
import { required, numeric, alphaNum, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { es } from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
  name: 'AumentoObligacion',
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    isEnviromentalTaxICEMPSelected() {
      return this.tax_increase.taxes.includes(this.$t('taxes.icemp'));
    },
    getTaxes() {
      return [
        this.$t('taxes.isn'),
        this.$t('taxes.ipop'),
        this.$t('taxes.ija'),
        this.$t('taxes.isan'),
        this.$t('taxes.ish'),
        this.$t('taxes.icemp'),
				this.$t('taxes.iecat'),
				this.$t('taxes.ieca'),
				this.$t('taxes.iecs')
      ];
    },
    isPatronalOrImssFilled() {
      return this.isnData.patronal_register_number !== '' || this.isnData.imss_started_date !== '';
    },
    isISNSelected: function() {
      return this.tax_increase.taxes === this.$t('taxes.isn');
    }
  },
  data() {
    return {
      disabledDates: {
        from: moment().toDate(),
        to: moment()
          .subtract(300, 'years')
          .toDate()
      },
      es: es,
      tax_increase: {
        taxes: []
      },
      isnData: {
        imss_started_date: '',
        patronal_register_number: '',
        employees_number: ''
      },
      imssStartedDateFormat: ''
    };
  },
  methods: {
    validateForm() {
      if (this.isEnviromentalTaxICEMPSelected) {
				this.$emit('setHasEnvironmentalTax', true);
			} else {
				this.$emit('setHasEnvironmentalTax', false);
			}
      this.$v.$reset();
      this.$v.$touch();
      const isValidForm = !this.$v.$error;
      this.isnData = this.isISNSelected ? this.isnData : null;
      if (isValidForm)
        this.$emit('data', {
          tax_obligation_type: 'tax_increase',
          obligations: this.tax_increase.taxes,
          isn_data: this.isnData
        });
      return isValidForm;
    },
    throwDependantValidations() {
      if (this.isISNSelected) {
        if (this.$v.isnData.imss_started_date.$anyDirty) {
          this.$v.isnData.patronal_register_number.$touch();
        } else if (this.$v.isnData.patronal_register_number.$anyDirty) {
          this.$v.isnData.imss_started_date.$touch();
        }
      }
    },
    /**
     * Called on click of an element of the taxes list
     *
     * @param Object<tax> type of tax to be added
     */
    addTax(tax, event) {
      if (this.tax_increase.taxes.includes(tax) && !event) {
        this.tax_increase.taxes.splice(this.tax_increase.taxes.indexOf(tax), 1);
      } else {
        this.tax_increase.taxes = [...this.tax_increase.taxes, ...[tax]];
      }
    }
  },
  validations: {
    tax_increase: {
      taxes: {
        required
      }
    },
    isnData: {
      imss_started_date: {
        required: requiredIf(function() {
          return this.isPatronalOrImssFilled;
        })
      },
      patronal_register_number: {
        alphaNum,
        minLength: minLength(11),
        maxLength: maxLength(11),
        required: requiredIf(function() {
          return this.isPatronalOrImssFilled;
        })
      },
      employees_number: {
        numeric,
        required: requiredIf(function() {
          return this.isISNSelected;
        })
      }
    }
  },
  watch: {
    imssStartedDateFormat: {
      handler: function(date) {
        this.isnData.imss_started_date = moment(date).format('YYYY/MM/DD');
      }
    }
  },
  components: {
    datepicker
  }
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.checkbox-custom {
  margin: 15px 0px 0px 0px;
}
</style>
