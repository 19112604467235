<template>
  <div id="activity-list-container">
    <DataTable :data="obligationsData" :columns="columns">
      <template #columns>
        <th class="obligation-column-name" v-for="column in columns" :key="column.id">{{ column }}</th>
      </template>
      <template slot-scope="{ row }">
        <td class="text-justify">
          {{ row.title }}
        </td>
        <td class="text-justify">
          {{ row.description }}
        </td>
        <td class="text-justify">
          {{ row.expiration_title }}
        </td>
        <td v-show="row.initial_date">
          <p>{{ row.initial_date }}</p>
        </td>
        <td v-show="row.finished_date">
          <p>{{ row.finished_date }}</p>
        </td>
        <td>
          <p>{{ row.obligation_type }}</p>
        </td>
        <!-- <td>{{ getNowDate() }}</td>
                <td>{{ row.updated_at }}</td>
                <td>ALTA</td>
                <td>
                    <a class="obligation-listing-action"
                        @click.prevent="deleteObligation(row)"
                        v-show="!row.locked || readOnlyMode" >
                        <font-awesome-icon :icon="['fa', 'trash']" size="lg" />
                    </a>
                </td> -->
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/shared/DataTable.vue';
import unionBy from 'lodash/unionBy';
import moment from 'moment';

export default {
  name: 'ListaObligaciones',
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    obligations: {
      type: Array,
      default() {
        return [];
      }
    },
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      obligationsData: this.obligations
    };
  },
  methods: {
    /**
     * Deletes an obligation from the obligationsdata array (Array<{}>)
     *
     * @param obligationRow the object desired to be deleted from the array.
     * @returns void
     */
    deleteObligation(obligationRow) {
      this.obligationsData.forEach((obligation, index) => {
        if (obligation.id === obligationRow.id) this.obligationsData.splice(index, 1);
      });
    },
    /**
     * Filters duplicated obligations.
     *
     * @param newObligations Array<{}> contains the new obligations.
     * @returns void
     */
    filterObligations(newObligations) {
      this.obligationsData = this.unionBy(this.obligationsData, newObligations, 'id');
    },
    /**
     * Wrapper for the lodash unionBy function,
     * this wrapper it's created to avoid definition erros on unit tests.
     */
    unionBy: unionBy,
    /**
     * Returns the today date in DD MMM YYYY format.
     *
     * @returns String date with format DD MMM YYYY
     */
    getNowDate() {
      return moment(Date.now()).format('DD MMM YYYY');
    }
  },
  watch: {
    obligations: {
      handler: function(newObligations) {
        this.filterObligations(newObligations);
        this.$emit('data', this.obligationsData);
      }
    }
  },
  components: {
    DataTable
  }
};
</script>

<style lang="scss">
.text-justify {
  text-align: justify;
}
tr:hover {
  .obligation-listing-action {
    /* display: block; */

    &:hover {
      color: #42b983;
      cursor: pointer;
    }
  }
}

.obligation-listing-action {
  text-decoration: none;
  color: #3c3f42;
  display: block;
}

thead {
  // th {
  //   text-align: center !important;
  //   background-color: rgb(231, 221, 221) !important;

  //   &:first-child {
  //     width: 10%;
  //   }
  //   &:nth-child(2) {
  //     width: 30%;
  //   }
  //   &:nth-child(3) {
  //     width: 30%;
  //   }
  //   &:last-child {
  //     width: 5%;
  //   }
  // }
}

tbody {
  tr {
    td {
      background-color: #e5e5e5;

      &:first-child {
        width: 10%;
        text-align: center;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        text-align: center;
      }
      &:nth-child(6) {
        text-align: center;
      }
      &:nth-child(7) {
        text-align: center;
      }

      p {
        word-break: break-all;
        text-align: center;
      }
    }
  }
}
</style>
