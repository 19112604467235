<template>
  <div class="ui container">
    <form class="ui form">
      <div class="two fields">
        <div class="field" :class="{ error: $v.data.rfc.$error }">
          <label>{{ $t('identification_data.form.rfc') }} *</label>
          <input
              :disabled="readOnlyMode"
              type="text"
              name="last-name"
              :placeholder="$t('identification_data.form.rfc')"
              v-model="$v.data.rfc.$model"
              @blur="$v.data.rfc.$touch()"
          />
          <div v-if="$v.data.rfc.$error" class="error-message">
            <span v-if="!$v.data.rfc.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.rfc')) }}
            </span>
            <span v-else-if="!$v.data.rfc.rfcFormat">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.rfc')) }}
            </span>
          </div>
        </div>
        <div class="field" :class="{ error: $v.data.rfcConfirmation.$error }">
          <label>{{ $t('identification_data.form.rfc_validate') }} *</label>
          <sui-input
              :disabled="readOnlyMode"
              type="text"
              name="last-name"
              :placeholder="$t('identification_data.form.rfc_validate')"
              v-model="$v.data.rfcConfirmation.$model"
              @keyup="loadFlow"
              @blur="$v.data.rfcConfirmation.$touch()"
              id="rfcFinal"
              :loading="isLoading"
          />
          <span v-if="$v.data.rfcConfirmation.$error" class="error-message">
            {{ $potFilterNameTag($t('La confirmacion del RFC no coincide')) }}
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { rfcFormat } from '@/utilities/rfcValidators';

export default {
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      data: {
        rfc: '',
        rfcConfirmation: ''
      },
      isLoading: false
    };
  },
  watch: {
    data: {
      handler: function(newData) {
        this.data.rfc = this.$uppercase(newData.rfc);
        this.data.rfcConfirmation = this.$uppercase(newData.rfcConfirmation);
      },
      deep: true
    }
  },
  validations: {
    data: {
      rfc: {
        required,
        rfcFormat
      },
      rfcConfirmation: {
        sameAsRfc: sameAs('rfc')
      }
    }
  },
  methods: {
    validateForm() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    isRfcConfirmationCorrect() {
      return this.data.rfc === this.data.rfcConfirmation;
    },
    loadFlow() {
      if (this.isRfcConfirmationCorrect() && this.validateForm()) {
        this.isLoading = true;
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.$emit('emitRfc', this.data);
          this.isLoading = false;
        }, 2500);
      }
    }
  }
};
</script>
