import { postPreponderantActivities } from '@/api/preponderantActivities';

export default {
    saveData({ state }) {
        if (!state.data) {
            return;
        }
        return postPreponderantActivities(state.data)
            .then((success) => {
                return Promise.resolve(success);
            }).catch((failed) => {
                return Promise.reject(failed)
            })
    },
    setData: ({ commit }, data) => {
        commit('setData', data)
    },
    setIsSaved({ commit }, isSaved) {
        commit('setSavedStatus', isSaved);
    },
    validator({ commit }, validated) {
        commit('setValidated', validated)
    }
}
