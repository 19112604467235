<template>
  <div class="ui grid container">
    <br />
    <h2 class="ui header">{{ $t('preview.overview.calculated_taxes.title') }}</h2>
    <div class="one column row">
      <ListadoObligaciones :columns="additionalContacts" :obligations="mapTaxesTableData()"></ListadoObligaciones>
    </div>
    <sui-divider />
  </div>
</template>

<script>
import ListadoObligaciones from './Obligaciones/ListadoObligaciones.vue';
import moment from 'moment';

export default {
  name: 'FiscalObligation',
  props: ['module', 'date'],
  data() {
    return {
      identificationValues: this.module,
      transactionDate: this.date,
      taxesData: this.module.taxes,
      obligationsOperation: {
        tax_increase: 'ALTA',
        tax_decrease: 'BAJA',
        default: ''
      }
    };
  },
  watch: {
    module(newVal) {
      this.identificationValues = newVal;
    },
    date(newValue) {
      this.transactionDate = newValue;
    }
  },
  computed: {
    additionalContacts() {
      if (this.module.tax_obligation_type === 'tax_increase') {
        return [
          this.$t('preview.overview.calculated_taxes.title'),
          this.$t('preview.overview.calculated_taxes.table.description'),
          this.$t('preview.overview.calculated_taxes.form.expiration_description'),
          this.$t('preview.overview.calculated_taxes.form.initial_date'),
          this.$t('preview.overview.calculated_taxes.form.status')
        ];
      } else {
        return [
          this.$t('preview.overview.calculated_taxes.title'),
          this.$t('preview.overview.calculated_taxes.table.description'),
          this.$t('preview.overview.calculated_taxes.form.expiration_description'),
          this.$t('preview.overview.calculated_taxes.form.finished_date'),
          this.$t('preview.overview.calculated_taxes.form.status')
        ];
      }
    }
  },
  methods: {
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-28
     *
     * Formats the date from the BE
     *
     * @pram customer preack id
     * @returns Object fetched data || Error server error
     */
    getFormattedDate() {
      return moment(this.transactionDate).utc().format('MM/YYYY');
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-28
     *
     * Gets the string for the Obligation Type
     *
     * @pram customer preack id
     * @returns Object fetched data || Error server error
     */
    getObligationTypeString(obligation_type) {
      return this.obligationsOperation[obligation_type] || this.meanslabelTypes.default;
    },
    isIncreaseObligations() {
      return (
          this.identificationValues.tax_obligation_type &&
          this.identificationValues.tax_obligation_type === 'tax_increase'
      );
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-28
     *
     * Maps an taxes array to the structured to be sent to update the DataTable
     *
     * @pram customer preack id
     * @returns Object fetched data || Error server error
     */
    mapTaxesTableData() {
      return this.taxesData.map(tax => {
        return {
          title: tax.title,
          description: tax.description,
          expiration_title: tax.expiration_description,
          ...(this.isIncreaseObligations() && {
            initial_date: this.getFormattedDate()
          }),
          ...(!this.isIncreaseObligations() && {
            finished_date: this.getFormattedDate()
          }),
          obligation_type: this.getObligationTypeString(this.identificationValues.tax_obligation_type)
        };
      });
    }
  },
  components: {
    ListadoObligaciones
  }
};
</script>
