import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';
import domicilio_fiscal from './modules/domicilio_fiscal';
import datos_identificacion from './modules/datos_identificacion';
import solicitud_inscripcion from './modules/solicitud_inscripcion';
import medios_contacto from './modules/medios_contacto';
import actividades_economicas_preponderantes from './modules/actividades_economicas_preponderantes';
import datos_adjuntos from './modules/datos_adjuntos';
import representante_legal from './modules/representante_legal.js';
import acta_constitutiva from './modules/acta_constitutiva';
import rec from './modules/baja_registro';
import obligation from './modules/aumento_obligacion';
import timeline from './modules/Timeline';
import vista_previa from './modules/vista_previa';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    datos_identificacion,
    domicilio_fiscal,
    timeline,
    solicitud_inscripcion,
    representante_legal,
    acta_constitutiva,
    datos_adjuntos,
    medios_contacto,
    actividades_economicas_preponderantes,
    rec,
    obligation,
    vista_previa
  }
});
