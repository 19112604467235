<template>
  <div id="mapa-dom-fiscal">
    <div class="google-map" id="google-map" ref="googleMap"></div>
		<input type="hidden" id="lat" />
		<input type="hidden" id="lng" />
  </div>		
</template>
<script>
import { Loader } from '@googlemaps/js-api-loader';


export default {
  // eslint-disable-next-line vue/multi-word-component-names
	name: 'Mapa',
  props: {
		mapConfig: Object,
		apiKey:String,
		coordenadas:Object		
  
  },

  data() {
    return {
      loader: new Loader({
        apiKey: 'AIzaSyB9ADJf-rUGvRbJVdYQHyIYl2hyr3xIPYI'
      }),
      mapOptions: {
        center: {
          lat: 25.6714,
          lng: -100.30925
        },
        zoom: 12,
        disableDefaultUI: false
      },
      markers: [{
        lat: 25.6714,
        lng: -100.30925,
        label: "Monterrey Centro, NL",
      }],
			direccion: "",
      google: null,
			map: null,
			geocoder: null,		
    }
  },

  async mounted() {

	},

  created() {
    this.loader
        .load()
        .then((google) => {
          this.google = google;
           const map = new google.maps.Map(document.getElementById("google-map"), this.mapOptions);
           this.markers.forEach((mapMarker) => {
             new google.maps.Marker({position: {lat: mapMarker.lat, lng: mapMarker.lng}, map, title: mapMarker.label});
           });
        })
  },

  methods: {

		geocodePosition(direccion) {
			const addr = direccion;

      this.loader
          .load()
          .then((google) => {
            console.log('intenta crear geocoder');
            var geocoder = new google.maps.Geocoder();
            console.log('geocoder', geocoder);
            geocoder.geocode({'address': addr},function (results,status){

              if (status === 'OK') {
                var lat=document.getElementById("lat").value=results[0].geometry.location.lat();
                var lng=document.getElementById("lng").value=results[0].geometry.location.lng();

                var myLatlng = new google.maps.LatLng(lat, lng);

                var myOptions = {
                  zoom: 15,
                  center: myLatlng,
                  mapTypeId: google.maps.MapTypeId.ROADMAP,
                  marker :marker
                };
                var map = new google.maps.Map(
                    document.getElementById('google-map'), myOptions
                )

                var marker = new google.maps.Marker({
                  position: myLatlng,
                  title: "Direccion",
                  map : map,
                  animation: google.maps.Animation.BOUNCE,
                  draggable: true,
                })


                marker.addListener("dragend", function () {
                  var coordenadas = {
                    lat:document.getElementById("lat").value = this.getPosition().lat(),
                    lng:document.getElementById("lng").value = this.getPosition().lng()
                  };

                  var geocodif = new google.maps.Geocoder;

                  geocodif.geocode({ 'location': coordenadas }, function (results, status) {
                    if (status === 'OK') {
                      console.log(results);
                    }
                  });
                });

              }
            });
          });

		},
  }
}
</script>
<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
