import axios from 'axios'

const state = {
    isSaved: false,
    validated: false,
    data: null
}

const mutations = {
    'Set_SavedStatus' (state, isSaved) {
        state.isSaved = isSaved;
    },
    'Set_Validated' (state, validated) {
        state.validated = validated;
    },
    'Set_Data' (state, data) {
        state.data = data;
    }	    
}


const getters = {

    getIsSaved(state) {
        return state.isSaved;
    },
    loadValidated() {
        return state.validated;
	},
	getData() {
        return state.data;
    }

}

const actions = {

    /**
     * @param {any} {commit
     * @param {any} state} recives the vue data from a component
     * @returns {any} response with the result of the post service 
     */    
    saveData({ state }) {
        if (!state.data) {
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post('https://hcbgilmqz2.execute-api.us-east-1.amazonaws.com/Prod/api/datosadjuntos', state.data)
            .then(() => {
                // commit('Set_Data', res.data);
                resolve();
            })
            .catch((error) => reject(error))
        });
    },
    setData: ({ commit }, data) => {
        commit('Set_Data', data)
    },
    setIsSaved({ commit }, isSaved) {
        commit('Set_SavedStatus', isSaved);
    },
    validator({ commit }, validated) {
        commit('Set_Validated', validated)
    }
};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
