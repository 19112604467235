<template>
  <div id="datos-adjuntos-container ui container">
    <form class="ui form">
      <h4>{{ $t('attachments.form.description') }}</h4>
      <h5>{{ $t('general.form.description_required_fields') }}</h5>
      <div v-if="tipoPersona === 'both'">
        <div class="field" :class="{ error: $v.datosAdjuntos.personaFisica.identification_id_file.$error }">
          <label>{{ $t('attachments.form.identification_id') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaFisica', 'identification_id_file')"
            @input="$v.datosAdjuntos.personaFisica.identification_id_file.$touch()"
            @blur="$v.datosAdjuntos.personaFisica.identification_id_file.$touch()"
            id="identification_id_file"
            ref="identification_id_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaFisica.identification_id_file.required &&
                $v.datosAdjuntos.personaFisica.identification_id_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.identification_id')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.identification_id_file.isValidFileType &&
                $v.datosAdjuntos.personaFisica.identification_id_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.identification_id'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.identification_id_file.isValidFileSize &&
                $v.datosAdjuntos.personaFisica.identification_id_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.identification_id'))
                | potfilter_file_size('4')
            }}
          </span>
        </div>

        <div class="field" :class="{ error: $v.datosAdjuntos.personaFisica.cif_sat_file.$error }">
          <label>{{ $t('attachments.form.cif_sat') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaFisica', 'cif_sat_file')"
            @input="$v.datosAdjuntos.personaFisica.cif_sat_file.$touch()"
            @blur="$v.datosAdjuntos.personaFisica.cif_sat_file.$touch()"
            id="cif_sat_file"
            ref="cif_sat_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaFisica.cif_sat_file.required &&
                $v.datosAdjuntos.personaFisica.cif_sat_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.cif_sat')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.cif_sat_file.isValidFileType &&
                $v.datosAdjuntos.personaFisica.cif_sat_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.cif_sat'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.cif_sat_file.isValidFileSize &&
                $v.datosAdjuntos.personaFisica.cif_sat_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid') | potfilter($t('attachments.form.cif_sat')) | potfilter_file_size('4')
            }}
          </span>
        </div>

        <div class="field" :class="{ error: $v.datosAdjuntos.personaFisica.address_proof_file.$error }">
          <label>{{ $t('attachments.form.address_proof') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaFisica', 'address_proof_file')"
            @input="$v.datosAdjuntos.personaFisica.address_proof_file.$touch()"
            @blur="$v.datosAdjuntos.personaFisica.address_proof_file.$touch()"
            id="address_proof_file"
            ref="address_proof_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaFisica.address_proof_file.required &&
                $v.datosAdjuntos.personaFisica.address_proof_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.address_proof')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.address_proof_file.isValidFileType &&
                $v.datosAdjuntos.personaFisica.address_proof_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.address_proof'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.address_proof_file.isValidFileSize &&
                $v.datosAdjuntos.personaFisica.address_proof_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.address_proof'))
                | potfilter_file_size('4')
            }}
          </span>
        </div>

        <div v-if="hasLegalRepresentant">
          <div>
            <div
              class="field"
              :class="{ error: $v.datosAdjuntos.personaFisica.legal_representative_identification_file.$error }"
            >
              <label>{{ $t('attachments.form.legal_representative_identification') }} *</label>
              <input
                :disabled="readOnlyMode"
                type="file"
                :accept="acceptFileTypes"
                @change="handleFileUpload($event, 'personaFisica', 'legal_representative_identification_file')"
                @input="$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$touch()"
                @blur="$v.datosAdjuntos.personaFisica.legal_representative_identification_file.$touch()"
                id="legal_representative_identification_file"
                ref="legal_representative_identification_file"
              />
              <!-- move temlate expression to his own function -->
              <span
                v-if="
                  !$v.datosAdjuntos.personaFisica.legal_representative_identification_file.required &&
                    $v.datosAdjuntos.personaFisica.legal_representative_identification_file.$anyDirty
                "
                class="error-message"
              >
                {{
                  $t('validation.field_required')
                    | potfilter($t('attachments.form.legal_representative_identification'))
                }}
              </span>

              <span
                v-else-if="
                  !$v.datosAdjuntos.personaFisica.legal_representative_identification_file.isValidFileType &&
                    $v.datosAdjuntos.personaFisica.legal_representative_identification_file.$anyDirty
                "
                class="error-message"
              >
                {{
                  $t('validation.file_type_invalid')
                    | potfilter($t('attachments.form.legal_representative_identification'))
                    | potfilter_file_type(allowedFileTypesToString)
                }}
              </span>

              <span
                v-else-if="
                  !$v.datosAdjuntos.personaFisica.legal_representative_identification_file.isValidFileSize &&
                    $v.datosAdjuntos.personaFisica.legal_representative_identification_file.$anyDirty
                "
                class="error-message"
              >
                {{
                  $t('validation.file_size_invalid')
                    | potfilter($t('attachments.form.legal_representative_identification'))
                    | potfilter_file_size('4')
                }}
              </span>
            </div>

            <div class="field"
              :class="{ error: $v.datosAdjuntos.personaFisica.legal_representative_power_file.$error }"
            >
              <label>{{ $t('attachments.form.legal_representative_power') }}</label>
              <input
                :disabled="readOnlyMode"
                type="file"
                :accept="acceptFileTypes"
                @change="handleFileUpload($event, 'personaFisica', 'legal_representative_power_file')"
                id="legal_representative_power_file"
                ref="legal_representative_power_file"
              />
              <span
                v-if="
                !$v.datosAdjuntos.personaFisica.legal_representative_power_file.isValidFileType &&
                $v.datosAdjuntos.personaFisica.legal_representative_power_file.$anyDirty
                "
                class="error-message"
              >
                  {{
                    $t('validation.file_type_invalid')
                    | potfilter($t('attachments.form.legal_representative_power'))
                    | potfilter_file_type(allowedFileTypesToString)
                  }}
              </span>

              <span
                v-else-if="
                !$v.datosAdjuntos.personaFisica.legal_representative_power_file.isValidFileSize &&
                $v.datosAdjuntos.personaFisica.legal_representative_power_file.$anyDirty
                "
                class="error-message"
              >
                  {{
                    $t('validation.file_size_invalid')
                    | potfilter($t('attachments.form.legal_representative_power'))
                    | potfilter_file_size('4')
                  }}
              </span>

            </div>
          </div>
        </div>
        <template v-if="hasEnvironmentalTax">
          <div class="field" :class="{ error: $v.datosAdjuntos.personaFisica.environmental_tax_file.$error }">
          <label>{{ $t('attachments.form.environmental_tax') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaFisica', 'environmental_tax_file')"
            @input="$v.datosAdjuntos.personaFisica.environmental_tax_file.$touch()"
            @blur="$v.datosAdjuntos.personaFisica.environmental_tax_file.$touch()"
            id="environmental_tax_file"
            ref="environmental_tax_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaFisica.environmental_tax_file.required &&
                $v.datosAdjuntos.personaFisica.environmental_tax_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.environmental_tax')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.environmental_tax_file.isValidFileType &&
                $v.datosAdjuntos.personaFisica.environmental_tax_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.environmental_tax'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaFisica.environmental_tax_file.isValidFileSize &&
                $v.datosAdjuntos.personaFisica.environmental_tax_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.environmental_tax'))
                | potfilter_file_size('4')
            }}
          </span>
        </div>
        </template>
      </div>
      <div v-if="tipoPersona === 'moral'">
        <div class="field" :class="{ error: $v.datosAdjuntos.personaMoral.cif_sat_file.$error }">
          <label>{{ $t('attachments.form.cif_sat') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaMoral', 'cif_sat_file')"
            @input="$v.datosAdjuntos.personaMoral.cif_sat_file.$touch()"
            @blur="$v.datosAdjuntos.personaMoral.cif_sat_file.$touch()"
            id="cif_sat_file"
            ref="cif_sat_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaMoral.cif_sat_file.required &&
                $v.datosAdjuntos.personaMoral.cif_sat_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.cif_sat')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.cif_sat_file.isValidFileType &&
                $v.datosAdjuntos.personaMoral.cif_sat_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.cif_sat'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.cif_sat_file.isValidFileSize &&
                $v.datosAdjuntos.personaMoral.cif_sat_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid') | potfilter($t('attachments.form.cif_sat')) | potfilter_file_size('4')
            }}
          </span>
        </div>

        <div class="field" :class="{ error: $v.datosAdjuntos.personaMoral.constitutive_act_file.$error }">
          <label>{{ $t('attachments.form.constitutive_act') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaMoral', 'constitutive_act_file')"
            @input="$v.datosAdjuntos.personaMoral.constitutive_act_file.$touch()"
            @blur="$v.datosAdjuntos.personaMoral.constitutive_act_file.$touch()"
            id="constitutive_act_file"
            ref="constitutive_act_file"
          />
          <span
            v-if="
              !$v.datosAdjuntos.personaMoral.constitutive_act_file.required &&
                $v.datosAdjuntos.personaMoral.constitutive_act_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.constitutive_act')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.constitutive_act_file.isValidFileType &&
                $v.datosAdjuntos.personaMoral.constitutive_act_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.constitutive_act'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.constitutive_act_file.isValidFileSize &&
                $v.datosAdjuntos.personaMoral.constitutive_act_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.constitutive_act'))
                | potfilter_file_size('4')
            }}
          </span>
        </div>

        <div
          class="field"
          :class="{ error: $v.datosAdjuntos.personaMoral.legal_representative_identification_file.$error }"
        >
          <label>{{ $t('attachments.form.legal_representative_identification') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaMoral', 'legal_representative_identification_file')"
            @input="$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$touch()"
            @blur="$v.datosAdjuntos.personaMoral.legal_representative_identification_file.$touch()"
            id="legal_representative_identification_file"
            ref="legal_representative_identification_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaMoral.legal_representative_identification_file.required &&
                $v.datosAdjuntos.personaMoral.legal_representative_identification_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.field_required') | potfilter($t('attachments.form.legal_representative_identification'))
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.legal_representative_identification_file.isValidFileType &&
                $v.datosAdjuntos.personaMoral.legal_representative_identification_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.legal_representative_identification'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.legal_representative_identification_file.isValidFileSize &&
                $v.datosAdjuntos.personaMoral.legal_representative_identification_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.legal_representative_identification'))
                | potfilter_file_size('4')
            }}
          </span>
        </div>

        <div class="field"
          :class="{ error: $v.datosAdjuntos.personaMoral.legal_representative_power_file.$error }"
        >
          <label>{{ $t('attachments.form.legal_representative_power') }}</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaMoral', 'legal_representative_power_file')"
            id="legal_representative_power_file"
            ref="legal_representative_power_file"
          />
          <span
            v-if="
            !$v.datosAdjuntos.personaMoral.legal_representative_power_file.isValidFileType &&
            $v.datosAdjuntos.personaMoral.legal_representative_power_file.$anyDirty
            "
            class="error-message"
          >
              {{
                $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.legal_representative_power'))
                | potfilter_file_type(allowedFileTypesToString)
              }}
          </span>

          <span
            v-else-if="
            !$v.datosAdjuntos.personaMoral.legal_representative_power_file.isValidFileSize &&
            $v.datosAdjuntos.personaMoral.legal_representative_power_file.$anyDirty
            "
            class="error-message"
          >
              {{
                $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.legal_representative_power'))
                | potfilter_file_size('4')
              }}
          </span>

        </div>

        <div class="field" :class="{ error: $v.datosAdjuntos.personaMoral.address_proof_file.$error }">
          <label>{{ $t('attachments.form.address_proof') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaMoral', 'address_proof_file')"
            @input="$v.datosAdjuntos.personaMoral.address_proof_file.$touch()"
            @blur="$v.datosAdjuntos.personaMoral.address_proof_file.$touch()"
            id="address_proof_file"
            ref="address_proof_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaMoral.address_proof_file.required &&
                $v.datosAdjuntos.personaMoral.address_proof_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.address_proof')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.address_proof_file.isValidFileType &&
                $v.datosAdjuntos.personaMoral.address_proof_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.address_proof'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.address_proof_file.isValidFileSize &&
                $v.datosAdjuntos.personaMoral.address_proof_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.address_proof'))
                | potfilter_file_size('4')
            }}
          </span>
        </div>
        <template v-if="hasEnvironmentalTax">
          <div class="field" :class="{ error: $v.datosAdjuntos.personaMoral.environmental_tax_file.$error }">
          <label>{{ $t('attachments.form.environmental_tax') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="file"
            :accept="acceptFileTypes"
            @change="handleFileUpload($event, 'personaMoral', 'environmental_tax_file')"
            @input="$v.datosAdjuntos.personaMoral.environmental_tax_file.$touch()"
            @blur="$v.datosAdjuntos.personaMoral.environmental_tax_file.$touch()"
            id="environmental_tax_file"
            ref="environmental_tax_file"
          />
          <!-- move temlate expression to his own function -->
          <span
            v-if="
              !$v.datosAdjuntos.personaMoral.environmental_tax_file.required &&
                $v.datosAdjuntos.personaMoral.environmental_tax_file.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.environmental_tax')) }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.environmental_tax_file.isValidFileType &&
                $v.datosAdjuntos.personaMoral.environmental_tax_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_type_invalid')
                | potfilter($t('attachments.form.environmental_tax'))
                | potfilter_file_type(allowedFileTypesToString)
            }}
          </span>

          <span
            v-else-if="
              !$v.datosAdjuntos.personaMoral.environmental_tax_file.isValidFileSize &&
                $v.datosAdjuntos.personaMoral.environmental_tax_file.$anyDirty
            "
            class="error-message"
          >
            {{
              $t('validation.file_size_invalid')
                | potfilter($t('attachments.form.environmental_tax'))
                | potfilter_file_size('4')
            }}
          </span>
        </div>
        </template>
        <template v-if="hasISHRTax">
          <div class="field" :class="{ error: $v.datosAdjuntos.personaMoral.ishr_tax_file.$error }">
            <label>{{ $t('attachments.form.ishr_tax') }} *</label>
            <input
                :disabled="readOnlyMode"
                type="file"
                :accept="acceptFileTypes"
                @change="handleFileUpload($event, 'personaMoral', 'ishr_tax_file')"
                @input="$v.datosAdjuntos.personaMoral.ishr_tax_file.$touch()"
                @blur="$v.datosAdjuntos.personaMoral.ishr_tax_file.$touch()"
                id="ishr_tax_file"
                ref="ishr_tax_file"
            />
            <!-- move temlate expression to his own function -->
            <span
                v-if="
              !$v.datosAdjuntos.personaMoral.ishr_tax_file.required &&
                $v.datosAdjuntos.personaMoral.ishr_tax_file.$anyDirty
            "
                class="error-message"
            >
            {{ $t('validation.field_required') | potfilter($t('attachments.form.ishr_tax')) }}
          </span>

            <span
                v-else-if="
              !$v.datosAdjuntos.personaMoral.ishr_tax_file.isValidFileType &&
                $v.datosAdjuntos.personaMoral.ishr_tax_file.$anyDirty
            "
                class="error-message"
            >
            {{
                $t('validation.file_type_invalid')
                    | potfilter($t('attachments.form.ishr_tax'))
                    | potfilter_file_type(allowedFileTypesToString)
              }}
          </span>

            <span
                v-else-if="
              !$v.datosAdjuntos.personaMoral.ishr_tax_file.isValidFileSize &&
                $v.datosAdjuntos.personaMoral.ishr_tax_file.$anyDirty
            "
                class="error-message"
            >
            {{
                $t('validation.file_size_invalid')
                    | potfilter($t('attachments.form.ishr_tax'))
                    | potfilter_file_size('4')
              }}
          </span>
          </div>
        </template>
        
      </div>
      <br /><br />
    </form>
    <Modal :error="mineError" v-show="isModalVisible" @close="closeModal" />
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { isValidFileSize, isValidFileType } from '@/utilities/fileValidators';
import { allowedFileTypes } from '@/shared/constants';
import axiosRec from '../../axios/axios-rec';
import Modal from './shared/Modal';
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'DatosAdjuntos',
  components: {
    Modal
  },
  mounted() {
    this.personTypeGroup = this.tipoPersona === 'both' ? 'personaFisica' : 'personaMoral';
  },
  props: {
    preackId: {
      type: String
    },
    tipoPersona: {
      type: String,
      default: 'fisica'
    },
    hasLegalRepresentant: {
      type: Boolean,
      default: true
    },
    hasEnvironmentalTax: {
      type: Boolean,
      default: false
    },

    hasISHRTax: {
      type: Boolean,
      default: false
    },
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    showPrivacyPolicy() {
      this.mineError.description = this.$t('privacypolicy.rec.description');
      this.mineError.title = this.$t('privacypolicy.rec.title');
      this.mineError.isPrivacy = true;
      this.showModal();
      return true;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getContentTypeByExt(ext) {
      return allowedFileTypes.find(s => s.match(ext) !== null);
    },
    submitFilesToS3: function() {
      this.progressBar = 0;
      this.saveIsLoading({
        isLoading: true,
        message: this.$t('attachments.form.loading')
      });
      const attachedFiles = this.datosAdjuntos[this.personTypeGroup];
      const personTypeGroupFiles =
        this.personTypeGroup == 'personaMoral' ? this.personaMoralFiles : this.personaFisicaFiles;
      const step = 100.0 / personTypeGroupFiles.length;
      let promises = [];
      for (let i = 0; i < personTypeGroupFiles.length; i++) {
        let filename = attachedFiles[personTypeGroupFiles[i] + '_name'];
        let fileExt = filename.split('.').pop();
        promises.push(
          this.sendFileToS3({
            preack_id: this.preackId,
            filename: filename,
            content_type: this.getContentTypeByExt(fileExt),
            file: attachedFiles[personTypeGroupFiles[i] + '_file'],
            file_key: personTypeGroupFiles[i]
          }).then((this.progressBar = this.progressBar > 100.0 ? 100 : this.progressBar + Math.ceil(step)))
        );
      }
      return promises;
    },
    sendFileToS3: function(obj) {
      return new Promise((resolve, reject) => {
        this.getSignedURL({
          preack_id: obj.preack_id,
          payload: {
            file_name: obj.filename,
            content_type: obj.content_type,
            file_key: obj.file_key
          }
        })
          .then(response => {
            const payload = new FormData();
            payload.append('file', obj.file);

            this.uploadFileToSignedURL({
              payload: payload,
              signedURL: response.data.data.uploadURL,
              content_type: obj.content_type
            })
              .then(response => {
                console.log('File Uploaded!' + JSON.stringify(response));
                resolve(response);
              })
              .catch(error => {
                reject(error);
              });
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getSignedURL(obj) {
      return axiosRec.post('/v1/attach2/' + obj.preack_id, obj.payload);
    },
    uploadFileToSignedURL(obj) {
      console.log('uploadFiletosignedurl' + JSON.stringify(obj));

      console.log('FILE OBJECT:' + JSON.stringify(obj));

      console.log('payload:' + obj.toString());

      delete axios.defaults.headers.common['Authorization'];
      return axios({
        method: 'put',
        url: obj.signedURL,
        data: obj.payload.get('file'),
        headers: {
          'content-type': obj.content_type
        }
      });
    },
    handleFileUpload(e, personType, propName) {
      const file = e.target.files[0];
      if (typeof file !== 'undefined' && e.target.files.length) {
        this.datosAdjuntos[personType][propName.replace(/_file/, '_name')] = file.name.toLowerCase();
        this.datosAdjuntos[personType][propName] = file;
        this.createBase64Image(file, personType, propName);
      } else {
        this.datosAdjuntos[personType][propName.replace(/_file/, '_name')] = '';
        this.datosAdjuntos[personType][propName] = '';
      }
    },
    createBase64Image(fileObject, personType, propName) {
      const reader = new FileReader();
      reader.onload = e => {
        this.datosAdjuntos[personType][propName.replace(/_file/, '')] = e.target.result.split(',')[1];
      };
      reader.readAsDataURL(fileObject);
    },
    validateForm: async function() {
      let isValidForm = false;
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v[`${this.personTypeGroup}ValidationGroup`].$error) {
        this.saveIsLoading({
          isLoading: true,
          message: this.$t('attachments.form.loading')
        });
        this.areFilesLoaded = false;

        let responseUploadFiles = await Promise.all(this.submitFilesToS3())
          .then(console.log('Uploaded file!'))
          .catch(error => {
            console.log('Error:' + JSON.stringify(error));
          });
        this.saveIsLoading({ isLoading: false });

        if (
          typeof responseUploadFiles !== 'undefined' &&
          !(
            responseUploadFiles.filter(obj => {
              return obj.status !== 200;
            }).length > 0
          )
        ) {
          this.areFilesLoaded = true;
        } else {
          this.mineError.description = this.$t('server.error');
          this.mineError.title = 'Error';
          this.mineError.isPrivacy = false;
          this.showModal();
          return false;
        }
        this.$emit('data', {});
        isValidForm = true;
      } else {
        this.$emit('validatedComponent', false);
        isValidForm = false;
      }
      return isValidForm;
    },
    ...mapActions({
      saveIsLoading: 'saveisLoading'
    })
  },
  computed: {
    acceptFileTypes: function() {
      return allowedFileTypes.join(' ');
    },
    allovalidationswedFileTypesToString: function() {
      return allowedFileTypes
        .map(function(fileType) {
          return fileType.replace('image/', '');
        })
        .map(function(fileType) {
          return fileType.replace('application/', '');
        })
        .join(', ');
    },
    personaFisicaFiles: function() {
      let baseFields = ['identification_id', 'cif_sat', 'address_proof'];
      if (this.hasLegalRepresentant) {
        baseFields.push('legal_representative_identification');

        if (this.datosAdjuntos.personaFisica.legal_representative_power_file) {
          baseFields.push('legal_representative_power');
        }
      }
      if (this.hasEnvironmentalTax) {
        baseFields.push('environmental_tax');
      }

      if (this.hasISHRTax) {
        baseFields.push('ishr_tax');
      }
      return baseFields;
    },
    personaMoralFiles: function() {
      let baseFields = ['cif_sat', 'constitutive_act', 'legal_representative_identification', 'address_proof'];
      if (this.datosAdjuntos.personaMoral.legal_representative_power_file) {
        baseFields.push('legal_representative_power');
      }
      if (this.hasEnvironmentalTax) {
        baseFields.push('environmental_tax');
      }
      if (this.hasISHRTax) {
        baseFields.push('ishr_tax');
      }

      return baseFields;
    }
  },
  data() {
    return {
      mineError: {
        description: '',
        title: ''
      },
      isModalVisible: false,
      progressBar: 0,
      areFilesLoaded: false,
      datosAdjuntos: {
        personaFisica: {
          identification_id: '',
          identification_id_file: '',
          identification_id_name: '',
          cif_sat: '',
          cif_sat_file: '',
          cif_sat_name: '',
          legal_representative_identification: '',
          legal_representative_identification_file: '',
          legal_representative_identification_name: '',
          legal_representative_power_name: '',
          legal_representative_power_file: '',
          legal_representative_power: '',
          address_proof_name: '',
          address_proof_file: '',
          address_proof: '',
          environmental_tax_name: '',
          environmental_tax_file: '',
          environmental_tax: '',
          ishr_tax_name: '',
          ishr_tax_file: '',
          ishr_tax: '',
        },
        personaMoral: {
          cif_sat: '',
          cif_sat_file: '',
          cif_sat_name: '',
          constitutive_act: '',
          constitutive_act_file: '',
          constitutive_act_name: '',
          legal_representative_identification: '',
          legal_representative_identification_file: '',
          legal_representative_identification_name: '',
          legal_representative_power: '',
          legal_representative_power_file: '',
          legal_representative_power_name: '',
          address_proof_name: '',
          address_proof_file: '',
          address_proof: '',
          environmental_tax_name: '',
          environmental_tax_file: '',
          environmental_tax: '',
          ishr_tax_name: '',
          ishr_tax_file: '',
          ishr_tax: '',
        }
      }
    };
  },
  watch: {
    progressBar(newValue) {
      console.log('Progress bar: ' + newValue);
    }
  },
  validations() {
    let validations = {
      personTypeGroup: '',
      datosAdjuntos: {
        personaFisica: {
          identification_id_file: {
            required,
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          },
          cif_sat_file: {
            required,
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          },
          address_proof_file: {
            required,
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          }
        },
        personaMoral: {
          cif_sat_file: {
            required,
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          },
          constitutive_act_file: {
            required,
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          },
          legal_representative_identification_file: {
            required,
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          },
          legal_representative_power_file: {
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          },
          address_proof_file: {
            required,
            isValidFileSize: isValidFileSize({
              maxFileSize: 4100000
            }),
            isValidFileType: isValidFileType({
              fileTypes: allowedFileTypes
            })
          }
        }
      },
      personaFisicaValidationGroup: ['datosAdjuntos.personaFisica'],
      personaMoralValidationGroup: ['datosAdjuntos.personaMoral']
    };
    if (this.hasLegalRepresentant) {
      validations.datosAdjuntos.personaFisica.legal_representative_identification_file = {
        required,
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
        isValidFileType: isValidFileType({
          fileTypes: allowedFileTypes
        })
      };
      validations.datosAdjuntos.personaFisica.legal_representative_power_file = {
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
        isValidFileType: isValidFileType({
          fileTypes: allowedFileTypes
        })
      }
    }
    if (this.hasEnvironmentalTax) {
      validations.datosAdjuntos.personaFisica.environmental_tax_file = {
        required,
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
        isValidFileType: isValidFileType({
          fileTypes: allowedFileTypes
        })
      };
      validations.datosAdjuntos.personaMoral.environmental_tax_file = {
        required,
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
        isValidFileType: isValidFileType({
          fileTypes: allowedFileTypes
        })
      };
      
    }

    if (this.hasISHRTax) {
      validations.datosAdjuntos.personaFisica.ishr_tax_file = {
        required,
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
        isValidFileType: isValidFileType({
          fileTypes: allowedFileTypes
        })
      };
      validations.datosAdjuntos.personaMoral.ishr_tax_file = {
        required,
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
        isValidFileType: isValidFileType({
          fileTypes: allowedFileTypes
        })
      };

    }
    return validations;
  }
};
</script>

<style scoped>
#datos-adjuntos-container {
  padding: 15px;
}
</style>
