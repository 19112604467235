<template>
  <form class="ui form">
    <div class="two fields">
      <div class="field">
        <label>{{ $t('announcement.form.movement_date') }} *</label>
        <div class="ui calendar">
          <datepicker
            :disabled="readOnlyMode"
            :placeholder="$t('announcement.form.movement_date')"
            v-model="movement_date"
            :value="movement_date"
            :language="es"
            :open-date="disabledDates.from"
            :initial-view="'year'"
            :disabled-dates="disabledDates"
            @input="
              $v.movement_date.$touch();
              setNoticeDate($event);
            "
            valueType="format"
            format="dd/MM/yyyy"
            >----
          </datepicker>
          <span v-if="!$v.movement_date.required && $v.movement_date.$anyDirty" class="error-message error-fecha">
            {{ $t('validation.field_required') | potfilter($t('announcement.form.movement_date')) }}
          </span>
        </div>
      </div>
      <div class="field">
        <label>{{ $t('announcement.form.state_account_number') }} *</label>
        <input
          :disabled="readOnlyMode"
          type="text"
          :placeholder="$t('announcement.form.state_account_number')"
          v-model="state_account_number"
          @blur="$v.state_account_number.$touch()"
          @keydown="onReferencesKeyDown($event)"
        />
        <span v-if="!$v.state_account_number.required && $v.state_account_number.$anyDirty" class="error-message">
          {{ $t('validation.field_required') | potfilter($t('announcement.form.state_account_number')) }}
        </span>
        <span v-if="isStateAccountNumberValid" class="error-message">
          {{ $t('validation.field_invalid') | potfilter($t('announcement.form.state_account_number')) }}
        </span>
      </div>
    </div>
  </form>
</template>

<script>
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators';
import { es } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import datepicker from 'vuejs-datepicker';
import { keyCodes } from '@/shared/constants';

export default {
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      movement_date: '',
      transaction_applied_at: '',
      state_account_number: '',
      maxAmountCharsReStateAccountField: 6,
      disabledDates: {
        from: moment()
          .subtract(0, 'years')
          .toDate(),
        to: moment()
          .subtract(120, 'years')
          .toDate()
      },
      es: es
    };
  },
  validations: {
    movement_date: {
      required
    },
    state_account_number: {
      required,
      numeric,
      minLength: minLength(2),
      maxLength: maxLength(10)
    }
  },
  methods: {
    /**
     * Emits the movement date chosen by the user on this component.
     *
     * @param string date chosen on the input event of the datepicker.
     * @return void
     */
    setNoticeDate(event) {
      if (event) this.transaction_applied_at = moment(event).format('YYYY/MM/DD');
    },
    validateForm() {
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit('data', {
          transaction_applied_at: this.transaction_applied_at,
          state_account_number: this.state_account_number
        });
      }
      return !this.$v.$error;
    },
    onReferencesKeyDown(event) {
      if (this.state_account_number.length >= this.maxAmountCharsReStateAccountField) {
        if ((event.keyCode >= keyCodes.zero && event.keyCode <= keyCodes.z) || event.keyCodes === keyCodes.spaceBar) {
          event.preventDefault();
          return;
        }
      }
    }
  },
  computed: {
    isStateAccountNumberValid() {
      return (
        (!this.$v.state_account_number.numeric ||
          !this.$v.state_account_number.minLength ||
          !this.$v.state_account_number.maxLength) &&
        this.$v.state_account_number.$anyDirty
      );
    }
  },
  components: {
    datepicker
  }
};
</script>

<style>
.error-fecha {
  display: block;
}
</style>
