const allowedFileTypes = ['application/pdf'];

const modules = [
  'datos_identificacion',
  'acta_constitutiva',
  'representante_legal',
  'datos_adjuntos',
  'domicilio_fiscal',
  'medios_contacto',
  'representante_legal',
  'solicitud_inscripcion',
  'obligation',
  'rec',
  'vista_previa'
];

const reasons = [
  {
    text: 'Defunción',
    value: 1
  },
  {
    text: 'Escición total de sociedades',
    value: 2
  },
  {
    text: 'Fusión de sociedades',
    value: 3
  },
  {
    text: 'Liquidación de sucesión',
    value: 4
  },
  {
    text: 'Liquidación total de activo',
    value: 5
  },
  {
    text: 'Personas Morales cesasión',
    value: 6
  },
  {
    text: 'Total de operaciones (que no entran en la liquidación)',
    value: 7
  }
];

const contribuyentes = [
  {
    text: 'Fisica',
    value: 1
  },
  {
    text: 'Moral',
    value: 2
  }
];

const entidades = [
  {
    value: 1,
    text: 'Aguascalientes'
  },
  {
    value: 2,
    text: 'Baja California'
  },
  {
    value: 3,
    text: 'Baja California Sur'
  },
  {
    value: 4,
    text: 'Campeche'
  },
  {
    value: 5,
    text: 'Chiapas'
  },
  {
    value: 6,
    text: 'Chihuahua'
  },
  {
    value: 7,
    text: 'Coahuila'
  },
  {
    value: 8,
    text: 'Colima'
  },
  {
    value: 9,
    text: 'Ciudad de México'
  },
  {
    value: 10,
    text: 'Durango'
  },
  {
    value: 11,
    text: 'Guanajuato'
  },
  {
    value: 12,
    text: 'Guerrero'
  },
  {
    value: 13,
    text: 'Hidalgo'
  },
  {
    value: 14,
    text: 'Jalisco'
  },
  {
    value: 15,
    text: 'Estado de México'
  },
  {
    value: 16,
    text: 'Michoacán'
  },
  {
    value: 17,
    text: 'Morelos'
  },
  {
    value: 18,
    text: 'Nayarit'
  },
  {
    value: 19,
    text: 'Nuevo León'
  },
  {
    value: 20,
    text: 'Oaxaca'
  },
  {
    value: 21,
    text: 'Puebla'
  },
  {
    value: 22,
    text: 'Querétaro'
  },
  {
    value: 23,
    text: 'Quintana Roo'
  },
  {
    value: 24,
    text: 'San Luis Potosí'
  },
  {
    value: 25,
    text: 'Sinaloa'
  },
  {
    value: 26,
    text: 'Sonora'
  },
  {
    value: 27,
    text: 'Tabasco'
  },
  {
    value: 28,
    text: 'Tamaulipas'
  },
  {
    value: 29,
    text: 'Tlaxcala'
  },
  {
    value: 30,
    text: 'Veracruz'
  },
  {
    value: 31,
    text: 'Yucatán'
  },
  {
    value: 32,
    text: 'Zacatecas'
  }
];

const nacionalidades = [
  {
    text: 'AFGANA',
    value: 'AFG'
  },
  {
    text: 'ALBANESA',
    value: 'ALB'
  },
  {
    text: 'ALEMANA',
    value: 'DEU'
  },
  {
    text: 'AMERICANA',
    value: 'USA'
  },
  {
    text: 'ANDORRANA',
    value: 'AND'
  },
  {
    text: 'ANGOLESA',
    value: 'AGO'
  },
  {
    text: 'ARABE',
    value: 'SAU'
  },
  {
    text: 'ARGELIANA',
    value: 'DZA'
  },
  {
    text: 'ARGENTINA',
    value: 'ARG'
  },
  {
    text: 'AUSTRALIANA',
    value: 'AUS'
  },
  {
    text: 'AUSTRIACA',
    value: 'AUT'
  },
  {
    text: 'BAHAMEÑA',
    value: 'BHS'
  },
  {
    text: 'BELGA',
    value: 'BEL'
  },
  {
    text: 'BELICEÑA',
    value: 'BLZ'
  },
  {
    text: 'BHUTANESA',
    value: 'BTN'
  },
  {
    text: 'BIRMANA',
    value: 'BUR'
  },
  {
    text: 'BOLIVIANA',
    value: 'BOL'
  },
  {
    text: 'BOTSWANESA',
    value: 'BWA'
  },
  {
    text: 'BRASILEÑA',
    value: 'BRA'
  },
  {
    text: 'BRITANICA',
    value: 'VGB'
  },
  {
    text: 'BRITANICA',
    value: 'IOT'
  },
  {
    text: 'BULGARA',
    value: 'BGR'
  },
  {
    text: 'BURKINA FASO',
    value: 'BFA'
  },
  {
    text: 'BURUNDESA',
    value: 'BDI'
  },
  {
    text: 'CAMBOYANA',
    value: 'KHM'
  },
  {
    text: 'CAMERUNESA',
    value: 'CMR'
  },
  {
    text: 'CANADIENSE',
    value: 'CAN'
  },
  {
    text: 'CENTRO AFRICANA',
    value: 'CAF'
  },
  {
    text: 'CHADIANA',
    value: 'TCD'
  },
  {
    text: 'CHECOSLOVACA',
    value: 'CSK'
  },
  {
    text: 'CHILENA',
    value: 'CHL'
  },
  {
    text: 'CHINA',
    value: 'CHN'
  },
  {
    text: 'CHIPRIOTA',
    value: 'CYP'
  },
  {
    text: 'COLOMBIANA',
    value: 'COL'
  },
  {
    text: 'COMORENSE',
    value: 'COM'
  },
  {
    text: 'CONGOLESA',
    value: 'COD'
  },
  {
    text: 'COSTARRICENSE',
    value: 'CRI'
  },
  {
    text: 'CUBANA',
    value: 'CUB'
  },
  {
    text: 'DANESA',
    value: 'DNK'
  },
  {
    text: 'DE BAHREIN',
    value: 'BHR'
  },
  {
    text: 'DE BARBADOS',
    value: 'BRB'
  },
  {
    text: 'DE BENNIN',
    value: 'BEN'
  },
  {
    text: 'DE CABO VERDE',
    value: 'CPV'
  },
  {
    text: 'DE DJIBOUTI',
    value: 'DJI'
  },
  {
    text: 'DE SANTO TOME',
    value: 'STP'
  },
  {
    text: 'DE SEYCHELLES',
    value: 'SYC'
  },
  {
    text: 'DE ZIMBAWI',
    value: 'ZWE'
  },
  {
    text: 'DEL QATAR',
    value: 'QAT'
  },
  {
    text: 'DEL SAHARA',
    value: 'ESH'
  },
  {
    text: 'DOMINICA',
    value: 'DMA'
  },
  {
    text: 'DOMINICANA',
    value: 'DOM'
  },
  {
    text: 'EGIPCIA',
    value: 'EGY'
  },
  {
    text: 'ESLOVACA',
    value: 'SVK'
  },
  {
    text: 'ESLOVAQUIA',
    value: 'XES'
  },
  {
    text: 'ESLOVENIA',
    value: 'SVN'
  },
  {
    text: 'ESPAÑOLA',
    value: 'ESP'
  },
  {
    text: 'ESTADOUNIDENSE',
    value: 'USA'
  },
  {
    text: 'ESTONIA',
    value: 'EST'
  },
  {
    text: 'ETIOPE',
    value: 'ETH'
  },
  {
    text: 'FIJIANA',
    value: 'FJI'
  },
  {
    text: 'FILIPINA',
    value: 'PHL'
  },
  {
    text: 'FINLANDESA',
    value: 'FIN'
  },
  {
    text: 'FRANCESA',
    value: 'FRA'
  },
  {
    text: 'GABONESA',
    value: 'GAB'
  },
  {
    text: 'GAMBIANA',
    value: 'GMB'
  },
  {
    text: 'GHANATA',
    value: 'GHA'
  },
  {
    text: 'GRANADINA',
    value: 'VCT'
  },
  {
    text: 'GRIEGA',
    value: 'GRC'
  },
  {
    text: 'GUATEMALTECA',
    value: 'GTM'
  },
  {
    text: 'GUINEA',
    value: 'GNB'
  },
  {
    text: 'GUINEA',
    value: 'GIN'
  },
  {
    text: 'GUINEA ECUATORIANA',
    value: 'GNQ'
  },
  {
    text: 'GUYANESA',
    value: 'GUY'
  },
  {
    text: 'HAITIANA',
    value: 'HTI'
  },
  {
    text: 'HINDU',
    value: 'IND'
  },
  {
    text: 'HOLANDESA',
    value: 'NLD'
  },
  {
    text: 'HONDUREÑA',
    value: 'HND'
  },
  {
    text: 'HUNGARA',
    value: 'HUN'
  },
  {
    text: 'INDONESA',
    value: 'IDN'
  },
  {
    text: 'IRANI',
    value: 'IRN'
  },
  {
    text: 'IRAQUI',
    value: 'IRQ'
  },
  {
    text: 'IRLANDESA',
    value: 'IRL'
  },
  {
    text: 'ISLANDESA',
    value: 'ISL'
  },
  {
    text: 'ISRAELI',
    value: 'ISR'
  },
  {
    text: 'ITALIANA',
    value: 'ITA'
  },
  {
    text: 'JAMAIQUINA',
    value: 'JAM'
  },
  {
    text: 'JAPONESA',
    value: 'JPN'
  },
  {
    text: 'JORDANA',
    value: 'JOR'
  },
  {
    text: 'KENIANA',
    value: 'KEN'
  },
  {
    text: 'KIRGUISTAN',
    value: 'KGZ'
  },
  {
    text: 'KUWAITI',
    value: 'KWT'
  },
  {
    text: 'LAOSIANA',
    value: 'LAO'
  },
  {
    text: 'LESOTHENSE',
    value: 'LSO'
  },
  {
    text: 'LIBANESA',
    value: 'LBN'
  },
  {
    text: 'LIBERIANA',
    value: 'LBR'
  },
  {
    text: 'LIBIA',
    value: 'LBY'
  },
  {
    text: 'LIECHTENSTENSE',
    value: 'LIE'
  },
  {
    text: 'LITUANIA',
    value: 'LTU'
  },
  {
    text: 'LUXEMBURGUESA',
    value: 'LUX'
  },
  {
    text: 'MACEDONIA',
    value: 'MKD'
  },
  {
    text: 'MALASIA',
    value: 'MYS'
  },
  {
    text: 'MALAWIANA',
    value: 'MWI'
  },
  {
    text: 'MALDIVA',
    value: 'MDV'
  },
  {
    text: 'MALIENSE',
    value: 'MLI'
  },
  {
    text: 'MALTESA',
    value: 'MLT'
  },
  {
    text: 'MARFILEÑA',
    value: 'COG'
  },
  {
    text: 'MARROQUI',
    value: 'MAR'
  },
  {
    text: 'MAURICIANA',
    value: 'MUS'
  },
  {
    text: 'MAURITANA',
    value: 'MRT'
  },
  {
    text: 'MEXICANA',
    value: 'MEX'
  },
  {
    text: 'MICRONECIA',
    value: 'FSM'
  },
  {
    text: 'MOLDOVIA, REPUBLICA DE',
    value: 'MDA'
  },
  {
    text: 'MONEGASCA',
    value: 'MCO'
  },
  {
    text: 'MONGOLESA',
    value: 'MNG'
  },
  {
    text: 'MOZAMBIQUEÑA',
    value: 'MOZ'
  },
  {
    text: 'NAMIBIANA',
    value: 'NAM'
  },
  {
    text: 'NAURUANA',
    value: 'NRU'
  },
  {
    text: 'NEPALESA',
    value: 'NPL'
  },
  {
    text: 'NICARAGUENSE',
    value: 'NIC'
  },
  {
    text: 'NIGERIANA',
    value: 'NGA'
  },
  {
    text: 'NIGERINA',
    value: 'NER'
  },
  {
    text: 'NORCOREANA',
    value: 'PRK'
  },
  {
    text: 'NORUEGA',
    value: 'NOR'
  },
  {
    text: 'NORVIETNAMITA',
    value: 'VNM'
  },
  {
    text: 'OMANESA',
    value: 'OMN'
  },
  {
    text: 'PAKISTANI',
    value: 'PAK'
  },
  {
    text: 'PANAMEÑA',
    value: 'PAN'
  },
  {
    text: 'PAPUENSE',
    value: 'PNG'
  },
  {
    text: 'PARAGUAYA',
    value: 'PRY'
  },
  {
    text: 'PERUANA',
    value: 'PER'
  },
  {
    text: 'PORTUGUESA',
    value: 'PRT'
  },
  {
    text: 'PUERTORRIQUEÑA',
    value: 'PRI'
  },
  {
    text: 'REINO UNIDO',
    value: 'GBR'
  },
  {
    text: 'REINO UNIDO(BRIT. DEL EXT.)',
    value: 'GBN'
  },
  {
    text: 'REINO UNIDO(C. BRIT. DEL EXT.)',
    value: 'GBO'
  },
  {
    text: 'REINO UNIDO(DEPEN. TET. BRIT.)',
    value: 'GBD'
  },
  {
    text: 'RUMANA',
    value: 'ROM'
  },
  {
    text: 'RWANDESA',
    value: 'RWA'
  },
  {
    text: 'SALOMONESA',
    value: 'SLB'
  },
  {
    text: 'SALVADOREÑA',
    value: 'SLV'
  },
  {
    text: 'SAMOANA',
    value: 'WSM'
  },
  {
    text: 'SAN MARINENSE',
    value: 'SMR'
  },
  {
    text: 'SANTA LUCIENSE',
    value: 'LCA'
  },
  {
    text: 'SENEGALESA',
    value: 'SEN'
  },
  {
    text: 'SIERRA LEONESA',
    value: 'SLE'
  },
  {
    text: 'SINGAPORENSE',
    value: 'SGP'
  },
  {
    text: 'SIRIA',
    value: 'SYR'
  },
  {
    text: 'SOMALI',
    value: 'SOM'
  },
  {
    text: 'SOVIETICA BIELORRUSA',
    value: 'BLR'
  },
  {
    text: 'SOVIETICA UCRANIANA',
    value: 'UKR'
  },
  {
    text: 'SUDAFRICANA',
    value: 'ZAF'
  },
  {
    text: 'SUDANESA',
    value: 'SDN'
  },
  {
    text: 'SUDCOREANA',
    value: 'KOR'
  },
  {
    text: 'SUECA',
    value: 'SWE'
  },
  {
    text: 'SUIZA',
    value: 'CHE'
  },
  {
    text: 'SURINAMENSE',
    value: 'SUR'
  },
  {
    text: 'SWAZI',
    value: 'SWZ'
  },
  {
    text: 'TAILANDESA',
    value: 'THA'
  },
  {
    text: 'TAIWANESA',
    value: 'TWN'
  },
  {
    text: 'TANZANIANA',
    value: 'TZA'
  },
  {
    text: 'TOGOLESA',
    value: 'TGO'
  },
  {
    text: 'TRINITARIA',
    value: 'TTO'
  },
  {
    text: 'TUNECINA',
    value: 'TUN'
  },
  {
    text: 'TURCA',
    value: 'TUR'
  },
  {
    text: 'UGANDESA',
    value: 'UGA'
  },
  {
    text: 'URUGUAYA',
    value: 'URY'
  },
  {
    text: 'VATICANA',
    value: 'VAT'
  },
  {
    text: 'VENEZOLANA',
    value: 'VEN'
  },
  {
    text: 'YEMENI',
    value: 'YEM'
  },
  {
    text: 'YUGOSLAVA',
    value: 'YUG'
  },
  {
    text: 'ZAIRANA',
    value: 'ZAR'
  },
  {
    text: 'ZAMBIANA',
    value: 'ZMB'
  }
];

const sexos = [
  {
    text: 'Masculino',
    value: 'Masculino'
  },
  {
    text: 'Femenino',
    value: 'Femenino'
  }
];

const tramites = {
  registro: {
    ruta: 'registro',
    flowBuilderAlias: 'rec',
    name: 'Crear registro estatal de contribuyentes.',
    initialModule: 'datos_identificacion',
    description:
      'Permite la inscripcion en el REC y obtener tu numero estatal de contribuyentes que te identifica dentro del Registro Estatal de Contribuyentes',
    id: 1
  },
  avisoDisminucionObligaciones: {
    ruta: 'aviso-disminucion-obligaciones',
    flowBuilderAlias: 'adof',
    name: 'Aviso disminución de obligaciones fiscales',
    id: 3
  },
  avisoAumentoObligaciones: {
    ruta: 'aviso-aumento-obligaciones',
    name: 'Aviso aumento de obligaciones fiscales',
    flowBuilderAlias: 'aaof',
    initialModule: 'tbd',
    description: 'Te permite informar cambios en tus actividades economicas o modificar tus obligaciones fiscales',
    id: 2
  },
  avisoCambioDomicilio: {
    ruta: 'aviso-cambio-domicilio',
    flowBuilderAlias: 'acd',
    // TODO: Remplazar este valor con el modulo inicial cuadno se trabaje en este modulo
    initialModule: 'tbd',
    description: 'Presenta este aviso para actualizar los datos de tu domicilio fiscal en el REC',
    id: 4
  },
  avisoAltaSucursal: {
    ruta: 'aviso-apertura-establecimiento',
    name: 'Aviso de apertura de establecimientos o locales',
    flowBuilderAlias: 'aael',
    initialModule: 'tbd',
    description:
      'Informar de la apertura de establecimientos y en general cualquier lugar que se utilice para el desempeño de las actividades economicas',
    id: 4
  },
  avisoCierreSucursal: {
    ruta: 'aviso-cierre-establecimiento',
    name: 'Cierre de establecimientos o locales',
    flowBuilderAlias: 'acel',
    // TODO: Remplazar este valor con el modulo inicial cuadno se trabaje en este modulo
    initialModule: 'tbd',
    description:
      'Presenta este aviso para informar del cierre de establecimientos y en general de cualquier lugar que se utilice para el desempeño de las actividades economicas',
    id: 5
  },
  avisoCambioDenominacion: {
    ruta: 'aviso-cambio-denominacion',
    name: 'Aviso cambio de denominación o razón social',
    flowBuilderAlias: 'acdrs'
  },
  avisoCambioRepresentanteLegal: {
    ruta: 'aviso-cambio-representante-legal',
    name: 'Aviso cambio de representante legal',
    flowBuilderAlias: 'acrl',
    initialModule: 'tbd'
  },
  avisoSuspensionActividades: {
    ruta: 'aviso-suspension-actividades',
    name: 'Aviso suspención de actividades',
    flowBuilderAlias: 'asa',
    initialModule: 'tbd'
  },
  avisoReanudacionActividades: {
    ruta: 'aviso-reanudacion-actividades',
    name: 'Aviso reanudación de actividades',
    flowBuilderAlias: 'aract',
    initialModule: 'tbd'
  },
  avisoInicioLiquidacion: {
    ruta: 'aviso-inicio-liquidacion',
    name: 'Aviso inicio de liquidación',
    flowBuilderAlias: 'ail',
    initialModule: 'tbd'
  },
  avisoAperturaSucesion: {
    ruta: 'aviso-apertura-sucesion',
    name: 'Aviso apertura de sucesión',
    flowBuilderAlias: 'aasu',
    initialModule: 'tbd'
  },
  avisoBajaRec: {
    ruta: 'aviso-baja-rec',
    name: 'Aviso baja del registro estatal de contribuyentes',
    flowBuilderAlias: 'abrec',
    initialModule: 'tbd'
  }
};

const keyCodes = {
  spaceBar: 32,
  zero: 48,
  z: 90
};

const contactMeansTypes = {
  LANDLINE_PHONE: 'phone_number',
  MOBILE_PHONE: 'mobile_phone',
  EMAIL: 'email'
};

export {
  modules,
  nacionalidades,
  sexos,
  tramites,
  entidades,
  contribuyentes,
  allowedFileTypes,
  keyCodes,
  reasons,
  contactMeansTypes
};
