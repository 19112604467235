<template>
  <div class="ui container">
    <form class="ui form">
      <h4>{{ $t('meansofcontact.form.description') }}</h4>
      <h5>{{ $t('general.form.description_required_fields') }}</h5>
      <div class="two fields">
        <div class="field" :class="{ error: $v.primaryMeanPhone.value.$error }">
          <label>{{ $t('meansofcontact.form.phone_number') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="phone"
            :placeholder="$t('meansofcontact.form.phone_number')"
            v-model="primaryMeanPhone.value"
            v-mask="'(##) #### ####'"
            @blur="$v.primaryMeanPhone.value.$touch()"
          />
          <span v-if="!$v.primaryMeanPhone.value.required && $v.primaryMeanPhone.value.$anyDirty" class="error-message">
            {{ $t('validation.field_required') | potfilter($t('meansofcontact.form.phone_number')) }}
          </span>
          <span
            v-if="
              (!$v.primaryMeanPhone.value.minLength || !$v.primaryMeanPhone.value.maxLength) &&
                $v.primaryMeanPhone.value.$anyDirty
            "
            class="error-message"
          >
            {{ $t('validation.field_invalid') | potfilter($t('meansofcontact.form.phone_number')) }}
          </span>
        </div>

        <div class="field" :class="{ error: $v.primaryMeanEmail.value.$error }">
          <label>{{ $t('meansofcontact.form.email') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="email"
            :placeholder="$t('meansofcontact.form.email')"
            v-model="primaryMeanEmail.value"
            @blur="$v.primaryMeanEmail.value.$touch()"
          />
          <span v-if="!$v.primaryMeanEmail.value.required && $v.primaryMeanEmail.value.$anyDirty" class="error-message">
            {{ $t('validation.field_required') | potfilter($t('meansofcontact.form.email')) }}
          </span>
          <span v-if="!$v.primaryMeanEmail.value.email" class="error-message">
            {{ $t('validation.field_invalid') | potfilter($t('meansofcontact.form.email')) }}
          </span>
        </div>
      </div>

      <h5 class="ui header">{{ $t('meansofcontact.form.subtitle') }}</h5>
      <div class="equal width fields">
        <div class="field">
          <label>{{ $t('meansofcontact.table.form.type') }}</label>
          <sui-dropdown
            :disabled="readOnlyMode"
            :placeholder="$t('meansofcontact.table.form.type')"
            selection
            :options="options"
            v-model="addtionalContact.contact_type"
            @select="resetAddtionalMeanField"
            @click="resetAddtionalMeanField"
            class="ui fluid input"
          />
        </div>
        <div class="field" :class="{ error: $v.addtionalContact.value.$error }">
          <label for="addMean">{{ $t(`${addtionalContactLabel}`) }}</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            id="addMean"
            v-bind:placeholder="$t(`${addtionalContactLabel}`)"
            v-model.trim="$v.addtionalContact.value.$model"
            @blur="$v.addtionalContact.value.$touch()"
            class="ui fluid input"
            v-mask="getAdditionalContactMask(addtionalContact)"
          />
          <div v-if="$v.addtionalContact.value.$error">
            <div v-if="addtionalContact.contact_type !== types.EMAIL" class="error-message">
              <span v-if="!$v.addtionalContact.value.required">{{
                $t('validation.field_required') | potfilter($t(`${addtionalContactLabel}`))
              }}</span>
              <span v-else-if="!$v.addtionalContact.value.integer">{{
                $t('validation.is_not_a_number') | potfilter($t(`${addtionalContactLabel}`))
              }}</span>
              <span v-else-if="!$v.addtionalContact.value.minLength || $v.addtionalContact.value.maxLength">{{
                $t('validation.must_be_n_chars') | lengthfilter($t('10'))
              }}</span>
            </div>
            <div v-if="addtionalContact.contact_type === types.EMAIL" class="error-message">
              <span v-if="!$v.addtionalContact.value.required">{{
                $t('validation.field_required') | potfilter($t(`${addtionalContactLabel}`))
              }}</span>
              <span v-else-if="!$v.addtionalContact.value.email">
                {{ $t('validation.field_invalid') | potfilter($t(`${addtionalContactLabel}`)) }}</span
              >
            </div>
          </div>
        </div>
        <div class="field">
          <button class="ui fluid button" id="addButton" :disabled="readOnlyMode" @click.prevent="saveAddtionalMean()">
            Agregar
          </button>
        </div>
      </div>
    </form>
    <div v-if="isPhoneAlreadyPrimary" class="error-message">
      <span>Ese Telefono ya existe como primario</span>
    </div>
    <div v-if="isEmailAlreadyPrimary" class="error-message">
      <span>Ese correo ya existe como primario</span>
    </div>
    <div v-else>
      {{ '' }}
    </div>
    <transition name="slide" mode="out-in">
      <template v-if="areThereAdditionalContacts()">
        <DataTable :data="items" :columns="headers" :noWrap="true">
          <template slot-scope="{ row }">
            <td style="overflow:visible;">
              <form class="ui form">
                <div class="field" v-if="row.editMode">
                  <sui-dropdown
                    :disabled="readOnlyMode"
                    :placeholder="$t('meansofcontact.table.form.type')"
                    selection
                    :options="options"
                    v-model="row.contact_type"
                    @select="resetValueMeanOfContact(row)"
                    @click="resetValueMeanOfContact(row)"
                    class="ui fluid input"
                  />
                </div>
                <div v-else @dblclick="toggleEditMode(row)">
                  {{ getStringTypeAdditionalContact(row) }}
                </div>
              </form>
            </td>
            <td>
              <form class="ui form">
                <div
                  class="field"
                  :class="{ error: !row.isValidData || !row.isRequired || !row.isMinLength }"
                  v-if="row.editMode"
                >
                  <input
                    :disabled="readOnlyMode"
                    type="text"
                    @keyup="validateAdditionalContactValue(row)"
                    v-mask="getAdditionalContactMask(row)"
                    v-model="row.value"
                  />
                  <div v-if="row.contact_type !== types.EMAIL" class="error-message">
                    <span v-if="!row.isRequired">
                      {{ $t('validation.field_required') | potfilter($t('meansofcontact.form.phone_number')) }}
                    </span>
                    <span v-else-if="!row.isValidData">
                      {{ $t('validation.field_invalid') | potfilter($t('meansofcontact.form.phone_number')) }}</span
                    >
                    <span v-else-if="!row.isMinLength">
                      {{ $t('validation.must_be_n_chars') | lengthfilter($t('10')) }}
                    </span>
                  </div>
                  <div v-if="row.contact_type === types.EMAIL" class="error-message">
                    <span v-if="!row.isRequired">
                      {{ $t('validation.field_required') | potfilter($t('meansofcontact.form.email')) }}
                    </span>
                    <span v-else-if="!row.isValidData">
                      {{ $t('validation.field_invalid') | potfilter($t('meansofcontact.form.email')) }}</span
                    >
                  </div>
                </div>
                <div v-else @dblclick="toggleEditMode($event, row)">
                  <div v-if="row.contact_type === 'phone_number' || row.contact_type === 'mobile_phone'">
                    {{ row.value | VMask('(##) #### ####') }}
                  </div>
                  <div v-else>
                    {{ row.value }}
                  </div>
                </div>
              </form>
            </td>
            <td>
              <button
                class="ui vertical animated button"
                :disabled="readOnlyMode"
                v-show="!row.editMode"
                @click.prevent="toggleEditMode($event, row)"
              >
                <div class="hidden content">{{ $t('meansofcontact.table.form.action.edit') }}</div>
                <div class="visible content">
                  <i class="edit icon"></i>
                </div>
              </button>
              <button
                class="ui vertical animated button"
                :disabled="readOnlyMode"
                v-show="row.editMode"
                @click.prevent="toggleEditMode($event, row)"
              >
                <div class="hidden content">{{ $t(`meansofcontact.table.form.action.save`) }}</div>
                <div class="visible content">
                  <i class="check icon"></i>
                </div>
              </button>
              <button
                class="ui vertical animated button"
                :disabled="readOnlyMode"
                @click.prevent="deleteAddtionalMean(row)"
              >
                <div class="hidden content">{{ $t(`meansofcontact.table.form.action.delete`) }}</div>
                <div class="visible content">
                  <i class="trash icon"></i>
                </div>
              </button>
            </td>
          </template>
        </DataTable>
      </template>
    </transition>
  </div>
</template>

<script>
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
import DataTable from '../shared/DataTable.vue';
import { isValidMinLenght, isRequired } from '@/utilities/dateValidators';
import { isValidEmail, isNumeric} from '@/utilities/rfcValidators';

import { contactMeansTypes } from '@/shared/constants';

const TELEPHONE_VALIDATION = {
  value: {
    required,
    minLength: minLength(14),
    maxLength: maxLength(14)
  }
};

const EMAIL_VALIDATION = {
  value: {
    required,
    email
  }
};

const DEFAULT_VALIDATION = {
  value: {}
};

export default {
  name: 'MediosContacto',
  components: {
    DataTable
  },
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data: () => ({
    primaryMeanEmail: {
      contact_type: 'email',
      value: '',
      order_type: 'primary'
    },
    primaryMeanPhone: {
      contact_type: 'phone_number',
      value: '',
      order_type: 'primary'
    },
    options: [
      { text: 'Fijo', value: 'phone_number', id: 1 },
      { text: 'Movil', value: 'mobile_phone', id: 2 },
      { text: 'Correo', value: 'email', id: 3 }
    ],
    items: [],
    headers: ['Tipo', 'Valor', 'Acciones'],
    addtionalContact: {
      contact_type: 'email',
      value: '',
      editMode: false,
      isValidData: true,
      isRequired: true,
      isMinLength: true,
      order_type: 'additional'
    },
    defaultAddtionalContact: {
      contact_type: 'email',
      value: '',
      editMode: false,
      isValidData: true,
      isRequired: true,
      isMinLength: true,
      order_type: 'additional'
    },
    meansTypes: {
      phone_number: TELEPHONE_VALIDATION,
      mobile_phone: TELEPHONE_VALIDATION,
      email: EMAIL_VALIDATION,
      default: DEFAULT_VALIDATION
    },
    meanslabelTypes: {
      phone_number: 'Telefono Fijo',
      mobile_phone: 'Telefono Movil',
      email: 'Correo Electronico',
      default: ''
    },
    additionalContactMask: {
      email: '',
      phone_number: '(##) #### ####',
      mobile_phone: '(##) #### ####',
      default: ''
    },
    isEmailAlreadyPrimary: false,
    isPhoneAlreadyPrimary: false,
    types: contactMeansTypes
  }),
  methods: {
    /**
     * Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Handles the string type to be displayed on the additional contacts
     *
     * @pram row the newly added addtional contact
     * @returns string
     */
    getStringTypeAdditionalContact(row) {
      return this.meanslabelTypes[row.contact_type] || this.meanslabelTypes.default;
    },
    /**
     * Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Handles the mask to use on the field for additional contacts
     *
     * @returns undefined
     */
    getAdditionalContactMask(contact) {
      return this.additionalContactMask[contact.contact_type] || contact.default;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Handles the dynamic validation object for vuelidate
     *
     * @pram validationType type of the newly added additional contact
     * @returns Object
     */
    getValidationType(validationType) {
      return this.meansTypes[validationType] || this.meansTypes.default;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Resets the value additional contacts value when its
     * type has changed while editing
     *
     * @pram validationType type of the newly added additional contact
     * @returns undefined
     */
    resetValueMeanOfContact(row) {
      this.isEmailAlreadyPrimary = false;
      this.isPhoneAlreadyPrimary = false;
      row.value = '';
      (row.isValidData = true), (row.isRequired = true), (row.isMinLength = true);
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Calls the corresponding validation depending
     * on the type of the current edited additional contact
     *
     * @param row additional contact
     * @returns undefined
     */
    validateAdditionalContactValue(row) {
      if (this.isPhoneRow(row)) {
        this.validatePhoneType(row);
      } else {
        this.validateEmailType(row);
      }
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Checks the type of the Additional contact
     * while editing is active
     *
     * @param row additional contact
     * @returns Boolean
     */
    isPhoneRow(row) {
      const { LANDLINE_PHONE, MOBILE_PHONE } = this.types;
      return row.contact_type === LANDLINE_PHONE || row.contact_type === MOBILE_PHONE;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Validates the entered value of the additional contact
     * is numeric
     *
     * @pram row additional contact
     * @returns undefined
     */
    validatePhoneType(row) {
      row.isRequired = isRequired(row.value);
      row.isMinLength = isValidMinLenght(14, row.value);
      row.isValidData = isNumeric(row.value);
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Validates the entered value of the additional contact
     * is an email
     *
     * @pram row additional contact
     * @returns undefined
     */
    validateEmailType(row) {
      row.isRequired = isRequired(row.value);
      row.isValidData = isValidEmail(row.value);
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Checks if the user has added at least an additional contact
     *
     * @pram row additional contact
     * @returns undefined
     */
    areThereAdditionalContacts() {
      return this.items.length > 0;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-10
     *
     * Handles that edit mode on each row if not
     *
     * @param e<{}> the event object from the input event.
     * @pram row additional contact
     */
    toggleEditMode(e, row) {
      if (this.$props.readOnlyMode) {
        return;
      }
      this.validateAdditionalContactValue(row);
      if (
        !row.isValidData ||
        !row.isRequired ||
        !row.isMinLength ||
        this.existsAsPrimaryEmailMean(row) ||
        this.existsAsPrimaryPhoneMean(row)
      ) {
        return;
      } else {
        row.editMode = !row.editMode;
      }
    },
    validateForm() {
      let isValidForm = false;
      this.$v.$reset();
      this.$v.$touch();
      this.addtionalContact.contact_type = '';
      this.addtionalContact.value = '';
      if (!this.$v.$error) {
        this.items.push(this.primaryMeanEmail);
        this.items.push(this.primaryMeanPhone);
        this.$emit('data', {
          data: this.items
        });
        isValidForm = true;
      } else {
        this.$emit('validatedComponent', false);
        isValidForm = false;
      }
      return isValidForm;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-07-22
     *
     * Checks wether an additional phone contact already exists
     * as a primary contact
     *
     * @return {Boolean}
     */
    existsAsPrimaryPhoneMean(contact) {
      return (this.isPhoneAlreadyPrimary = this.primaryMeanPhone.value === contact.value);
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-07-22
     *
     * Checks wether an additional email contact already exists
     * as a primary contact
     *
     * @return {Boolean}
     */
    existsAsPrimaryEmailMean(contact) {
      return (this.isEmailAlreadyPrimary = this.primaryMeanEmail.value === contact.value);
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-07-22
     *
     * Adds an item from the Contact Means Table.
     *
     * @return {undefined}
     */
    saveAddtionalMean() {
      this.$v.addtionalContact.$touch();
      if (
        this.$v.addtionalContact.$error ||
        this.existsAsPrimaryEmailMean(this.addtionalContact) ||
        this.existsAsPrimaryPhoneMean(this.addtionalContact)
      ) {
        return;
      } else {
        this.items.push(this.addtionalContact);
        this.$nextTick(() => {
          this.addtionalContact = Object.assign({}, this.defaultAddtionalContact);
          this.$v.$reset();
        });
      }
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-07-22
     *
     * Removes an item from the Contact Means Table.
     *
     * @param {Object} item - Selected from the table.
     * @return {undefined}
     */
    deleteAddtionalMean(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-07-22
     *
     * Resets the value of the form when Contact Mean Type Dropdown has changed
     *
     * @return {undefined}
     */
    resetAddtionalMeanField() {
      this.isEmailAlreadyPrimary = false;
      this.isPhoneAlreadyPrimary = false;
      this.addtionalContact.value = '';
      this.$v.addtionalContact.value.$reset();
    }
  },
  computed: {
    addtionalContactLabel() {
      const { LANDLINE_PHONE, MOBILE_PHONE } = this.types;
      return this.addtionalContact.contact_type === MOBILE_PHONE ||
        this.addtionalContact.contact_type === LANDLINE_PHONE
        ? 'meansofcontact.form.phone_number'
        : 'meansofcontact.form.email';
    },
    addtionalContactRules() {
      return this.getValidationType(this.addtionalContact.contact_type);
    }
  },
  validations() {
    return {
      addtionalContact: this.addtionalContactRules,
      primaryMeanEmail: {
        value: {
          required,
          email
        }
      },
      primaryMeanPhone: {
        value: {
          required,
          minLength: minLength(14),
          maxLength: maxLength(14)
        }
      }
    };
  }
};
</script>
<style scoped>
#addButton {
  margin-top: 21px;
}
</style>
