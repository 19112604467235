export default {
  Set_Data(state, data) {
    state.data = data;
  },
  Set_SavedStatus(state, isSaved) {
    state.isSaved = isSaved;
  },
  Set_Validated(state, validated) {
    state.validated = validated;
  }
};
