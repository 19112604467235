import { helpers } from 'vuelidate/lib/validators';
import Vue from 'vue';

export const isValidDateFormat = (options = {}) => {
  return helpers.withParams(options, value => {
    if (!value) return true;
    return Vue.moment(value, options.format, true).isValid();
  });
};

export const calcAge = (age, format) => {
  const today = Vue.moment();
  return today.diff(Vue.moment(age, format, true), 'years');
};

export const isValidMinAge = (options = {}) => {
  return helpers.withParams(options, value => {
    if (!value) return true;
    return calcAge(value, options.format, true) >= options.minAge;
  });
};

export const isRequired = value => {
  return !!String(value).length;
};

export const isValidMinLenght = (minLength, value) => {
  return value.length === minLength;
};
