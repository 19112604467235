<template lang="html">
  <div class="notaries">
    <response-status></response-status>
    <notes-notarios></notes-notarios>
    <header-notarios></header-notarios>
    <div class="notaries__title">
      <h1 class="ui header center aligned">Formulario de Notarios</h1>
    </div>
    <div class="ui container">
      <h3 class="ui dividing header">Formulario de Notarios</h3>
      <form-notarios></form-notarios>
    </div>
  </div>
</template>

<script>
import ResponseStatus from "@/components/shared/ResponseStatus.vue";
import HeaderNotarios from "@/components/Notarios/Header.vue";
import NotesNotarios from "@/components/Notarios/Notes.vue";
import FormNotarios from "@/components/Notarios/Form.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notaries",
  components: {
    ResponseStatus,
    HeaderNotarios,
    NotesNotarios,
    FormNotarios
  }
};
</script>

<style lang="scss">
.notaries {
  min-height: calc(100vh - 27.2rem);
  height: 100%;
  background-color: #f1f2f7;
  padding-bottom: 2rem;
  &__notes {
    img {
      display: inline;
      width: 3rem;
      height: auto;
      margin-right: 0.5rem;
    }
    background: #dfd7d4;
    color: #7f7f82;
    text-align: center;
    padding: 0.2rem;
  }
  &__header {
    background: white;
    padding: 1rem 1.5rem;
    img {
      padding: 1rem 0;
    }
  }
  &__title {
    height: 14rem;
    background: #ffff url(../assets/img/front_principal.png) 50% 30% / cover
    no-repeat;
    h1 {
      font-weight: 900 !important;
      line-height: 14rem !important;
      color: white !important;
    }
  }
  .container {
    padding: 1.5rem 0;
    border-radius: 5px;
    background: white;
    margin: 2rem 0;
    box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.1);
    .header {
      padding: 0.5rem 2rem;
    }
    h3 {
      margin-bottom: 2rem !important;
    }
  }
  &__form {
    h5 {
      padding-left: 10rem !important;
      margin-bottom: 2rem !important;
    }
    .field {
      padding: 0 10rem;
    }
    .fields {
      padding: 0 10rem;
    }
    .submit {
      text-align: center;
      margin-top: 1.5rem;
      padding: 0 10rem;
      button {
        width: 10rem;
        border-radius: 8px;
      }
    }
    input {
      border-color: #e8eae9 !important;
      background: linear-gradient(
              180deg,
              rgba(247, 247, 247, 1) 0%,
              rgba(252, 252, 252, 1) 15%,
              rgba(255, 255, 255, 1) 26%
      ) !important;
    }
    .selection {
      border-color: #e8eae9 !important;
      background: linear-gradient(
              180deg,
              rgba(247, 247, 247, 1) 0%,
              rgba(252, 252, 252, 1) 15%,
              rgba(255, 255, 255, 1) 26%
      ) !important;
    }
  }
}
</style>
