import axios from 'axios'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const axiosInstance = axios.create({
    headers: {

    }
});

const BASE_URL = process.env.VUE_APP_SERVICE_BASE_URL;

const state = {
    curp: null,
    data: null,
    errorServer: false,
    isSaved: false,
    validated: false
}

const mutations = {
    Set_DatosCurp (state, curp) {
        state.curp = curp;
    },
    Set_Data (state, data) {
        state.data = data;
    },
    Set_SavedStatus(state, isSaved) {
        state.isSaved = isSaved;
    },
    Set_Validated (state, validated) {
        state.validated = validated
    }

}


const getters = {

    LoadCurp() {
        return state.curp
    },
    getIsSaved(state) {
        return state.isSaved;
    },
    loadValidated() {
        return state.validated
    },
    getData() {
        return state.data;
    }

}

const actions = {
    /**
     * jaimexcastillo
     * @date 2020-05-29
     * @param {any} {commit}
     * @param {string} curp recives a Curp 
     * @returns {json} with response of the server being a json with the data of the person from the curp
     */
    loadCurpData: ({ commit }, curp) => {
        if (!curp) { return; }
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${BASE_URL}renapo/${curp}`)
                .then(res => {
                    commit('Set_DatosCurp', res.data.data);
                    resolve();
                })
                .catch(error => reject(error))
        });
    },
    /**
     * jaimexcastillo
     * @date 2020-05-29
     * @param {any} {commit
     * @param {any} state} recives the vue data from a component
     * @returns {any} response with the result of the post service
     * 
     */
    saveData({ state }) {
        if (!state.data) {
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}v1/legal_representative`, state.data)
            .then(() => {
                // commit('Set_Data', res.data);
                resolve();
            })
            .catch((error) => reject(error))
        });
    },
    setData: ({ commit }, data) => {
        commit('Set_Data', data)
    },
    setIsSaved({ commit }, isSaved) {
        commit('Set_SavedStatus', isSaved);
    },
    validator({ commit }, validated) {
        commit('Set_Validated', validated)
    }
};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
