<template lang="html">
  <div class="notaries__notes">
    <img
        src="@/assets/img/palacio.svg"
    />
    <span>Un sitio oficial de Gobierno del Estado de Nuevo León</span>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notes"
}
</script>
