<template>
  <div class="ui grid container">
    <br />
    <h2 class="ui header">Representante Legal:</h2>
    <div class="two column row">
      <div class="column">
        <div class="ui list">
          <div class="item">
            <div class="ui header">{{ POElabels[`birthdate`] }}</div>
            {{ formattedBirthdate }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`email`] }}</div>
            {{ legalRepresentativeValues.email }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`gender`] }}</div>
            {{ validateGender(legalRepresentativeValues.gender) }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`lastname`] }}</div>
            {{ legalRepresentativeValues.lastname }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`rfc`] }}</div>
            {{ legalRepresentativeValues.rfc }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`phone`] }}</div>
            {{ legalRepresentativeValues.phone }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui list">
          <div class="item">
            <div class="ui header">{{ POElabels[`curp`] }}</div>
            {{ legalRepresentativeValues.curp }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`entity`] }}</div>
            {{ legalRepresentativeValues.entity }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`name`] }}</div>
            {{ legalRepresentativeValues.name }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`second_lastname`] }}</div>
            {{ legalRepresentativeValues.second_lastname }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`nationality`] }}</div>
            {{ legalRepresentativeValues.nationality }}
          </div>
        </div>
      </div>
    </div>
    <sui-divider />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'LegalRepresentative',
  props: ['module', 'labels'],
  data() {
    return {
      legalRepresentativeValues: this.module,
      POElabels: this.labels,
    };
  },
  computed: {
    formattedBirthdate() {
      return moment(this.legalRepresentativeValues.birthdate).utc().format('DD/MM/YYYY');
    },
  },
  watch: {
    module(newVal) {
      this.legalRepresentativeValues = newVal;
    },
  },
  methods: {
    validateGender(content) {
      if (content.toLowerCase() === 'female') return 'Femenino';
      return content;
    },
  },
};
</script>
