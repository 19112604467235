import axios from 'axios'
const BASE_URL = process.env.VUE_APP_SERVICE_BASE_URL;
export default {
    saveData({ state }) {
        if (!state.data) {
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}v1/constitutive_act2`, state.data)
            .then(() => {
                resolve();
            })
            .catch((error) => reject(error))
        });
    },
    setData: ({ commit }, data) => {
        commit('setData', data)
    },
    setIsSaved({ commit }, isSaved) {
        commit('setSavedStatus', isSaved);
    },
    validator({ commit }, validated) {
        commit('setValidated', validated)
    }
}
