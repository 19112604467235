<template>
  <div class="ui grid container">
    <br />
    <h2 class="ui header">Datos de Medios de Contacto:</h2>
    <div class="two column row">
      <div class="column">
        <h4 class="ui subheader">Contactos Primarios</h4>
        <div class="ui list" v-if="primaryContacts">
          <div class="item" v-for="contact in primaryContacts" :key="contact.id">
            <div class="ui header">{{ `${$t(contact.contact_type)}` }}</div>
            {{ contact.value }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui list" v-if="additionalContacts.length !== 0">
          <h4 class="ui subheader">Contactos Adicionales</h4>
          <div class="item" v-for="contact in additionalContacts" :key="contact.id">
            <div class="ui header">{{ `${$t(contact.contact_type)}` }}</div>
            {{ contact.value }}
          </div>
        </div>
        <div v-else class="no-data">
          {{ $t('meansofcontact.table.no_data') }}
        </div>
      </div>
    </div>
    <sui-divider />
  </div>
</template>

<script>
export default {
  name: 'MeanContact',
  props: ['module'],
  data() {
    return {
      meanContactValues: this.module,
      meanslabelTypes: {
        phone_number: 'meansofcontact.form.phone_number',
        mobile_phone: 'meansofcontact.form.phone_number',
        email: 'meansofcontact.form.email',
        default: ''
      }
    };
  },
  methods: {
    getStringLabel(contact) {
      return this.meanslabelTypes[contact.contact_type] || this.meanslabelTypes.default;
    }
  },
  computed: {
    additionalContacts() {
      return this.meanContactValues.data
          .filter(contact => contact.order_type === 'additional')
          .map(contact => ({
            value: contact.value,
            contact_type: this.getStringLabel(contact)
          }));
    },
    primaryContacts() {
      return this.meanContactValues.data
          .filter(contact => contact.order_type === 'primary')
          .map(contact => ({
            value: contact.value,
            contact_type: this.getStringLabel(contact)
          }));
    }
  },
  watch: {
    module(newVal) {
      this.meanContactValues = newVal;
    }
  }
};
</script>

<style scoped>
.no-data {
  color: lightgray;
}
</style>
