<template>
  <div class="ui container">
    <form class="ui form">
      <div class="two fields">
        <div class="field" :class="{ error: $v.data.curp.$error }">
          <label> {{ $t('identification_data.form.curp') }} *</label>
          <sui-input
            :disabled="readOnlyMode"
            type="text"
            name="curp"
            :placeholder="$t('identification_data.form.curp')"
            v-model="$v.data.curp.$model"
            :value="$v.data.curp.$model"
            :loading="loading"
          />
          <div v-if="$v.data.curp.$error" class="error-message">
            <span v-if="!$v.data.curp.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.curp')) }}
            </span>
            <span v-else-if="!$v.data.curp.minLength || !$v.data.curp.maxLength">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.curp')) }}
            </span>
            <span v-else-if="!$v.data.curp.curpFormat">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.curp')) }}
            </span>
          </div>
        </div>
        <div class="field" :class="{ error: $v.data.name.$error }">
          <label>{{ $t('identification_data.form.name') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="first-name"
            :placeholder="$t('identification_data.form.name')"
            v-model.trim="$v.data.name.$model"
            @blur="$v.data.name.$touch()"
          />

          <div v-if="$v.data.name.$error" class="error-message">
            <span v-if="!$v.data.name.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.name')) }}
            </span>
            <span v-else-if="!$v.data.name.minLength">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.name')) }}
            </span>
            <span v-else-if="!$v.data.name.isValidName">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.name')) }}
            </span>
          </div>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.data.lastname.$error }">
          <label> {{ $t('identification_data.form.lastname') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="last-name"
            :placeholder="$t('identification_data.form.lastname')"
            v-model.trim="$v.data.lastname.$model"
            @blur="$v.data.lastname.$touch()"
          />
          <div v-if="$v.data.lastname.$error" class="error-message">
            <span v-if="!$v.data.lastname.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.lastname')) }}
            </span>
            <span v-else-if="!$v.data.lastname.minLength">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.lastname')) }}
            </span>
            <span v-else-if="!$v.data.lastname.isValidName">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.lastname')) }}
            </span>
          </div>
        </div>

        <div class="field" :class="{ error: $v.data.second_lastName.$error }">
          <label>{{ $t('identification_data.form.second_lastname') }} *</label>
          <input
            :disabled="readOnlyMode"
            type="text"
            name="second-last-name"
            :placeholder="$t('identification_data.form.second_lastname')"
            v-model.trim="$v.data.second_lastName.$model"
            @blur="$v.data.second_lastName.$touch()"
          />

          <div v-if="$v.data.second_lastName.$error" class="error-message">
            <span v-if="!$v.data.second_lastName.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.second_lastname')) }}
            </span>
            <span v-else-if="!$v.data.second_lastName.minLength">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.second_lastname')) }}
            </span>
            <span v-else-if="!$v.data.second_lastName.isValidName">
              {{ $potFilterNameTag($t('validation.field_invalid'), $t('identification_data.form.second_lastname')) }}
            </span>
          </div>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.data.nationality.$error }">
          <label>{{ $t('identification_data.form.nationality') }} *</label>
          <sui-dropdown
            :disabled="readOnlyMode"
            :placeholder="$t('identification_data.form.nationality')"
            selection
            :options="nacionalidades"
            v-model="$v.data.nationality.$model"
            @blur="$v.data.nationality.$touch()"
          />
          <div v-if="$v.data.nationality.$error" class="error-message">
            <span v-if="!$v.data.nationality.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.nationality')) }}
            </span>
          </div>
        </div>

        <div class="field" :class="{ error: $v.data.gender.$error }">
          <label>{{ $t('identification_data.form.gender') }} *</label>
          <sui-dropdown
            :disabled="readOnlyMode"
            :placeholder="$t('identification_data.form.gender')"
            selection
            :options="sexos"
            v-model="data.gender"
            @blur="$v.data.gender.$touch()"
          />
          <div v-if="$v.data.gender.$error" class="error-message">
            <span v-if="!$v.data.gender.required">
              {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.gender')) }}
            </span>
          </div>
        </div>
      </div>
      <div class="two fields">
        <div class="field" :class="{ error: $v.formattedBirthdate.$error }">
          <label>{{ $t('identification_data.form.birthdate') }} *</label>
          <div class="ui " id="fecha_nacimiento">
            <datepicker
              :disabled="readOnlyMode"
              :placeholder="$t('identification_data.form.birthdate')"
              v-model="$v.formattedBirthdate.$model"
              :value="formattedBirthdate"
              :language="es"
              :open-date="disabledDates.from"
              :initial-view="'year'"
              :disabled-dates="disabledDates"
              @input="$v.formattedBirthdate.$touch()"
              valueType="format"
              format="dd/MM/yyyy"
            >
            </datepicker>
            <div v-if="$v.formattedBirthdate.$error" class="error-message">
              <span v-if="!$v.formattedBirthdate.required">
                {{ $potFilterNameTag($t('validation.field_required'), $t('identification_data.form.birthdate')) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <modal :error="mineError" v-show="isModalVisible" @close="handleModal" />
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { es } from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';
import { nacionalidades, sexos } from '@/shared/constants';
import modal from '../shared/Modal';
import moment from 'moment';
import { curpFormat} from '@/utilities/curpValidators';
import { isValidName } from '@/utilities/rfcValidators';

const CURP_LENGTH = 18;

export default {
  name: 'PersonaF',
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      es: es,
      disabledDates: {
        from: moment()
          .subtract(16, 'years')
          .toDate(),
        to: moment()
          .subtract(120, 'years')
          .toDate()
      },
      data: {
        taxpayer_type: 'natural_person',
        curp: '',
        name: '',
        lastname: '',
        second_lastName: '',
        birthdate: '',
        nationality: null,
        gender: null
      },
      formattedBirthdate: '',
      nacionalidades: nacionalidades,
      sexos: sexos,
      loading: false,
      mineError: {
        title: '',
        description: ''
      },
      isModalVisible: false
    };
  },
  components: {
    datepicker,
    modal
  },
  validations: {
    data: {
      curp: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18),
        curpFormat
      },
      second_lastName: {
        required,
        minLength: minLength(1),
        isValidName
      },
      lastname: {
        required,
        minLength: minLength(1),
        isValidName
      },
      name: {
        required,
        minLength: minLength(1),
        isValidName
      },
      nationality: {
        required,
        minLength: minLength(1)
      },
      gender: {
        required,
        minLength: minLength(1)
      },
      birthdate: {
        required
      }
    },
    formattedBirthdate: {
      required
    }
  },
  methods: {
    getNationalityById(id) {
      return this.nacionalidades.filter(i => i.value === id)[0];
    },
    validateForm() {
      let isValidForm = false;
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit('data', {
          taxpayer_type: this.data.taxpayer_type,
          data: {
            name: this.data.name,
            lastname: this.data.lastname,
            second_lastname: this.data.second_lastName,
            curp: this.data.curp,
            birthdate: this.data.birthdate,
            nationality: this.getNationalityById(this.data.nationality).text,
            gender: this.data.gender
          }
        });
        this.$emit('setCurp', this.data.curp);
        isValidForm = true;
      } else {
        this.$emit('validatedComponent', false);
        isValidForm = false;
      }
      return isValidForm;
    },
    /**
     * Checks if the modal should be hidden or shown
     * @author Ramon Manrique Figueroa
     * @date 11/08/2020
     * @return void
     */
    handleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    /**
     * Checks if the CURP length is valid
     * @author Ramon Manrique Figueroa
     * @date 11/08/2020
     * @param curp - CURP Value
     * @return boolean
     */
    isValidCurpLength(curp) {
      return curp.length === CURP_LENGTH;
    },
    loadCurpData() {
      if (this.isValidCurpLength) {
        this.loading = true;
        this.fetchData(this.data.curp)
          .then(() => {
            this.loading = false;
            this.setCurpData(this.loadCurp());
          })
          .catch(err => {
            if (err.response && err.response.status === 404) {
              this.mineError.description = this.$t('renapo.0002');
              this.mineError.title = err;
              this.handleModal();
              this.loading = false;
            } else {
              this.mineError.description = this.$t('renapo.socket');
              this.mineError.title = err;
              this.handleModal();
              this.loading = false;
            }
          });
      }
    },
    ...mapGetters({
      loadCurp: 'datos_identificacion/LoadCurp'
    }),
    ...mapActions({
      fetchData: 'datos_identificacion/loadCurpData',
      setData: 'datos_identificacion/setData'
    }),
    /**
     * Sets the curp fetched data to the object model
     * @author Ramon Manrique Figueroa
     * @date 07/08/2020
     * @param curpData fetched curp information
     * @return void
     */
    setCurpData(curpData) {
      const { CURP, Nombres, Apellido2, Apellido1, Nacionalidad, Sexo, FechNac } = curpData;
      this.data.curp = CURP;
      this.data.name = this.$replaceSpecialChars(Nombres);
      this.data.second_lastName = this.$replaceSpecialChars(Apellido2);
      this.data.lastname = this.$replaceSpecialChars(Apellido1);
      this.data.nationality = Nacionalidad;
      this.data.gender = this.determineGender(Sexo);
      this.formattedBirthdate = moment(FechNac, 'DD/MM/YYYY').toDate();
    },
    /**
     * Sets an H for Masculine Gender and F for Femenine Gender
     * @author Ramon Manrique Figueroa
     * @date 07/08/2020
     * @param gender char for gender
     * @return string
     */
    determineGender(gender) {
      return gender === 'H' ? sexos[0].value : sexos[1].value;
    }
  },
  watch: {
    'data.curp': {
      handler: function(newData) {
        this.data.curp = this.$uppercase(newData);
      }
    },
    formattedBirthdate: {
      handler: function(newData) {
        this.data.birthdate = moment(newData, 'DD/MM/yyyy').format('YYYY/MM/DD');
      }
    }
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
  float: left;
}
</style>
