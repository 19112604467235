<template>
  <div class="ui container ">
    <form class="ui form">
      <h4>{{ $t('unsubscribe.form.description') }}</h4>
      <h5>{{ $t('general.form.description_required_fields') }}</h5>
      <div class="two fields">
        <div class="field" :class="{ error: $v.unsubscribe.reason.$error }">
          <label>{{ $t('unsubscribe.form.reason') }} *</label>
          <sui-dropdown
            :disabled="readOnlyMode"
            :placeholder="$t('unsubscribe.form.reason')"
            selection
            :options="getReasons"
            @blur="$v.unsubscribe.reason.$touch()"
            v-model="unsubscribe.reason"
          />
          <tr>
            <span v-if="!$v.unsubscribe.reason.required && $v.unsubscribe.reason.$anyDirty" class="error-message">
              {{ $t('validation.field_required') | potfilter($t('unsubscribe.form.reason')) }}
            </span>
          </tr>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { reasons } from '@/shared/constants'; //eslint-disable-line

export default {
  name: 'BajaRegistro',
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    tipoPersona: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      unsubscribe: {
        reason: null
      },
      reasons: reasons,
      customer_id: {
        type: String,
        default() {
          '';
        }
      }
    };
  },
  created() {
    this.customer_id = this.getCustomerID();
  },
  computed: {
    getReasons() {
      if (this.tipoPersona === 'both') {
        return this.reasons.filter(e => e.value === 1);
      } else {
        return this.reasons.filter(e => e.value > 1);
      }
    }
  },
  methods: {
    /**
     * Validates wether the form data is in valid state
     * @returns {Boolean} isValidaForm
     */
    validateForm() {
      let isValidForm = false;
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit('data', {
          commit_type: 'rec.unsubscribe',
          title: this.getReasonsById(this.unsubscribe.reason).text,
          description: this.getReasonsById(this.unsubscribe.reason).text,
          created_by: this.customer_id
        });
        isValidForm = true;
      } else {
        this.$emit('validatedComponent', false);
        isValidForm = false;
      }
      return isValidForm;
    },
    /**
     * Gets the customer ID from the VUEX Store
     * @returns {id}  id - customer identification from Global Store
     */
    getCustomerID() {
      this.preackID = this.getPreacuse();
      const { id } = this.preackID.data;
      return id;
    },
    /**
     * Passes the text value of a text,value array of reasons
     * @param {id} id of the reason in the collection
     * @returns {i} i text value of given index
     */
    getReasonsById(id) {
      return this.reasons.filter(i => i.value === id)[0];
    },
    ...mapGetters({
      getPreacuse: 'getPreacuse'
    })
  },
  validations: {
    unsubscribe: {
      reason: {
        required
      }
    }
  }
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.checkbox-custom {
  margin: 15px 0px 0px 0px;
}
</style>
