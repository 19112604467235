export default {
    currentAlias: '',
    currentModule: '',
    loading: {
        isLoading: false,
        message: ''
    },
    responseStatus: {
        type: 0,
        message: ''
    },
    tipoPersona: '',
    disableRouteLeaveDialog: false,
    errorModalData: {
        show: false,
        error: {
            title: '',
            description: ''
        }
    }
}
