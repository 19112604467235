/**
 * This fllter transform every character to uppercase using the toUppercase function
 * Adds a property called commonFilters to the proptype and under this the 
 * $uppercase filter.
 * 
 * @return string
 */
export default {
  install(Vue) {
    Vue.prototype.$uppercase = (value) => {
        if (!value) return ''
        value = value.toString()
        return value.toUpperCase()
    }
  }  
}
