<template lang="html">
  <div>
    <transition name="slide" mode="out-in">
      <div class="ui tertiary inverted green segment flyMessage" v-if="getResponseType === 1">
        <p>{{getResponseMessage}}</p>
      </div>
    </transition>
    <transition name="slide" mode="out-in">
      <div class="ui tertiary inverted red segment flyMessage" v-if="getResponseType === 2">
        <p>{{getResponseMessage}}</p>
      </div>
    </transition>
  </div>

</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getResponseType: 'getResponseType',
      getResponseMessage: 'getResponseMessage'
    }),
  }
}
</script>

<style lang="scss" scoped>
.flyMessage {
  position: absolute;
  width: 90%;
  left: 5%;
  top: 3%;
  z-index: 100;
  font-size: 1.3rem;
}
</style>
