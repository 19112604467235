<template>
  <div class="ui container ">
    <form class="ui form">
      <h4>{{ $t('inscription_request.form.description') }}</h4>
      <h5>{{ $t('general.form.description_required_fields') }}</h5>
      <div class="two fields">
        <div class="field" :class="{ error: $v.inscription_request.preponderant_activity.$error }">
          <label>{{ $t('inscription_request.form.preponderant_activity') }} *</label>
          <input
              type="text"
              :disabled="readOnlyMode"
              name="preponderant_activity"
              :placeholder="$t('inscription_request.form.preponderant_activity')"
              v-model="inscription_request.preponderant_activity"
              @blur="$v.inscription_request.preponderant_activity.$touch()"
          />
          <span
              v-if="
              !$v.inscription_request.preponderant_activity.required &&
                $v.inscription_request.preponderant_activity.$anyDirty
            "
              class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('inscription_request.form.preponderant_activity')) }}
          </span>
        </div>
      </div>

      <div class="two fields">
        <div class="field" :class="{ error: $v.operationsStartedDateFormat.$error }">
          <label>{{ $t('inscription_request.form.operations_started_date') }} *</label>
          <div class="ui">
            <datepicker
                :disabled="readOnlyMode"
                :placeholder="$t('inscription_request.form.operations_started_date')"
                v-model="operationsStartedDateFormat"
                :value="operationsStartedDateFormat"
                :language="es"
                :open-date="yearRuleDates.from"
                :initial-view="'year'"
                :disabled-dates="yearRuleDates"
                @input="$v.operationsStartedDateFormat.$touch()"
                valueType="format"
                format="dd/MM/yyyy"
            >
            </datepicker>

            <span
                v-if="!$v.operationsStartedDateFormat.required && $v.operationsStartedDateFormat.$anyDirty"
                class="error-message"
            >
              {{ $t('validation.field_required') | potfilter($t('inscription_request.form.operations_started_date')) }}
            </span>
          </div>
        </div>

        <div class="field" v-if="operationsStartedDateFormat">
          <label>{{ $t('inscription_request.form.imss_started_date') }} {{ isPatronalOrImssFilled ? '*' : '' }}</label>
          <div class="ui">
            <datepicker
                :disabled="readOnlyMode"
                :placeholder="$t('inscription_request.form.imss_started_date')"
                v-model="imssStartedDateFormat"
                :value="imssStartedDateFormat"
                :language="es"
                :open-date="imssStartedDateRuleDates.from"
                @input="
                !$v.inscription_request.imss_started_date.$touch();
                throwDependantValidations();
              "
                :initial-view="'year'"
                :disabled-dates="imssStartedDateRuleDates"
                valueType="format"
                format="dd/MM/yyyy"
            >
            </datepicker>
          </div>
          <span
              v-if="
              !$v.inscription_request.imss_started_date.required && $v.inscription_request.imss_started_date.$anyDirty
            "
              class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('inscription_request.form.imss_started_date')) }}
          </span>
        </div>
      </div>

      <div class="two fields">
        <div class="field" :class="{ error: $v.inscription_request.patronal_register_number.$error }">
          <label
          >{{ $t('inscription_request.form.patronal_register_number') }}
            {{ isPatronalOrImssFilled ? '*' : '' }}</label
          >
          <input
              maxlength="11"
              :disabled="readOnlyMode"
              type="text"
              name="patronal_register_number"
              :placeholder="$t('inscription_request.form.patronal_register_number')"
              v-model="inscription_request.patronal_register_number"
              @blur="
              $v.inscription_request.patronal_register_number.$touch();
              throwDependantValidations();
            "
          />

          <span
              v-if="
              !$v.inscription_request.patronal_register_number.required &&
                $v.inscription_request.patronal_register_number.$anyDirty
            "
              class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('inscription_request.form.patronal_register_number')) }}
          </span>

          <span
              v-if="
              (!$v.inscription_request.patronal_register_number.minLength ||
                !$v.inscription_request.patronal_register_number.maxLength ||
                !$v.inscription_request.patronal_register_number.alphaNum) &&
                $v.inscription_request.patronal_register_number.$anyDirty
            "
              class="error-message"
          >
            {{ $t('validation.field_invalid') | potfilter($t('inscription_request.form.patronal_register_number')) }}
          </span>
        </div>

        <div class="field" :class="{ error: $v.inscription_request.employees_number.$error }">
          <label>{{ $t('inscription_request.form.employees_number') }} {{ isISNSelected ? '*' : '' }}</label>
          <input
              :disabled="readOnlyMode"
              type="text"
              name="employees_number"
              :placeholder="$t('inscription_request.form.employees_number')"
              v-model="inscription_request.employees_number"
              @blur="$v.inscription_request.employees_number.$touch()"
          />

          <span v-if="!$v.inscription_request.employees_number.numeric" class="error-message">
            {{ $t('validation.field_invalid') | potfilter($t('inscription_request.form.employees_number')) }}
          </span>
          <span
              v-if="
              !$v.inscription_request.employees_number.required && $v.inscription_request.employees_number.$anyDirty
            "
              class="error-message"
          >
            {{ $t('validation.field_required') | potfilter($t('inscription_request.form.employees_number')) }}
          </span>
        </div>
      </div>

      <br />
      <div class="field" :class="{ error: $v.taxesArray.$error }" v-if="operationsStartedDateFormat">
        <label>{{ $t('inscription_request.form.taxes') }} *</label>
        <template v-for="(tax, key) in taxesArray">
          <div :key="key" class="checkbox-custom">
            <sui-checkbox
                :disabled="readOnlyMode"
                :label="$t('inscription_request.form.taxes.' + key.toLowerCase())"
                v-model="taxesArray[key]"
            />
            <template v-if="Object.keys(taxesInitDate).includes(key) && taxesArray[key]">
              <div class="tax_date_form field" :class="{ error: $v.taxesInitDate[key].$error }">
                <label>{{ labelInscriptionRequestTaxes(key.toLowerCase()) }} * </label>
                <datepicker
                    :disabled="readOnlyMode"
                    :placeholder="labelInscriptionRequestTaxes(key.toLowerCase())"
                    v-model="taxesInitDate[key]"
                    :value="taxesInitDate[key]"
                    :language="es"
                    :open-date="taxEnvironmentalRuleDates.from"
                    :initial-view="'year'"
                    :disabled-dates="key === 'ISN' ? taxLimitDateISN : taxEnvironmentalRuleDates"
                    @input="$v.taxesInitDate[key].$touch()"
                    valueType="format"
                    format="dd/MM/yyyy"
                />
                <template v-if="key != 'ISN'">
                  <span v-if="!$v.taxesInitDate[key].required && $v.taxesInitDate[key].$anyDirty" class="error-message">
                    Campo requerido
                  </span>
                </template>
                <template v-else>
                  <span v-if="!$v.taxesInitDate[key].required && $v.taxesInitDate[key].$anyDirty" class="error-message">
                    {{
                      $t('validation.field_required') | potfilter($t('inscription_request.form.hiring_date_in_state'))
                    }}
                  </span>
                </template>
              </div>
            </template>
          </div>
        </template>
        <span v-if="$v.taxesArray.$error" class="error-message">
          {{ $t('validation.field_required_atleast_one') | potfilter($t('inscription_request.form.taxes')) }}
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { required, numeric, alphaNum, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { es } from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'SolicitudInscripcion',
  props: {
    readOnlyMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    constitutiveDate: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      es: es,
      companyConstitutionDate: this.constitutiveDate,
      fiveYearRuleDates: {
        from: moment().toDate(),
        to: this.getHiringToDate()
      },
      yearRuleDates: {
        from: moment().toDate(),
        to: moment()
            .subtract(150, 'years')
            .toDate()
      },
      inscription_request: {
        preponderant_activity: '',
        operations_started_date: '',
        imss_started_date: '',
        patronal_register_number: '',
        employees_number: '',
        taxes: '',
        taxes_data: []
      },
      taxesArray: {
        ISN: false,
        IPOP: false,
        IJA: false,
        ISAN: false,
        ISH: false,
        ICEMP: false,
        IECAT: false,
        IECA: false,
        IECS: false,
        ISHR: false,
      },
      taxesInitDate: {
        ISN: '',
        ICEMP: '',
        IECAT: '',
        IECA: '',
        IECS: ''
      },
      operationsStartedDateFormat: '',
      imssStartedDateFormat: ''
    };
  },
  computed: {
    isSomeTaxWithDateSelected() {
      return Object.keys(this.taxesInitDate).some(key => this.taxesArray[key] === true);
    },
    taxLimitDateISN() {
      return {
        from: moment().toDate(),
        to: this.operationsStartedDateFormat ? this.operationsStartedDateFormat : moment().toDate()
      };
    },
    taxEnvironmentalRuleDates() {
      return {
        from: moment().toDate(),
        to: this.operationsStartedDateFormat ? this.operationsStartedDateFormat : moment().toDate()
      };
    },
    imssStartedDateRuleDates() {
      return {
        from: moment().toDate(),
        to: this.operationsStartedDateFormat ? this.operationsStartedDateFormat : moment().toDate()
      };
    },
    isEnviromentalTaxICEMPSelected() {
      return this.taxesArray['ICEMP'] === true;
    },

    isISHRSelected() {
      return this.taxesArray['ISHR'] === true;
    },
    isValidTaxes: function() {
      return (
          this.taxesArray.ISN ||
          this.taxesArray.IPOP ||
          this.taxesArray.IJA ||
          this.taxesArray.ISAN ||
          this.taxesArray.ISH ||
          this.taxesArray.ICEMP ||
          this.taxesArray.IECAT ||
          this.taxesArray.IECA ||
          this.taxesArray.IECS ||
          this.taxesArray.ISHR
      );
    },
    isPatronalOrImssFilled: function() {
      return (
          this.inscription_request.patronal_register_number !== '' || this.inscription_request.imss_started_date !== ''
      );
    },
    isISNSelected: function() {
      return this.taxesArray.ISN;
    }
  },
  components: {
    datepicker
  },
  methods: {
    labelInscriptionRequestTaxes(key) {
      return key === 'isn'
          ? this.$t('inscription_request.form.hiring_date_in_state')
          : this.$t('inscription_request.form.dates.taxes.' + key.toLowerCase());
    },
    calculateDateObligationInit(taxDate) {
      let limitDate = moment('2022-01-01').format('YYYY/MM/DD')
      if (taxDate.isBefore('2022-01-01')) return limitDate
      return taxDate.format('YYYY/MM/DD')
    },
    generateTaxDataForEnvironmental() {
      this.inscription_request.taxes_data = [];
      Object.keys(this.taxesInitDate).map(key => {
        if (this.taxesArray[key] === true) {
          let taxDataTemplate = {
            name: key,
            param: '',
            dateSelected: moment(this.taxesInitDate[key]).format('YYYY/MM/DD'),
            value: key === 'ISN' ? moment(this.taxesInitDate[key]).format('YYYY/MM/DD') : this.calculateDateObligationInit(moment(this.taxesInitDate[key]))
          };
          let isTaxDataAlreadyAdd = this.inscription_request.taxes_data.indexOf(tax => tax.name === key);
          if (isTaxDataAlreadyAdd >= 0) {
            this.inscription_request.taxes_data[isTaxDataAlreadyAdd].value = moment(this.taxesInitDate[key]).format(
                'YYYY/MM/DD'
            );
          } else {
            if (key === 'ISN') {
              taxDataTemplate.param = 'init_operations';
              this.inscription_request.taxes_data.push(taxDataTemplate);
            } else {
              taxDataTemplate.param = 'hiring_date_in_state';
              this.inscription_request.taxes_data.push(taxDataTemplate);
            }
          }
        }
      });
    },
    isEnviromentalTaxSelected(key) {
      return this.taxesArray[key] === true;
    },
    customFormatter(date) {
      return Vue.moment(date).format('L');
    },
    validateForm() {
      if (this.isEnviromentalTaxICEMPSelected) {
        this.$emit('setHasEnvironmentalTax', true);
      } else {
        this.$emit('setHasEnvironmentalTax', false);
      }
      if (this.isISHRSelected) {
        this.$emit('setHasISHRTax', true);
      } else {
        this.$emit('setHasISHRTax', false);
      }
      if (this.isSomeTaxWithDateSelected) {
        this.generateTaxDataForEnvironmental();
      }
      let isValidForm = false;
      this.runFormValidation();
      if (!this.$v.$error) {
        this.$emit('data', this.inscription_request);
        isValidForm = true;
      } else {
        this.$emit('validatedComponent', false);
        isValidForm = false;
      }
      return isValidForm;
    },
    throwDependantValidations() {
      if (this.taxesArray.ISN) {
        this.$v.inscription_request.employees_number.$touch();
        this.$v.taxesInitDate['ISN'].$touch();

        if (this.$v.inscription_request.imss_started_date.$anyDirty) {
          this.$v.inscription_request.patronal_register_number.$touch();
        } else if (this.$v.inscription_request.patronal_register_number.$anyDirty) {
          this.$v.inscription_request.imss_started_date.$touch();
        }
      }
    },
    getHiringToDate() {
      let dateReturned = moment()
          .subtract(5, 'years')
          .toDate();
      if (this.companyConstitutionDate && this.companyConstitutionDate.length) {
        const companyConstitutionToDate = moment(this.companyConstitutionDate).toDate();
        if (moment(companyConstitutionToDate).isAfter(dateReturned)) dateReturned = companyConstitutionToDate;
      }
      return dateReturned;
    },
    runFormValidation() {
      this.$v.$reset();
      this.$v.$touch();
    }
  },
  watch: {
    imssStartedDateFormat: {
      handler: function(newData) {
        newData = JSON.stringify(newData);
        var newDate = `${newData.substr(1, 4)}/${newData.substr(6, 2)}/${newData.substr(9, 2)}`;
        this.inscription_request.imss_started_date = newDate;
      }
    },
    operationsStartedDateFormat: {
      handler: function(newData) {
        newData = JSON.stringify(newData);
        var newDate = `${newData.substr(1, 4)}/${newData.substr(6, 2)}/${newData.substr(9, 2)}`;
        this.inscription_request.operations_started_date = newDate;
      }
    },
    constitutiveDate: {
      handler: function(newDate) {
        this.companyConstitutionDate = newDate;
        this.fiveYearRuleDates.to = this.getHiringToDate();
      }
    },
    taxesArray: {
      handler: function(newData) {
        let values = [];
        for (const key in newData) {
          if (newData[key]) {
            values.push(key);
          }
        }
        this.inscription_request.taxes = values;
      },
      deep: true
    }
  },
  validations() {
    let validations = {
      inscription_request: {
        preponderant_activity: {
          required
        },
        operations_started_date: {
          required
        },
        imss_started_date: {
          required: requiredIf(function() {
            return this.isPatronalOrImssFilled;
          })
        },
        patronal_register_number: {
          alphaNum,
          minLength: minLength(11),
          maxLength: maxLength(11),
          required: requiredIf(function() {
            return this.isPatronalOrImssFilled;
          })
        },
        employees_number: {
          numeric,
          required: requiredIf(function() {
            return this.isISNSelected;
          })
        },
        taxes: {
          required
        }
      },
      taxesArray: {
        required_atleast_one() {
          return this.isValidTaxes;
        }
      },
      taxesInitDate: {
        ISN: { required: false },
        ICEMP: { required: false },
        IECAT: { required: false },
        IECA: { required: false },
        IECS: { required: false }
      },
      operationsStartedDateFormat: {
        required
      }
    };
    Object.keys(this.taxesInitDate).map(key => {
      if (this.isEnviromentalTaxSelected(key)) {
        validations.taxesInitDate[key] = { required };
      }
    });
    return validations;
  }
};
</script>

<style lang="scss">
.tax_date_form {
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;

  label {
    font-weight: 600;
  }
  .vdp-datepicker {
    max-width: 20rem;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.checkbox-custom {
  margin: 15px 0px 0px 0px;
}
</style>
