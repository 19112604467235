import { helpers } from 'vuelidate/lib/validators';

export const rfcFormat = helpers.regex(
  'rfcFormat',
  /^([A-ZÑ&]{3,4})([0-9]{2}[0-1]{1}[0-9]{1}[0-3]{1}[0-9]{1})([A-Z0-9]{3})$/
);

export const isValidName = helpers.regex('isValidName', /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]+([ a-zA-ZÀ-ÿ\u00f1\u00d1]+)*$/);

export const isValidEmail = helpers.regex(
  'isValidEmail',
  /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
);

export const isNumeric = helpers.regex('isNumeric', /^(?=.*[0-9])[- +()0-9]+$/);
