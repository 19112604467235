<template>
    <div>
        <div id="lista-actividades" >
            <span v-show="error" class="error"  >
                {{ $t('preponderant_activity.percentage_error') }}
            </span>
            <ListaActividades 
            :activities="activities"
            :readOnlyMode="readOnlyMode"
            :defaultErrorText="$t('preponderant_activity.percentage_error')"
            :columns="columns"
            @activityListError="handleListingError"
            @lockControls="handleLockControls"
            @data="handleActivitiesWithpercentage" />
        </div>
        <Buscador
        :placeholder="$t('preponderant_activity.form.search')"
        :readOnlyMode="disableSearch" 
        @selectedItem="sendActivitytoList"
        :search="searchActivities" />
    </div>
</template>

<script>
import { searchActivities } from '@/shared/services.js';
import Buscador from '@/components/shared/Buscador.vue';
import ListaActividades from './ListaActividades.vue';
export default {
    components: {
        Buscador,
        ListaActividades
    },
    props: {
        readOnlyMode: {
            type: Boolean,
            default () {
                return false;
            }
        }
    },
    data() {
        return {
            columns: [
                this.$t('preponderant_activity.form.table.description'),
                this.$t('preponderant_activity.form.table.percentage'),
                this.$t('preponderant_activity.form.table.action')
            ],
            lockControls: false,
            activities: [],
            activitiesPercentage: [],
            error: '',
        }
    },
    methods: {
        /**
         * Wrapper for the searchActivitie sservice.
         * 
         * @returns void
         */
        searchActivities: searchActivities,
        /**
         * Assigns the selected activity as an array to the <activities> data prop to assign activities 
         * one by one
         * 
         * @returns void
         */
        sendActivitytoList(activity) {
            this.activities = [activity];
        },
        /**
         * Handles the @data event from the activity list component and assigns it to the
         * <activityPercentage> data prop this returned activities are already processed activities 
         * with the needed structure to be sent to the parent of this component.
         * 
         * @returns void
         */
        handleActivitiesWithpercentage(activities) {
            this.activitiesPercentage = activities;
        },
        /**
         * Used by the parent component to invoke if this component is valid, this function should
         * return strictly a boolean value doesn't handle asyncronous behavior.
         * it should not run any asyncronous logic inside this function.
         * 
         * @returns boolean
         * 
         */
        validateForm() {
            let isValid = false;
            if (!this.error.length && this.activitiesPercentage.length) {
                this.error = '';
                this.activitiesPercentage = this.parseActivitiesPercentage();
                this.$emit('data', { activities: this.activitiesPercentage });
                isValid = true;
            } else {
                this.error = this.$t('preponderant_activity.percentage_error')
                return isValid;
            }
            return isValid;
        },
        /**
         * Parses the info to match backend service needs.
         * 
         * @returns void
         */
        parseActivitiesPercentage() {
            return  this.activitiesPercentage.map(activity => {
                    if(activity)
                        return { activity_id: activity.id, percentage: activity.percentage }
                });
        },
        /**
         * Handles the @activityListError event and assigns the value to the "error" data property.
         * 
         * @returns void
         */
        handleListingError(error) {
            this.error = error;
        },
        /**
         * Handles the @lockControls event assigns it to the lockControls data property of 
         * this component
         * 
         * @returns void
         */
        handleLockControls(lockControls) {
            this.lockControls = lockControls;
        }
    },
    computed: {
        disableSearch() {
            return Boolean(this.activitiesPercentage.length) || 
                            this.readOnlyMode || 
                            this.lockControls
        }
    }
}
</script>

<style>
#lista-actividades {
    margin-bottom: 70px;
}
.error {
    margin-top: 5px;
    color: red;
    font-size: 12px;
    margin-left: 10px;
}
</style>
