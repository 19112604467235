<template>
  <div class="ui grid container">
    <br />
    <h2 class="ui header">Documento Protocalizado:</h2>
    <div class="two column row">
      <div class="column">
        <div class="ui list">
          <div class="item">
            <div class="ui header">{{ POElabels[`book`] }}</div>
            {{ constitutiveActValues.book }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`date_signature_constitution`] }}</div>
            {{ formattedConstitutiveActDate }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`rfc_notary_public`] }}</div>
            {{ constitutiveActValues.rfc_notary_public }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui list">
          <div class="item">
            <div class="ui header">{{ POElabels[`rpp_and_trade_data`] }}</div>
            {{ constitutiveActValues.rpp_and_trade_data }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`sheet`] }}</div>
            {{ constitutiveActValues.sheet }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`nationality`] }}</div>
            {{ getCountryName(constitutiveActValues.nationality) }}
          </div>
        </div>
      </div>
    </div>
    <sui-divider />
  </div>
</template>

<script>
import moment from 'moment';
import { nacionalidades } from '@/shared/constants';

export default {
  name: 'ConstitutiveAct',
  props: ['module', 'labels'],
  data() {
    return {
      constitutiveActValues: this.module,
      POElabels: this.labels,
      countries: nacionalidades,
    };
  },
  methods: {
    getStringLabel(key) {
      return this.POEditorLabel[key] || this.POEditorLabel.default;
    },

    getCountryName(key) {
      let countryFound = this.countries.find(country => country.value == key)
      return  countryFound ? countryFound.text  : key;
    }
  },
  computed: {
    formattedConstitutiveActDate() {
      return moment(this.constitutiveActValues.date_signature_constitution).format('DD/MM/YYYY');
    }
  },
  watch: {
    module(newVal) {
      this.constitutiveActValues = newVal;
    }
  }
};
</script>
