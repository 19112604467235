<template>
  <div class="ui grid container">
    <br />
    <h2 class="ui header">Datos de Identificacion:</h2>
    <div class="two column row">
      <div class="column">
        <div class="ui list">
          <div class="item" v-for="(contact, key) in identificationData" :key="key">
            <div v-for="(contactItem, key) in contact" :key="key">
              <div class="ui header">
                {{ `${POElabels[`${key}`]}` }}
              </div>
              {{ validateGender(key, contactItem) }}
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui list">
          <div class="item">
            <div class="ui header">{{ POElabels['taxpayer_type'] }}</div>
            {{ `${$t(getStringLabel(identificationValues.taxpayer_type))}` }}
          </div>
          <div class="item">
            <div class="ui header">RFC</div>
            {{ identificationValues.rfc }}
          </div>
        </div>
      </div>
    </div>
    <sui-divider />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'Identification',
  props: ['module', 'labels'],
  data() {
    return {
      identificationValues: this.module,
      POElabels: this.labels,
      personLabelType: {
        natural_person: 'identification_data.natural_person',
        legal_entity: 'identification_data.legal_entity',
        default: ''
      }
    };
  },
  methods: {
    validateGender(key, content) {
      if (key === 'gender') {
        if (content.toLowerCase()  === 'female') {
          return 'Femenino'
        }
      }
      return content
    },
    getStringLabel(personType) {
      return this.personLabelType[personType] || this.personLabelType.default;
    },
    ...mapGetters({
      getCurrentAlias: 'getCurrentAlias'
    })
  },
  computed: {
    identificationData() {
      const { data } = this.identificationValues;

      for (var propt in data) {
        if (propt.includes('date')) {
          data[propt] = moment(data[propt])
              .utc()
              .format('DD/MM/YYYY');
        }
      }
      return Object.keys(data).map(key => {
        return { [key]: data[key] };
      });
    }
  },
  watch: {
    module(newVal) {
      this.identificationValues = newVal;
    }
  }
};
</script>
