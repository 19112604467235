import axios from 'axios'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
import { updateNoticeMovementDate, postIdentificationData } from '../../shared/services';

const axiosInstance = axios.create({
    headers: {

    }
});

const state = {
    curp: null,
    data: {
        data: {
            constitution_date: ''
        }
    },
    errorServer: false,
    isSaved: false,
    validated: false,
    regimen: ""
}

const mutations = {
    Set_Regimen(state, regimen) {
        state.regimen = regimen;
    },
    Set_DatosCurp(state, curp) {
        state.curp = curp;
    },
    Set_Data(state, data) {
        state.data = data;
    },
    Set_SavedStatus(state, isSaved) {
        state.isSaved = isSaved;
    },
    Set_Validated(state, validated) {
        state.validated = validated
    }

}


const getters = {
    getRegimen(state) {
        return state.regimen;
    },
    LoadCurp() {
        return state.curp
    },
    getIsSaved(state) {
        return state.isSaved;
    },
    loadValidated() {
        return state.validated
    },
    getData() {
        return state.data;
    }

}

const actions = {
    /**
     * @date 2020-07-16  
     * @param {any} {commit}
     * @returns {json} with the data from the server regimen catalog
     */
    loadRegimen: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_SERVICE_BASE_URL}catalogs/regimen`)
                .then((data) => {
                    let regimen = data.data;
                    commit('Set_Regimen', regimen)
                    resolve(true);
                }).catch(error => reject(error))
        });

    },

    /**
     * jaimexcastillo
     * @date 2020-05-29
     * @param {any} {commit}
     * @param {string} curp recives a Curp 
     * @returns {json} with response of the server being a json with the data of the person from the curp
     */
    loadCurpData: ({ commit }, curp) => {
        if (!curp) { return; }
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${process.env.VUE_APP_SERVICE_BASE_URL}renapo/${curp}`)
                .then(res => {
                    if (res.data.data && Object.keys(res.data.data).length === 0 && Object.getPrototypeOf(res.data.data) === Object.prototype) {
                        reject('Empty response')
                    }
                    console.log(res.data);
                    commit('Set_DatosCurp', res.data.data);
                    resolve();
                })
                .catch(error => reject(error))
        });
    },
    /**
     * jaimexcastillo
     * @date 2020-05-29
     * @param {any} {commit
     * @param {any} state} recives the vue data from a component
     * @returns {any} response with the result of the post service
     * 
     * TODO: Cambiar datosIden por solo data 
     */
    // eslint-disable-next-line no-unused-vars
    saveData({ state, commit }) {
        if (!state.data)
            return;

        if (state.data.transaction_applied_at) {
            return new Promise((resolve, reject) => {
                updateNoticeMovementDate(state.data.preack_id, state.data.transaction_applied_at, state.data.state_account_number)
                    .then(() => {
                        postIdentificationData(state.data)
                            .then(() => {
                                resolve();
                            })
                            .catch((error) => reject(error))
                    })
                    .catch((err => reject(err)));
            });
        } else {
            return new Promise((resolve, reject) => {
                postIdentificationData(state.data)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => reject(error))
            });
        }
    },
    setData: ({ commit }, data) => {
        commit('Set_Data', data)
    },
    setIsSaved({ commit }, isSaved) {
        commit('Set_SavedStatus', isSaved);
    },
    validator({ commit }, validated) {
        commit('Set_Validated', validated)
    }
};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
