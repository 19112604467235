import axios from 'axios';

const BASE_URL = process.env.VUE_APP_SERVICE_BASE_URL;

/**
 * Gets the data that has been committed by the customer
 * @param {Object} payload Contact Means object
 * @returns {Promise} response promise
 */
export const getPreview = payload => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL}preack/${payload}/preview`)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
};