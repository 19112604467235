import Vue from 'vue'

Vue.filter('uppercase', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.toUpperCase()
})

Vue.filter('renapoHandler', function(value) {
    if (!value) return ''
    value = value.replace('�', 'Ñ')
    return value;
})