<template>
  <div>
    <Autocomplete
        :search="searchItems"
        :placeholder="placeholder || 'Buscar.'"
        :aria-label="placeholder || 'Buscar.'"
        :get-result-value="getResultValue"
        @submit="selectedItem"
        :debounceTime="400"
        :autoSelect="true">
      <template
          #default="{
        rootProps,
        inputProps,
        inputListeners,
        resultListProps,
        resultListListeners,
        results,
        resultProps
        }"
      >
        <div v-bind="rootProps">
          <input
              :disabled="readOnlyMode"
              v-bind="inputProps"
              v-on="inputListeners"
              :class="[
                'autocomplete-input',
                'autocomplete-input-no-results',
                'autocomplete-input-focused'
                ]"
              type="text"
              name="search-autocomplete"
          />
          <ul v-bind="resultListProps" v-on="resultListListeners">
            <li
                v-for="(result, index) in results"
                :key="resultProps[index].id"
                v-bind="resultProps[index]">
              <div v-if="result" class="activity-container" >
                <div class="activity-title">
                  <strong>{{ result.title }}</strong>
                </div>
                <div class="activity-description" >
                  <p><i>{{result.description}}</i></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </autocomplete>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';

export default {
  props: {
    placeholder: {
      type: String,
      default () {
        return '';
      }
    },
    search: {
      type: Function,
      default() {
        return null;
      }
    },
    readOnlyMode: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data() {
    return {
      lastTermSearched: ''
    }
  },
  methods: {
    /**
     * Used by the autocomplete to handle the search logic.
     *
     * @param string<input> the input that you enter on the search input box.
     * @returns Promise<[{}]>
     */
    searchItems (input) {
      if (input.length < 3) { return [] }
      if (input === this.lastTermSearched) { return this.activities; }
      if (typeof this.search === 'function') {
        return new Promise((resolve, reject) => {
          this.search(input)
              .then((activities) => {
                this.lastTermSearched = input;
                this.activities = activities;
                resolve(activities);
              })
              .catch(err => reject(err));
        });
      }
    },
    /**
     * Gets the result value that you selected on the search box (used on the ":get-result-valu"
     * auto complete prope ).
     *
     * activity Object<activity> the activity selected by the user from the results list.
     * @returns string<title> returns the string containing the title of the activity selected to
     *          be displayed inside the search box as a value.
     */
    getResultValue(activity) {
      return activity.title
    },
    /**
     * Selected activity by the user on the results list of the autocomplete,
     * performs an emit to the container component (parent component) with the current activity
     * selected.
     *
     * @param Object<activity>
     * @return void.
     */
    selectedItem(activity) {
      this.$emit('selectedItem', activity);
    },
  },
  components: {
    Autocomplete
  }
}
</script>

<style src="@trevoreyre/autocomplete-vue/dist/style.css"></style>
<style lang="scss">

.activity-container {
  .activity-description {
    font-size: 12px;
    margin-top: 5px;
    word-break: break-all;

    @media (min-width: 1000px) {
      width: 45%;
    }
  }
}

</style>
