<template>
    <div class="ui container ">
      <form class="ui form">
        <h4>  {{ $t('tax_decrease.form.description') }} </h4>
        <h5>  {{ $t('general.form.description_required_fields') }} </h5>
        <div class="two fields">
          <div class="field" :class="{ error: $v.tax_decrease.taxes.$error }">
            <sui-form-fields grouped>
              <label>{{ $t("tax_decrease.form.taxes") }} *</label>
              <sui-form-field v-for="tax in taxes" v-bind:key="tax">
                <sui-checkbox :label="tax"
                              :value="tax"
                              @change="addTax(tax, $event)"
                              @blur="$v.tax_decrease.taxes.$touch()"
                              :disabled="readOnlyMode"/>
              </sui-form-field>
            </sui-form-fields>
            <span v-if="!$v.tax_decrease.taxes.required && $v.tax_decrease.taxes.$anyDirty" class="error-message" >
              {{ $t('validation.field_required') | potfilter($t('tax_decrease.form.taxes')) }}
            </span>
          </div>
        </div>
      </form>
    </div>
</template>

<script>
	import { required } from "vuelidate/lib/validators";
    
  export default {
    name: 'DisminucionObligacion',
    props: {
      readOnlyMode: {
        type: Boolean,
        default () {
          return false;
        }
      }
    },
    computed: {
      isEnviromentalTaxICEMPSelected() {
        return this.tax_increase.taxes.includes(this.$t('taxes.icemp'));
      },
    },
    data () {
      return {
        tax_decrease: {
          taxes : []
        },
        taxes: [
          this.$t('taxes.isn'),
          this.$t('taxes.isn_ps'),
          this.$t('taxes.ret_isn'),
          this.$t('taxes.ipop'),
          this.$t('taxes.ija'),
          this.$t('taxes.isan'),
          this.$t('taxes.ish'),
          this.$t('taxes.iteert'),
          this.$t('taxes.icemp'),
          this.$t('taxes.iecat'),
          this.$t('taxes.ieca'),
          this.$t('taxes.iecs')
        ]
      }
    },
    methods:{
      validateForm() {
        let isValidForm = false;
        this.$v.$reset()
        this.$v.$touch()
        if (!this.$v.$error) {
          this.$emit('data', {
            tax_obligation_type: 'tax_decrease',
            obligations: this.tax_decrease.taxes
          });
          isValidForm = true;
        } else {
          this.$emit('validatedComponent', false);
          isValidForm = false;
        }
        return isValidForm;
      },
      /**
       * Called on click of an element of the taxes list
       *
       * @param Object<tax> type of tax to be added
       */
      addTax(tax, event) {
        if (this.tax_decrease.taxes.includes(tax) && !event) {
          this.tax_decrease.taxes.splice(this.tax_decrease.taxes.indexOf(tax), 1);
        } else {
          this.tax_decrease.taxes = [...this.tax_decrease.taxes, ...[tax]];
        }
      }
    },
    validations: {
      tax_decrease: {
        taxes: {
          required
        }
      }
    }
  }
</script>

<style>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
    }

    /* Firefox */
    input[type=number] {
	-moz-appearance: textfield;
    }

    .checkbox-custom {
	margin: 15px 0px 0px 0px;
    }

    
</style>
