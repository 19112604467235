import Vue from 'vue';

Vue.filter('potfilter', function(value, param) {
  return value.replace('__name__', param);
});

Vue.filter('potfilter_file_size', function(value, param) {
  return value.replace('__file_size__', param);
});

Vue.filter('potfilter_file_type', function(value, param) {
  return value.replace('__type__', param);
});

Vue.filter('potfilter_not_same', function(value, param) {
  return value.replace('__field__', param);
});

Vue.filter('potfilter_module', function(value, param) {
  return value.replace('__module__', param);
});

Vue.filter('lengthfilter', function(value, param) {
  return value.replace('__n__', param);
});
