<template>
  <div class="ui grid container">
    <br />
    <h2 class="ui header">Datos de Domicilio Fiscal:</h2>
    <div class="two column row">
      <div class="column">
        <div class="ui list">
          <div class="item">
            <div class="ui header">{{ POElabels[`exterior`] }}</div>
            {{ identificationValues.exterior }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`state`] }}</div>
            {{ identificationValues.state }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`street`] }}</div>
            {{ identificationValues.street }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`neighborhood`] }}</div>
            {{ identificationValues.neighborhood }}
          </div>
          <div class="item" :class="identificationValues.water_and_drain_meter === '' ? 'non-required' : ''">
            <div class="ui header">{{ POElabels[`water_and_drain_meter`] }}</div>
            {{ nonRequiredValue(identificationValues.water_and_drain_meter) }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`references`] }}</div>
            {{ identificationValues.references }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`between_street_2`] }}</div>
            {{ identificationValues.between[1].street }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui list">
          <div class="item " :class="identificationValues.interior === '' ? 'non-required' : ''">
            <div class="ui header">{{ POElabels[`interior`] }}</div>
            {{ nonRequiredValue(identificationValues.interior) }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`municipality`] }}</div>
            {{ identificationValues.municipality }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`property_type`] }}</div>
            {{ identificationValues.property_type }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`type_of_road`] }}</div>
            {{ identificationValues.type_of_road }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`zip`] }}</div>
            {{ identificationValues.zip }}
          </div>
          <div class="item">
            <div class="ui header">{{ POElabels[`between_street_1`] }}</div>
            {{ identificationValues.between[0].street }}
          </div>
        </div>
      </div>
    </div>
    <sui-divider />
  </div>
</template>

<script>
export default {
  name: 'TaxResidence',
  props: ['module', 'labels'],
  data() {
    return {
      identificationValues: this.module,
      POElabels: this.labels
    };
  },
  methods: {
    getStringLabel(key) {
      return this.POEditorLabel[key] || this.POEditorLabel.default;
    },
    nonRequiredValue(value) {
      if (value === '') {
        return this.$t('No se proporciono un valor');
      } else {
        return value;
      }
    }
  },
  watch: {
    module(newVal) {
      this.identificationValues = newVal;
    }
  }
};
</script>

<style scoped>
.non-required {
  color: lightgray;
}
</style>
