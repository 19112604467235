import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_SERVICE_BASE_URL
})

instance.interceptors.request.use(config => {
    return config
})
instance.interceptors.response.use(res => {
    return res
})

axios.defaults.headers.common['Authorization'] = ''
axios.defaults.headers.get['Accepts'] = 'application/json'

export default instance
