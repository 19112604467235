
<template>
  <div>
    <div class="title-header" @click="redirectToHome()">
      <h2 class="ui header center aligned">{{headerTitle}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {
      headerTitle: this.title
    };
  },
  methods: {
    redirectToHome() {
      this.$router.push({ path: `/tramites` });
    }
  }
};
</script>

<style>
.image-header {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.title-header {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.title-header:hover {
  cursor: pointer;
}
</style>
