<template lang="html">
  <form class="ui form notaries__form" @submit.prevent="submitData">
    <div class="field">
      <label>Tipo de Usuario</label>
      <sui-dropdown
        placeholder="Titular/Suplente"
        selection
        :options="types"
        v-model="userType"
      />
    </div>
    <!-- DATOS GENERALES -->
    <h5 v-if="userType != null" class="ui dividing small header">
      DATOS GENERALES DE LA NOTARIA
    </h5>
    <template v-if="userType && userType != 'titular'">
      <div class="two fields">
        <!-- Número de Notaría -->
        <div
          class="field"
          :class="{ error: $v.notary_substitute.notary_number.$error }"
        >
          <label>Número de Notaría</label>
          <input
            type="number"
            v-model="notary_substitute.notary_number"
            name="notary_number"
            placeholder="Número de Notaria"
            required
          />
        </div>
        <!-- Número de Notaría -->
        <!-- Nombre Completo del Notario titular -->
        <div
          class="field"
          :class="{ error: $v.notary_substitute.titular_fullname.$error }"
        >
          <label>Nombre completo del Notario Titular</label>
          <input
            type="text"
            v-model="notary_substitute.titular_fullname"
            name="titular_fullname"
            placeholder="Nombre Completo"
            required
          />
        </div>
        <!-- Nombre Completo del Notario titular -->
      </div>
    </template>
    <template v-if="userType === 'titular'">
      <!-- Número de Notaría -->
      <div class="fields">
        <div
          class="eight wide field"
          :class="{ error: $v.notary_general_data.notary_number.$error }"
        >
          <label>Número de Notaría</label>
          <input
            type="number"
            v-model="notary_general_data.notary_number"
            name="notary_number"
            placeholder="Número de Notaria"
            required
          />
        </div>
      </div>
      <!-- Número de Notaría -->
      <!-- Dirección -->
      <h5 class="ui dividing small header">Dirección</h5>
      <!-- <div class="three fields">

      </div> -->
      <div class="two fields">
        <!-- Entidad Federativa -->
        <div
          class="field"
          :class="{ error: $v.notary_general_data.state.$error }"
        >
          <label>Entidad Federativa</label>
          <input
            type="text"
            name="state"
            v-model="notary_general_data.state"
            maxlength="16"
            placeholder="Estado"
            required
          />
        </div>
        <!-- Entidad Federativa -->
        <!-- Municipio -->
        <div
          class="field"
          :class="{ error: $v.notary_general_data.city.$error }"
        >
          <label>Municipio</label>
          <input
            type="text"
            name="city"
            v-model="notary_general_data.city"
            placeholder="Municipio"
            required
          />
        </div>
        <!-- Municipio -->
      </div>
      <div class="fields">
        <!-- Calle -->
        <div
          class="eight wide field"
          :class="{ error: $v.notary_general_data.street.$error }"
        >
          <label>Calle</label>
          <input
            type="text"
            name="street"
            v-model="notary_general_data.street"
            placeholder="Calle"
            required
          />
        </div>
        <!-- Calle -->
        <!-- Número exterior -->
        <div
          class="four wide field"
          :class="{ error: $v.notary_general_data.exterior_number.$error }"
        >
          <label>Número exterior</label>
          <input
            type="text"
            name="exterior_number"
            v-model="notary_general_data.exterior_number"
            maxlength="5"
            placeholder="#"
            required
          />
        </div>
        <!-- Número exterior -->
        <!-- Número interior -->
        <div class="four wide field">
          <label>Número interior</label>
          <input
            type="text"
            name="interior_number"
            v-model="notary_general_data.interior_number"
            maxlength="5"
            placeholder="#"
          />
        </div>
        <!-- Número interior -->
      </div>
      <div class="two fields">
        <!-- Código postal -->
        <div
          class="field"
          :class="{ error: $v.notary_general_data.zip.$error }"
        >
          <label>Código postal</label>
          <input
            type="text"
            name="zip"
            v-model="notary_general_data.zip"
            maxlength="5"
            placeholder="Código postal"
            required
          />
        </div>
        <!-- Código postal -->
        <!-- Colonia -->
        <div
          class="field"
          :class="{ error: $v.notary_general_data.neighborhood.$error }"
        >
          <label>Colonia</label>
          <input
            type="text"
            name="neighborhood"
            v-model="notary_general_data.neighborhood"
            placeholder="Colonia"
            required
          />
        </div>
        <!-- Colonia -->
      </div>
      <!-- Dirección -->
      <!-- Contacto -->
      <h5 class="ui dividing small header">Contacto</h5>
      <div class="two fields">
        <!-- Número de Teléfono -->
        <div
          class="field"
          :class="{ error: $v.notary_general_data.phone.$error }"
        >
          <label>Número de Teléfono</label>
          <input
            type="text"
            name="phone"
            v-model="notary_general_data.phone"
            maxlength="10"
            pattern="[1-9]{1}[0-9]{9}"
            placeholder="#"
            required
          />
        </div>
        <!-- Número de Teléfono -->
        <!-- Correo electrónico -->
        <div
          class="field"
          :class="{ error: $v.notary_general_data.email.$error }"
        >
          <label>Correo electrónico</label>
          <input
            type="text"
            name="email"
            v-model="notary_general_data.email"
            placeholder="EMAIL"
            required
          />
        </div>
        <!-- Correo electrónico -->
      </div>
      <!-- Contacto -->
      <!-- DATOS GENERALES -->
    </template>

    <!-- TITULAR -->
    <template v-if="userType === 'titular'">
      <h5 class="ui dividing small header">NOTARIO TITULAR</h5>
      <!-- CURP TITULAR -->
      <div class="field" :class="{ error: $v.notary_titular.curp.$error }">
        <label>CURP</label>
        <input
          type="text"
          name="curp"
          v-model="notary_titular.curp"
          maxlength="18"
          placeholder="CURP"
          required
        />
      </div>
      <!-- CURP TITULAR -->
      <div class="three fields">
        <!-- NAME TITULAR -->
        <div class="field" :class="{ error: $v.notary_titular.name.$error }">
          <label>Nombre (s)</label>
          <input
            type="text"
            name="name"
            v-model="notary_titular.name"
            placeholder="Nombre (s)"
            required
          />
        </div>
        <!-- NAME TITULAR -->
        <!-- LASTNAME TITULAR -->
        <div
          class="field"
          :class="{ error: $v.notary_titular.lastname.$error }"
        >
          <label>Apellido Paterno</label>
          <input
            type="text"
            name="lastname"
            v-model="notary_titular.lastname"
            placeholder="Apellido Paterno"
            required
          />
        </div>
        <!-- LASTNAME TITULAR -->
        <!-- SECOND LASTNAME TITULAR -->
        <div
          class="field"
          :class="{ error: $v.notary_titular.second_lastname.$error }"
        >
          <label>Apellido Materno</label>
          <input
            type="text"
            name="second_lastname"
            v-model="notary_titular.second_lastname"
            placeholder="Apellido Materno"
            required
          />
        </div>
        <!-- SECOND LASTNAME TITULAR -->
      </div>
      <!-- RFC TITULAR -->
      <div class="field" :class="{ error: $v.notary_titular.rfc.$error }">
        <label>RFC</label>
        <input
          type="text"
          name="rfc"
          v-model="notary_titular.rfc"
          maxlength="13"
          placeholder="RFC"
          required
        />
      </div>
      <!-- RFC TITULAR -->
      <!-- Contacto TITULAR -->
      <h5 class="ui dividing small header">CONTACTO</h5>
      <div class="two fields">
        <!-- Número de Teléfono TITULAR -->
        <div class="field" :class="{ error: $v.notary_titular.phone.$error }">
          <label>Número de Teléfono</label>
          <input
            type="text"
            name="phone"
            v-model="notary_titular.phone"
            maxlength="10"
            pattern="[1-9]{1}[0-9]{9}"
            placeholder="#"
            required
          />
        </div>
        <!-- Número de Teléfono TITULAR -->
        <!-- Correo electrónico TITULAR -->
        <div class="field" :class="{ error: $v.notary_titular.email.$error }">
          <label>Correo electrónico</label>
          <input
            type="text"
            name="email"
            v-model="notary_titular.email"
            placeholder="EMAIL"
            required
          />
        </div>
        <!-- Correo electrónico TITULAR -->
      </div>
      <!-- Contacto TITULAR -->
      <!-- Datos Adjuntos TITULAR -->
      <h5 class="ui dividing small header">DATOS ADJUNTOS</h5>
      <!-- SAT PDF -->
      <div class="field" :class="{ error: $v.attachments.sat_pdf.$error }">
        <label>Constancia SAT*</label>
        <input
          type="file"
          :accept="acceptFileTypes"
          @change="previewFiles($event, 'sat_pdf')"
          @input="$v.attachments.sat_pdf.$touch()"
          @blur="$v.attachments.sat_pdf.$touch()"
          id="sat_pdf"
          ref="sat_pdf"
        />
      </div>
      <!-- SAT PDF -->
      <!-- NOTARIO PDF -->
      <div
        class="field"
        :class="{ error: $v.attachments.notary_certificate_pdf.$error }"
      >
        <label>Constancia de notario*</label>
        <input
          type="file"
          :accept="acceptFileTypes"
          @change="previewFiles($event, 'notario_pdf')"
          @input="$v.attachments.notary_certificate_pdf.$touch()"
          @blur="$v.attachments.notary_certificate_pdf.$touch()"
          id="notario_pdf"
          ref="notario_pdf"
        />
      </div>
      <!-- NOTARIO PDF -->
      <!-- Datos Adjuntos TITULAR -->
    </template>
    <!-- TITULAR -->
    <!-- SUPLENTE -->
    <template v-if="userType && userType != 'titular'">
      <h5 class="ui dividing small header" v-if="userType === 'subtitute'">NOTARIO SUPLENTE</h5>
      <h5 class="ui dividing small header" v-if="userType === 'capturist'">CAPTURISTA</h5>
      <h5 class="ui dividing small header" v-if="userType === 'payer'">PAGADOR</h5>
      <h5 class="ui dividing small header" v-if="userType === 'capturist-payer'">CAPTURISTA/PAGADOR</h5>

      <!-- CURP SUPLENTE -->
      <div class="field" :class="{ error: $v.notary_substitute.curp.$error }">
        <label>CURP</label>
        <input
          type="text"
          name="curp"
          v-model="notary_substitute.curp"
          maxlength="18"
          placeholder="CURP"
          required
        />
      </div>
      <!-- CURP SUPLENTE -->
      <div class="three fields">
        <!-- NAME SUPLENTE -->
        <div class="field" :class="{ error: $v.notary_substitute.name.$error }">
          <label>Nombre (s)</label>
          <input
            type="text"
            name="name"
            v-model="notary_substitute.name"
            placeholder="Nombre (s)"
            required
          />
        </div>
        <!-- NAME SUPLENTE -->
        <!-- LASTNAME SUPLENTE -->
        <div
          class="field"
          :class="{ error: $v.notary_substitute.lastname.$error }"
        >
          <label>Apellido Paterno</label>
          <input
            type="text"
            name="lastname"
            v-model="notary_substitute.lastname"
            placeholder="Apellido Paterno"
            required
          />
        </div>
        <!-- LASTNAME SUPLENTE -->
        <!-- SECOND LASTNAME SUPLENTE -->
        <div
          class="field"
          :class="{ error: $v.notary_substitute.second_lastname.$error }"
        >
          <label>Apellido Materno</label>
          <input
            type="text"
            name="second_lastname"
            v-model="notary_substitute.second_lastname"
            placeholder="Apellido Materno"
            required
          />
        </div>
        <!-- SECOND LASTNAME SUPLENTE -->
      </div>
      <!-- RFC SUPLENTE -->
      <div class="field" :class="{ error: $v.notary_substitute.rfc.$error }">
        <label>RFC</label>
        <input
          type="text"
          name="rfc"
          v-model="notary_substitute.rfc"
          maxlength="13"
          placeholder="RFC"
          required
        />
      </div>
      <!-- RFC SUPLENTE -->
      <!-- Contacto SUPLENTE -->
      <h5 class="ui dividing small header">CONTACTO</h5>
      <div class="two fields">
        <!-- Número de Teléfono SUPLENTE -->
        <div
          class="field"
          :class="{ error: $v.notary_substitute.phone.$error }"
        >
          <label>Número de Teléfono</label>
          <input
            type="text"
            name="phone"
            v-model="notary_substitute.phone"
            maxlength="10"
            pattern="[1-9]{1}[0-9]{9}"
            placeholder="#"
            required
          />
        </div>
        <!-- Número de Teléfono SUPLENTE -->
        <!-- Correo electrónico SUPLENTE -->
        <div
          class="field"
          :class="{ error: $v.notary_substitute.email.$error }"
        >
          <label>Correo electrónico</label>
          <input
            type="text"
            name="email"
            v-model="notary_substitute.email"
            placeholder="EMAIL"
            required
          />
        </div>
        <!-- Correo electrónico SUPLENTE -->
      </div>
      <!-- Contacto SUPLENTE -->
    </template>
    <!-- SUPLENTE -->
    <div class="submit">
        <span :class="{ error: errorCaptcha }" class="text-captcha">Favor de verificar la casilla del captcha.</span>
      <vue-recaptcha
        @verify="onVerify"
        @expired="onExpired"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        ref="recaptcha"
      ></vue-recaptcha>
      <br/>
    </div>
    <div class="submit" v-if="userType && !isRobot">
      <button class="ui blue button" type="submit">
        Enviar
      </button>
    </div>
  </form>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import { isValidFileSize } from "@/utilities/fileValidators";
import { allowedFileTypes } from "@/shared/constants";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Form",
  components: { VueRecaptcha },
  data() {
    return {
      errorCaptcha: false,
      file: {
        sat_pdf: null,
        notario_pdf: null
      },
      isRobotToken: null,
      isRobot: true,
      sitekey: "6LfhBYIaAAAAAIAKhZ4FElYbn8ZuuN-L5XqP5CG9",
      userType: null,
      notary_general_data: {
        notary_number: null,
        state: null,
        city: null,
        street: null,
        exterior_number: null,
        interior_number: null,
        zip: null,
        neighborhood: null,
        phone: null,
        email: null
      },
      notary_titular: {
        curp: null,
        rfc: null,
        name: null,
        lastname: null,
        second_lastname: null,
        phone: null,
        email: null
      },
      notary_substitute: {
        notary_number: null,
        titular_fullname: null,
        curp: null,
        rfc: null,
        name: null,
        lastname: null,
        second_lastname: null,
        phone: null,
        email: null
      },
      atts: {
        sat_pdf: null,
        notary_certificate_pdf: null
      },
      attachments: {
        sat_pdf: null,
        notary_certificate_pdf: null
      },
      signedUrls: null,
      types: [
        {
          text: "Notario Titular",
          value: "titular"
        },
        {
          text: "Notario Suplente",
          value: "subtitute"
        },
        {
          text: "Capturista",
          value: "capturist"
        },
        {
          text: "Pagador",
          value: "payer"
        },
        {
          text: "Capturista/Pagador",
          value: "capturist-payer"
        }
      ]
    };
  },
  validations: {
    notary_general_data: {
      notary_number: {
        required
      },
      state: {
        required
      },
      city: {
        required
      },
      street: {
        required
      },
      exterior_number: {
        required,
        maxLength: maxLength(5),
      },
      interior_number: {
        maxLength: maxLength(5),
      },
      zip: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5)
      },
      neighborhood: {
        required
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      email: {
        required,
        email
      }
    },
    notary_titular: {
      curp: {
        required
      },
      rfc: {
        required
      },
      name: {
        required
      },
      lastname: {
        required
      },
      second_lastname: {
        required
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      email: {
        required,
        email
      }
    },
    notary_substitute: {
      notary_number: {
        required
      },
      titular_fullname: {
        required
      },
      curp: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },
      rfc: {
        required,
        minLength: minLength(13),
        maxLength: maxLength(13)
      },
      name: {
        required
      },
      lastname: {
        required
      },
      second_lastname: {
        required
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      email: {
        required
      }
    },
    attachments: {
      sat_pdf: {
        required,
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
      },
      notary_certificate_pdf: {
        required,
        isValidFileSize: isValidFileSize({
          maxFileSize: 4100000
        }),
      }
    }
  },
  computed: {
    acceptFileTypes: function() {
      return allowedFileTypes.join(" ");
    }
  },
  methods: {
    ...mapActions({
      saveNotariosData: "saveNotariosData",
      saveisLoading: "saveisLoading",
      responseMessage: "responseMessage"
    }),
    ...mapGetters({
      getSignedURL: "getNotariosAttachmentUrl"
    }),
    resetRecaptcha() {
      this.isRobot = true;
      this.errorCaptcha = true;
      this.$refs.recaptcha.reset();
    },
    onVerify(response) {
      this.isRobot = false;
      this.errorCaptcha = false;
      this.isRobotToken = response;
      console.log("validado " + response);
    },
    onExpired() {
      this.errorCaptcha = true;
      this.isRobot = true;
      console.log("se expiro");
    },
    payload() {
      if (this.userType != "titular") {
        return {
          notary_substitute: this.notary_substitute,
          token: this.isRobotToken,
          user_type: this.userType
        };
      } else {
        return {
          notary_general_data: this.notary_general_data,
          notary_titular: this.notary_titular,
          attachments: this.atts,
          token: this.isRobotToken,
          user_type: this.userType
        };
      }
    },
    previewFiles(e, type) {
      const file = e.target.files[0];
      if (type === "sat_pdf") {
        this.attachments.sat_pdf = file;
      } else {
        this.attachments.notary_certificate_pdf = file;
      }
      this.file[type] = {file: file, filename: file.name.toLowerCase()};
    },
    processSendFile() {
      return new Promise((resolve, reject) => {
        this.getSignedURL().then(res => {
          let signedUrls = res.data.data;
          this.atts.sat_pdf = signedUrls.sat_pdf.key;
          this.atts.notary_certificate_pdf = signedUrls.notary_certificate_pdf.key;
          this.uploadFileToSignedURL({
            signedURL: signedUrls.sat_pdf.path,
            type: "sat_pdf",
            content_type: this.getContentTypeByExt(this.file["sat_pdf"]["filename"].split('.').pop())
          }).then(() => {
            this.uploadFileToSignedURL({
              signedURL: signedUrls.notary_certificate_pdf.path,
              type: "notario_pdf",
              content_type: this.getContentTypeByExt(this.file["notario_pdf"]["filename"].split('.').pop())
            }).then((response) => {
              resolve(response);
            }).catch(error => {
              reject(error);
            });
          }).catch(error => {
            reject(error);
          });
        }).catch(error => {
          reject(error);
        });
      });
    },
    getContentTypeByExt(ext) {
      return allowedFileTypes.find(s => s.match(ext) !== null);
    },
    uploadFileToSignedURL(obj) {
      const payload = new FormData();
      payload.append("file", this.file[obj.type].file);
      delete axios.defaults.headers.common["Authorization"];
      return axios({
        method: "put",
        url: obj.signedURL,
        data: payload.get("file"),
        headers: {
          "content-type": obj.content_type
        }
      });
    },
    processNotariosData() {
      this.saveNotariosData(this.payload())
        .then(() => {
          window.scrollTo(0, 0);
          this.$v.$reset();
          this.responseMessage(["La información capturada ha enviada con éxito.", 1]);
          this.saveisLoading({ isLoading: false });
          this.resetRecaptcha();
          this.$dialog
              .alert("La información capturada ha enviada con éxito.")
              .then(function() {
                window.location.reload();
              })
              .catch(function() {
                window.location.reload();
              });
        })
          .catch(err => {
            window.scrollTo(0, 0);
            this.saveisLoading({ isLoading: false });
            this.responseMessage([err.response.data.data.message, 2]);
        });
    },
    submitData() {
      if (!this.validateForm()) {
        this.responseMessage(["La información capturada es inválida.", 2]);
        return;
      }
      this.saveisLoading({
        isLoading: true,
        message: "Enviando Información"
      });
      if (this.userType != "titular") {
        this.processNotariosData();
      } else {
        this.processSendFile()
          .then(() => {
            this.processNotariosData();
          })
          .catch(() => {
            this.responseMessage([
              "Error al subir los datos adjuntos porfavor intente nuevamente",
              2
            ]);
          });
      }
    },
    validateForm() {
      let isValidForm = false;
      this.$v.$reset();
      this.$v.$touch();
      if (this.userType === "titular") {
        isValidForm = !(
          this.$v.notary_general_data.$error && this.$v.notary_titular.$error && this.$v.attachments.$error
        );
      } else if (this.userType != "titular") {
        isValidForm = !this.$v.notary_substitute.$error;
      }
      return isValidForm;
    }
  }
};
</script>

<style lang="css" scoped>
  .text-captcha {
    float: left;
  }
</style>
