<template>
  <table :class="[{ 'ui fixed single celled table': true}, {'line': noWrap}]">
    <thead>
    <slot name="columns">
      <th class="column" v-for="column in columns" :key="column.id">{{ column }}</th>
    </slot>
    </thead>
    <tbody>
    <template v-for="item in data">
      <tr class="row" :key="item.id">
        <slot :row="item">
          <td v-for="column in columns" :key="column.id">
            <i class="angle up icon"></i>
          </td>
        </slot>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    columns: Array,
    data: Array,
    noWrap: {
      type: Boolean,
      default () {
        return false;
      }
    }
  }
};
</script>
