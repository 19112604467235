<template>
    <footer>

        <div class="footer-top">
            <a href="tel:911">
                <h2>911</h2>
                <p>Emergencias</p>
            </a>
            <a href="tel:089">
                <h2 class="footer-top__title">089</h2>
                <p>Denuncia anónima</p>
            </a>
            <a href="tel:070">
                <h2 class="footer-top__title">070</h2>
                <p>Informatel y Locatel</p>
            </a>
            <a href="tel:073">
                <h2 class="footer-top__title">073</h2>
                <p>Agua y drenaje</p>
            </a>
            <a href="http://aire.nl.gob.mx/" title="Ir a portal de Monitoreo ambiental" target="_blank">
                <font-awesome-icon :icon="['fa', 'seedling']" size="lg" />
                <p>Monitoreo ambiental</p>
            </a>
        </div>

        <div class="footer-row" >
            <img src="@/assets/img/logoescudonl.svg" />
            <div id="footer-outer-links" >

                <div id="links">
                    <ul class="menu nav">
                        <li class="first leaf">
                            <a :href="`${getNlGobExternalUrl()}gobierno`" title="">Gobierno</a>
                        </li>
                        <li class="leaf">
                            <a :href="`${getNlGobExternalUrl()}actualidad`" title="">Actualidad</a>
                        </li>
                        <li class="last leaf">
                            <a  :href="`${getNlGobExternalUrl()}transparencia`" title="">Transparencia</a>
                        </li>
                    </ul>
                </div>

                <div id="social">

                    <div id="social-media">
                      <a class="social__link" target="_blank" href="https://www.facebook.com/gobiernonuevoleon/">
                            <font-awesome-icon :icon="['fab', 'facebook-square']" size="xs" />
                        </a>
                        <a class="social__link" target="_blank" href="https://twitter.com/nuevoleon">
                            <font-awesome-icon :icon="['fab', 'twitter']" size="xs" />
                        </a>
                        <a class="social__link" target="_blank" href="https://www.instagram.com/nuevoleonmx/">
                            <i class="fab fa-instagram"></i>
                            <font-awesome-icon :icon="['fab', 'instagram']" size="xs" />
                        </a>
                        <a class="social__link" target="_blank" href="https://www.youtube.com/user/GobiernoNuevoLeon">
                            <i class="fab fa-youtube"></i>
                            <font-awesome-icon :icon="['fab', 'youtube']" size="xs" />
                        </a>
                        <a class="social__link" target="_blank" href="https://t.me/gobnl">
                            <i class="fab fa-telegram-plane"></i>
                                <font-awesome-icon :icon="['fab', 'telegram-plane']" size="xs" />
                        </a>
                    </div>

                    <a id="codigo-etica" :href="`${getNlGobExternalUrl()}campanas/codigo-de-etica-del-gobierno-del-estado-de-nuevo-leon`" title="">
                        Código de Ética
                    </a>

                </div>

            </div>
        </div>

        <div class="footer-row" id="extra-info" >
            <a :href="`${getNlGobExternalUrl()}sobre-tratamiento-datos-personales`" title="">Sobre el tratamiento de los datos personales</a>
            <span>{{ getAppVersion() }}</span>
        </div>

    </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: 'Footer',
    methods: {
        /**
         * Returns from the project variables the current version of the project.
         *
         * @return string
         */
        getAppVersion() {
            return process.env.VUE_APP_VERSION;
        },
        /**
         * Returns the external nuevo leon government url.
         *
         * @return string
         */
        getNlGobExternalUrl() {
            return process.env.VUE_APP_NL_GOB_EXTERNAL_URL;
        }
    }
}
</script>

<style scoped>
footer {
    /* GOB NL BODY STYLES */
    font-family: Montserrat,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;

    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #3c3f42;
    position: absolute;
    bottom: 0 auto;
    width: 100%;
    padding: 20px 0;
    z-index: -1;
}

footer .footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 96px;
    width: 80%;
}

.footer-top a {
    color: #FFF;
    text-decoration: none;
    padding: 10px;
}

.footer-top a:hover {
    color: #337ab7;
}

.footer-top a h2 {
    font-weight: 700;
    font-size: 2.0rem;
    margin: 0;
}

.footer-top a p {
    font-size: 1.2rem;
    font-weight: 500;
}

footer .footer-row {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer-row img {
    margin-right: auto;
    align-self: center;
    height: 200px;
    width: auto;
}

.footer-row #footer-outer-links {
    color: #fff;
    display: flex;
    flex-direction: row;
}

#footer-outer-links #links {
    border-right: 2px solid #FFF;
    padding-right: 15px;
}

#footer-outer-links #links .nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

#footer-outer-links #links .nav li a {
    font-weight: 600;
    font-size: 1.3rem;
    color: #fff;
    display: block;
    padding: 8px 5px;
    line-height: 1;
    text-transform: uppercase;
}

#footer-outer-links #links .nav li a:hover {
    background-color: #FFF;
    color: #008aba;
}

.footer-row #footer-outer-links #social {
    padding-left: 15px;
    align-self: center;
}

.footer-row #footer-outer-links #social #social-media {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    justify-content: space-between;
}

#social-media a {
    margin-right: 10px;
}

#social-media a.social__link {
    font-size: 2.5rem;
    color: #fff;
    padding: 0;
    font-weight: 400;
}

#social-media a.social__link:hover {
    color: #008aba;
}

.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

#footer-outer-links #links .nav li a {
    font-weight: 600;
    font-size: 1.3rem;
    color: #fff;
    display: block;
    padding: 8px 5px;
    line-height: 1;
    text-transform: uppercase;
}

#footer-outer-links #links .nav li a:hover {
    background-color: #FFF;
    color: #008aba;
}

#codigo-etica {
    text-decoration: none;
    color: #FFF;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}

#extra-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}

#extra-info a {
    margin-right: auto;
    font-family: Roboto;
    font-size: 1.0rem;
    font-weight: 400;
    color: #fff;
    padding: 5px 9px;
}

#extra-info a:hover {
    background-color: #fff;
    color: #337ab7;
}

#extra-info span {
    font-family: Roboto;
    font-size: 1.0rem;
    font-weight: 400;
    color: #fff;
    padding: 5px 9px;
}

</style>
