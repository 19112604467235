<template lang="html">
	<div id="loaderContainer" >
		<sui-dimmer active inverted>
				<sui-loader :content="showMessage" />
		</sui-dimmer>
	</div>
</template>

<script>
    export default {
      // eslint-disable-next-line vue/multi-word-component-names
	name: 'Loader',
	props: {
    message: {
      type: String
    }
	},
	computed: {
    showMessage() {
      return (this.message)? this.message : this.$t('general.form.loading');
    }
	}
	
};
</script>

<style scoped>
.dimmer {
	/* Applying important to avoid dimmer not being fixed */ 
	position: fixed !important;
}
</style>
