<template>
  <div id="activity-list-container">
    <DataTable :data="tableData" :columns="columns">
      <template #columns >
        <th class="activity-column-name" v-for="column in columns" :key="column.id">{{ column }}</th>
      </template>
      <template slot-scope="{ row }">
        <td class="activity-desc-row" >{{ row.Descripcion }}</td>

        <td>
          <form class="ui form">
            <div class="field">
              <input
                  @input="addActivityPercentage($event, row)"
                  @keydown="onReferencesKeyDown"
                  maxlength="3"
                  :aria-disabled="row.disabled"
                  :disabled="row.disabled"
                  type="number"
                  name="activity-percentage"
                  class="activity-percentage"
                  value=""
                  placeholder="Porcentaje (Ej, 100)"
                  v-focus>
            </div>
          </form>
        </td>

        <td>
          <a :class="[{'activities-action': showActions}, { 'activities-action-disabled': disableActions }]"
             v-show="row.disabled"
             href="#"
             @click.prevent="togglePercentageControl(row)" >
            <font-awesome-icon :icon="['fa', 'edit']" size="lg" />
          </a>

          <a :class="[{'activities-action': showActions}, { 'activities-action-disabled': disableActions }]"
             v-show="!row.disabled"
             href="#"
             @click.prevent="
                            togglePercentageControl(row);
                            sumAllActivitiesPercentage();" >
            <font-awesome-icon :icon="['fa', 'check']" size="lg" />
          </a>

          <a :class="[{'activities-action': showActions}, { 'activities-action-disabled': disableActions }]" href="#" @click.prevent="deleteRow(row)" >
            <font-awesome-icon :icon="['fa', 'trash']" size="lg" />
          </a>
        </td>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from '../shared/DataTable.vue';
import sumBy from 'lodash/sumBy';
import unionBy from 'lodash/unionBy';
import { keyCodes } from '@/shared/constants';
export default {
  name: 'ListadoActividades',
  components: {
    DataTable
  },
  props: {
    columns: {
      type: Array,
      default () {
        return [];
      }
    },
    activities: {
      type: Array,
      default () {
        return [];
      }
    },
    readOnlyMode: {
      type: Boolean,
      default () {
        return false;
      }
    },
    defaultErrorText: String
  },
  data () {
    return {
      tableData: this.mapActivitiesTableData(),
      globalPercentageSum: 0,
      hundredPercentReached: false,
      lockActivitiesControls: false,
      maxAmountCharsPercentageField: 3,
      defaultError: this.defaultErrorText
    }
  },
  methods: {
    /**
     * Toggles the property disabled of the selected activity on the table.
     *
     * @param activityRow<{}> the selected activity to toggle the disabled value.
     * @return void
     */
    togglePercentageControl(activityRow) {
      if (!this.hundredPercentReached) {
        this.getTableDataIndex(activityRow, index => {
          this.tableData[index].disabled = !activityRow.disabled;
        });
      }
    },
    /**
     * Handles the assignment of the selectoed activity proeprty "percentage"
     * and assigns the value of the input evento to it on this function.
     *
     * @param event<{}> the event object from the input event.
     * @pram activityRow<{}> the selected ctivity to ad the new percentage value.
     * @returns void
     */
    addActivityPercentage(event, activityRow) {
      let isValid = false;
      if (event.target.value.length <= 3) {
        isValid = true;
        activityRow.percentage = parseInt(event.target.value);

        this.getTableDataIndex(activityRow, index => {
          this.tableData[index] = activityRow;
        });

      }
      return isValid;
    },
    /**
     * Handles the deletion of an activity from the tableData array of objects
     * when clicking the trash icon on the DataTable component.
     *
     * @param activityRow the selected activity to be deleted.
     */
    deleteRow(activityRow) {
      if (!this.hundredPercentReached) {
        this.getTableDataIndex(activityRow, index => {
          this.tableData.splice(index, 1);
        })
      }
    },
    /**
     * Sums all the activities percentage property to be asigned to the
     * <globalPercentageSum> data property.
     *
     * @returns void
     */
    sumAllActivitiesPercentage() {
      this.globalPercentageSum = this.sum(this.tableData, 'percentage');
      this.globalPercentageValidations(this.globalPercentageSum);
    },
    /**
     * Returns the index of the selected activity inside the tabledata array
     * used by other functions to performs operations like adding new properties to an
     * activity object or delete it from the tableData array.
     *
     * @param activityRow the selected activity to obtain his index from tableData
     * @param cb a callback function to perform an operation receiving the index as parameter.
     * @returns void
     */
    getTableDataIndex(activityRow, cb) {
      this.tableData.forEach((activity, index) => {
        if (activity.Descripcion === activityRow.Descripcion) {
          cb(index);
        }
      });
    },
    /**
     * Wrapper for the lodash sumBy function.
     *
     * @returns the sum of props of an array of objects by the property name.
     */
    sum(arr, prop) {
      return sumBy(arr, prop);
    },
    unionBy(arr, arrJoin, propValue) {
      return unionBy(arr, arrJoin, propValue);
    },
    /**
     * Invoked on watch:activities to map the new activities assigned from the father
     * component and assigns it to tableData data property also invokes
     * filterTableData dupes which invokes other transformation logic.
     *
     * @param Array<{activties}> The new array of activities (objects) to work on.
     * @return Array<activities> transformed activites to be passed to the DataTable component.
     *
     */
    filterNewActivities(newActivities) {
      const newActvitiesMapped = newActivities.map(activity => {
        return { Descripcion: activity.title, disabled: false, id: activity.id }
      });
      this.tableData = this.unionBy(this.tableData, newActvitiesMapped, 'Descripcion');
      this.setLockActivities({ lock: true });
    },
    /**
     * Handles the lock of external controls on the parent component.
     *
     * @param settings
     * @returns void
     */
    setLockActivities(settings) {
      this.$emit('lockControls', settings.lock);
    },
    /**
     * Maps an activities array to the structured to be sent to update the DataTable
     */
    mapActivitiesTableData() {
      return this.activities.map(activity => {
        return { Descripcion: activity.title, disabled: false, id: activity.id }
      })
    },
    /**
     * Checks if every activity has a percentage above 0
     *
     * @returns boolean returns false if one of the activities doesn't meet the requirements
     */
    isEveryActivityBelowHundred() {
      return this.tableData.every(activity => {
        return activity.percentage > 0
      });
    },
    /**
     * Limits the input of an input to only 3 characters.
     *
     * @param event the object sent by the @keydown event
     */
    onReferencesKeyDown(event) {
      if (event.target.value.length >= this.maxAmountCharsPercentageField) {
        if ((event.keyCode >= keyCodes.zero && event.keyCode <= keyCodes.z) || event.keyCodes === keyCodes.spaceBar) {
          event.preventDefault();
          return;
        }
      }
    },
    /**
     * Rules to handle the behavior of the percentage set to an activity.
     *
     * @param newGlobalPercentage number the new sum of the activities.
     * @returns void
     */
    globalPercentageValidations(newGlobalPercentage) {
      if(newGlobalPercentage === 100)  {
        this.setLockActivities({ lock: true })
        this.$emit('activityListError', '');
        this.$emit('data', this.getTableDataExport());
        this.hundredPercentReached = true;
      } else {
        (newGlobalPercentage > 100) ? this.setLockActivities({ lock: true }) : this.setLockActivities({ lock: false });
        this.$emit('activityListError', this.defaultError);
        this.$emit('data', []);
      }
    },
    /**
     * Returns the tableData transformed to the structure needed by BE service
     */
    getTableDataExport() {
      return this.tableData.filter(activity => {
        if (activity.percentage > 0)
          return { id: activity.id, percentage: activity.percentage }
      })
    }
  },
  watch: {
    activities: {
      handler: function(newActivities) {
        this.filterNewActivities(newActivities);
      }
    }
  },
  computed: {
    showActions() {
      return (!this.hundredPercentReached &&
          !this.readOnlyMode);
    },
    disableActions() {
      return (this.hundredPercentReached ||
          this.readOnlyMode);
    }
  }
}
</script>

<style lang="scss" scoped >
.activities-action {
  text-decoration: none;
  color: #3C3F42;
  margin-right: 10px;

  :hover {
    color: #42b983
  }
}

.activities-action-disabled {
  text-decoration: none;
  color: #ccc;
  margin-right: 10px;
  cursor: default;
}

.form {
  .field {
    input {
      text-align: center;
    }
  }
}
/**
* Using important on this rule to override the DataTable styles in this specific case
*  because since the text-align propert is sorrounded by the th tag and the slot
* it is over the thead tag there is not so much options here since i can't wrap the th with another
* tag without ruining the semanticness of the table avoid using important at all costs better ue
* another element wrapping the other to apply an specific rule.
 */
thead {
  th {
    text-align: center !important;
    background-color: rgb(231, 221, 221) !important;

    &:first-child {
      width: 70%;
    }
  }
}

tbody {
  td {
    &:first-child {
      text-align: center;
      width: 70%;
    }
  }
}

</style>
