import state from './state';

export default {
  getIsSaved(state) {
    return state.isSaved;
  },
  loadValidated() {
    return state.validated;
  },
  getData() {
    return state.data;
  }
};
