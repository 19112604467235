<template>
  <div class="ui grid container">
    <Loader v-if="isloading" />
    <form class="ui form">
      <div v-for="(component, index) in componentModules" :key="component.id">
        <component
            v-bind:is="component"
            :module="componentData[index]"
            :labels="getStringsLabels(component)"
            :date="componentDate"
        ></component>
      </div>
      <br />
      <div class="field ui checkbox" :class="{ error: $v.read_privacy_policy.$error }" v-show="!isloading">
        <input type="checkbox" name="read_privacy_policy" v-model="read_privacy_policy" />
        <label>He leído y acepto el <a href="" @click.prevent="showPrivacyPolicy">aviso de privacidad.</a></label>
        <span v-if="!$v.read_privacy_policy.checked && $v.read_privacy_policy.$anyDirty" class="error-message">
          {{ $t('validation.field_required') | potfilter($t('privacypolicy.form.privacy_policy')) }}
        </span>
      </div>
    </form>
    <Modal :error="mineError" v-show="isModalVisible" @close="closeModal" />
  </div>
</template>

<script>
import { getPreview } from '@/api/vistaPrevia';
import { mapGetters } from 'vuex';
import Modal from '@/components/shared/Modal';

import Loader from '@/components/Loader.vue';
import LegalRepresentative from './LegalRepresentative.vue';
import TaxResidence from './TaxResidence.vue';
import MeanContact from './MeanContact.vue';
import Identification from './Identification.vue';
import PreponderantActivities from './PreponderantActivities.vue';
import ConstitutiveAct from './ConstitutiveAct.vue';
import FiscalObligation from './FiscalObligation.vue';
import InscriptionForm from './InscriptionForm.vue';

export default {
  name: 'VistaPrevia',
  props: ['currentModule', 'tipoPersona'],
  data() {
    return {
      read_privacy_policy: false,
      mineError: {
        description: '',
        title: ''
      },
      isModalVisible: false,
      fetchedData: {
        type: Array
      },
      componentModules: {
        type: Array
      },
      componentData: {
        type: Array
      },
      componentDate: {
        type: Object
      },
      serverError: {
        type: Object
      },
      isloading: true,
      preackID: '',
      customer_id: {
        type: String,
        default() {
          '';
        }
      },
      POEditorLabels: {
        identification: {
          name: this.$t('identification_data.form.name'),
          lastname: this.$t('identification_data.form.lastname'),
          second_lastname: this.$t('identification_data.form.second_lastname'),
          curp: this.$t('identification_data.form.curp'),
          birthdate: this.$t('identification_data.form.birthdate'),
          nationality: this.$t('identification_data.form.nationality'),
          gender: this.$t('identification_data.form.gender'),
          rfc: this.$t('identification_data.form.name'),
          business_name: this.$t('identification_data.form.business_name'),
          constitution_date: this.$t('identification_data.form.constitution_date'),
          regimen: this.$t('identification_data.form.regimen'),
          taxpayer_type: this.$t('preview.overview.identification_data.tax_payer_type'),
          default: ''
        },
        constitutive_act: {
          date_signature_constitution: this.$t('book.form.date_signature_constitution'),
          rpp_and_trade_data: this.$t('book.form.rpp_and_trade_data'),
          rfc_notary_public: this.$t('book.form.rfc_notary_public'),
          sheet: this.$t('book.form.sheet'),
          book: this.$t('book.form.book'),
          nationality: this.$t('book.form.nationality'),
          default: ''
        },
        legal_representative: {
          birthdate: this.$t('legal_representative.form.birthdate'),
          email: this.$t('legal_representative.form.email'),
          gender: this.$t('legal_representative.gender'),
          lastname: this.$t('legal_representative.form.lastname'),
          phone: this.$t('legal_representative.form.phone_number'),
          curp: this.$t('legal_representative.form.curp'),
          entity: this.$t('legal_representative.form.entity'),
          name: this.$t('legal_representative.form.name'),
          second_lastname: this.$t('legal_representative.form.second_lastname'),
          nationality: this.$t('legal_representative.form.nationality'),
          rfc: this.$t('legal_representative.form.rfc'),
          default: ''
        },
        tax_residence: {
          exterior: this.$t('taxresidence.form.ext_number'),
          state: this.$t('taxresidence.form.entity'),
          street: this.$t('taxresidence.form.street'),
          neighborhood: this.$t('taxresidence.form.select_neighborhood'),
          water_and_drain_meter: this.$t('taxresidence.form.water_and_drain_meter'),
          references: this.$t('taxresidence.form.additional_reference'),
          interior: this.$t('taxresidence.form.int_number'),
          municipality: this.$t('taxresidence.form.city'),
          property_type: this.$t('taxresidence.form.property_type'),
          type_of_road: this.$t('taxresidence.form.viality_type'),
          zip: this.$t('taxresidence.form.zip'),
          between_street_1: this.$t('taxresidence.form.between_streets_1'),
          between_street_2: this.$t('taxresidence.form.between_streets_2')
        },
        default: ''
      }
    };
  },
  watch: {
    currentModule: function(moduleValue) {
      if (moduleValue === 'vista_previa') {
        this.fetchCustomerDataPreview(this.getCustomerID());
      }
    }
  },
  validations: {
    read_privacy_policy: {
      checked: value => value === true
    }
  },
  components: {
    legal_representative: LegalRepresentative,
    tax_residence: TaxResidence,
    mean_contact: MeanContact,
    identification: Identification,
    preponderant_activity: PreponderantActivities,
    constitutive_act: ConstitutiveAct,
    fiscal_obligation: FiscalObligation,
    inscription_form: InscriptionForm,
    Loader,
    Modal
  },
  methods: {
    validateForm() {
      let isValidForm = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('data', this.data);
        isValidForm = true;
      }
      return isValidForm;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-10-08
     *
     * Asigns the object to display inside the modal
     *
     * @returns undefined
     */
    showPrivacyPolicy() {
      this.mineError.description = this.$t('privacypolicy.rec.description');
      this.mineError.title = this.$t('privacypolicy.rec.title');
      this.mineError.isPrivacy = true;
      this.showModal();
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-10-08
     *
     * Opens a modal with privacy and policy text
     *
     * @returns undefined
     */
    showModal() {
      this.isModalVisible = true;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-10-08
     *
     * Closes the modal active
     *
     * @returns undefined
     */
    closeModal() {
      this.isModalVisible = false;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-28
     *
     * Fetches the data from get Preview endpoint
     *
     * @pram customer preack id
     * @returns Object fetched data || Error server error
     */
    fetchCustomerDataPreview(preackID) {
      getPreview(preackID)
          .then(res => {
            this.isloading = false;
            this.componentDate = res.data.data.transaction_applied_at;
            this.fetchedData = res.data.data.modules;
            this.getOrderArray();
          })
          .catch(err => {
            this.serverError = err;
            this.isloading = false;
          });
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-28
     *
     * Gets each objects specfic data
     *
     * @returns undefined
     */
    getOrderArray() {
      this.componentData = this.fetchedData.map(({ data }) => data);
      this.componentModules = this.fetchedData.map(({ module_type }) => module_type);
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-28
     *
     * Gets the customer Id in session
     *
     * @returns String customer id
     */
    getCustomerID() {
      this.preackID = this.getPreacuse();
      const { id } = this.preackID.data;
      return id;
    },
    /**
     * @author Ramon Manrique Figueroa
     * @date 2020-09-28
     *
     * Gets the POEDitor Labels for each child component
     *
     * @returns String customer id
     */
    getStringsLabels(componentName) {
      return this.POEditorLabels[`${componentName}`];
    },
    ...mapGetters({
      getPreacuse: 'getPreacuse'
    })
  }
};
</script>
