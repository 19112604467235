<template>
  <div class="ui main text container">
    <Header title="Trámite Finalizado" />
    <div class="ui main text container">
      <p v-if="successError && !successError.length" >
        Estimado Contribuyente,
        <br />
        <br />Le informamos que hemos recibido su solicitud y hemos creado un ticket.
        Un representante de soporte revisará su solicitud y le enviará una respuesta personal (generalmente dentro de 24 horas a 48 horas hábiles).
        <br />
        <br />En caso de no recibir un correo electrónico favor de comunicarse al tel. 81 20 33 24 20 del Departamento de concertación.
      </p>
      <p v-if="!customer_invoice === '' || !hideTemplate">
        <b>Folio de Seguimiento:</b>
        {{customer_invoice}}
      </p>

      <p v-if="successError && successError.length">
        <b>Error :</b>
        {{successError}}
      </p>
      <br />
      <button @click="returnHome()" class="ui button">Regresar a Inicio</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axiosRec from "../../axios/axios-rec";
import Header from "@/components/shared/Header";

export default {
  name: "Success",
  components: {
    Header
  },
  data: function() {
    return {
      hideTemplate: true,
      successData: {
        type: Object,
        default(){
          null
        }
      },
      successError:{
        type: String,
        default(){
          ''
        }
      },
      customer_id: {
        type: String,
        default(){
          ''
        }
      },
      customer_invoice: {
        type: String,
        default(){
          ''
        }
      }
    };
  },
  created() {
    window.onbeforeunload = null;
    this.setDisableRouteLeaveDialog({ disabled: false });
    this.customer_id = this.getCustomerID();

    this.saveIsLoading({ isLoading: true, message: 'Finalizando Proceso...' });
    setTimeout(() => {
      this.callRequest();
    }, 3000);
  },
  methods: {
    getCustomerID() {
      this.preackID = this.getPreacuse();
      const { id } = this.preackID.data
      return id;
    },
    callRequest() {
      axiosRec
          .post(`/preack/${this.customer_id}/status/end`)
          .then(data => {
            const { short_id } = data.data.data
            this.customer_invoice = short_id;
            this.saveIsLoading({ isLoading: false });
            this.hideTemplate = false;
            this.resetModulesStatus();
          })
          .catch(() => {
            this.successError = this.$t('server.error');
            this.saveIsLoading({ isLoading: false });
          });
    },
    returnHome() {
      this.$router.push("/tramites");
    },
    ...mapGetters({
      getPreacuse: "getPreacuse"
    }),
    ...mapActions({
      saveIsLoading: "saveisLoading",
      setDisableRouteLeaveDialog: 'setDisableRouteLeaveDialog',
      resetModulesStatus: 'resetModulesStatus'
    })
  }
};
</script>

<style>
</style>
