var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Autocomplete',{attrs:{"search":_vm.searchItems,"placeholder":_vm.placeholder || 'Buscar.',"aria-label":_vm.placeholder || 'Buscar.',"get-result-value":_vm.getResultValue,"debounceTime":400,"autoSelect":true},on:{"submit":_vm.selectedItem},scopedSlots:_vm._u([{key:"default",fn:function({
      rootProps,
      inputProps,
      inputListeners,
      resultListProps,
      resultListListeners,
      results,
      resultProps
      }){return [_c('div',_vm._b({},'div',rootProps,false),[_c('input',_vm._g(_vm._b({class:[
              'autocomplete-input',
              'autocomplete-input-no-results',
              'autocomplete-input-focused'
              ],attrs:{"disabled":_vm.readOnlyMode,"type":"text","name":"search-autocomplete"}},'input',inputProps,false),inputListeners)),_c('ul',_vm._g(_vm._b({},'ul',resultListProps,false),resultListListeners),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].id},'li',resultProps[index],false),[(result)?_c('div',{staticClass:"activity-container"},[_c('div',{staticClass:"activity-title"},[_c('strong',[_vm._v(_vm._s(result.title))])]),_c('div',{staticClass:"activity-description"},[_c('p',[_c('i',[_vm._v(_vm._s(result.description))])])])]):_vm._e()])}),0)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }