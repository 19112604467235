import axios from 'axios';
/**
 * Used to perform an http call to create the preponderant activities of a user
 * 
 * @param activities <{}> object containing the preack id and an array of properties 
 *                   containing the activities to post the structure is the following:
 * 
 * {
 *   "preack_id": "{{preack-id}}",
 *  "activities": [
 *  {
 *    "activity_id": 1,
 *    "percentage": 80
 *  }
 * ]
 *
 * 
 */
export const postPreponderantActivities = (activities) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_SERVICE_BASE_URL}v1/preponderant_activity`, activities)
      .then((res) => {
        resolve(res);
      })
      .catch(error => reject(error))
    });
}