import axios from 'axios';
import { generatePreackID, generatePreack} from '../../shared/services';

const state = {
    posts: '',
    flowAlias: '',
    customerId: '',
    preAck: Object
}

const mutations = {
    'Set_FlowLine' (state, posts) {
        state.posts = posts;
    },
    'generatePreackCustomerID' (state, payload){
        state.customerId = payload;
    },
    'generatePreacuse' (state, payload){
        state.preAck = payload;
    }
}

const getters = {
    LoadFlowState() {
        return state.posts
    },
    getPreackCustomerID(){
        return state.customerId;
    },
    getPreacuse(){
        return state.preAck;
    }
}
const BASE_URL = process.env.VUE_APP_SERVICE_BASE_URL;
const actions = {
    loadTimeline({ commit }) {
        axios.get(`${BASE_URL}flow/rec`)
            .then((data) => {
                let posts = data.data;
                commit('Set_FlowLine', posts);
            });
    },
    async generatePreackID({ commit }, alias) {
        return new Promise((resolve, reject) => {
            generatePreackID(alias).then((response) => {
                resolve();
                commit('generatePreackCustomerID', response.data.customer_id);
            }).catch((err) => {
                reject(err);
            });   
        });
  },
    async generatePreack({ commit }, alias){
      const response = await generatePreack(alias);
      commit('generatePreacuse', response);
      return response;
    },
    loadFlowBuilderData({ commit }, alias) {
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}flow/${alias}`)
            .then((data) => {
                let posts = data.data;
                commit('Set_FlowLine', posts);
                resolve(true);
            })
            .catch((err) => {
                reject(err);
            });
        })
    },
    /**
     * Sets the next module based on the actual module stored in the main
     * store.
     * 
     * @param {} vuex object internally passed by vuex 
     * @param string currentModule 
     */
    setNextModule({ getters, dispatch }, currentModule) {
        let nextModule = '';
        getters.LoadFlowState.data.modules.forEach((module, index) => {
            if(module.alias === currentModule) {
                nextModule = getters.LoadFlowState.data.modules[index + 1].alias
            } else if (currentModule === 'datos_identificacion') {
                nextModule = getters.LoadFlowState.data.modules[0].alias
            }
        });
        dispatch('saveCurrentModule', nextModule);
    },
    /**
     * Set previous module when cliking previous on the timeline
     * TODO: Convert to dry this logic with the one above on setNextModule.
     * 
     * @param string currentModule
     * @return void
     */
    setPreviousModule({ getters, dispatch }, currentModule) {
        let newModule = 'datos_identificacion';
        return new Promise((resolve, reject) => {
            getters.LoadFlowState.data.modules.forEach((module, index) => {
                if(module.alias === currentModule &&
                    index > 0) {
                    newModule = getters.LoadFlowState.data.modules[index - 1].alias;
                    resolve();
                } else if (currentModule === 'datos_identificacion' && 
                           index === 0) {
                    reject('/tramites');
                    console.log('ENTRO AQUI');
                }
            });
            dispatch('saveCurrentModule', newModule);
        });
    }
}


export default {
    state,
    mutations,
    getters,
    actions
}
