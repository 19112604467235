import { getPreview } from '@/api/vistaPrevia';

export default {
  setData({ state, commit }) {
    commit('Set_Data', state.data);
  },
  setIsSaved({ commit }, isSaved) {
    commit('Set_SavedStatus', isSaved);
  },
  validator({ commit }, validated) {
    commit('Set_Validated', validated);
  },
  saveData({ state }) {
    if (!state.data) {
      return;
    }
    return getPreview(state.data)
      .then(success => {
        return Promise.resolve(success);
      })
      .catch(failed => {
        return Promise.reject(failed);
      });
  }
};
